import React, { useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import { Radio, Label } from '@rebass/forms'
import Button from '../../../sharedComponents/atoms/Button'
import { SmartPINDeliveryMethod } from '../../interfaces'
import { useConfig } from '../../accountMgmt/state/accountConfigSetup'
import { useLocale } from '../../localization'

interface EnrollProps {
  onContinue: (x: SmartPINDeliveryMethod) => void
  mdn: string
  email: string
  hasEmail: boolean
  isLoading?: boolean
}
const SmartPinDelivery: React.FC<EnrollProps> = ({
  onContinue,
  mdn,
  email,
  hasEmail,
  isLoading,
}) => {
  const { strings } = useLocale()
  const { ALLOW_EMAIL_SMART_PIN } = useConfig()
  const [chosenOption, setChosenOption] = useState<SmartPINDeliveryMethod>(
    SmartPINDeliveryMethod.SMS
  )

  const handleContinue = () => {
    onContinue(chosenOption)
  }

  const handleOptionChange = (option: SmartPINDeliveryMethod) => () => {
    setChosenOption(option)
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{ textAlign: 'center' }}
      py={4}
    >
      <Heading as="h1" fontSize={28} fontWeight={500}>
        {strings.smartPinDelivery.weWillSend}
      </Heading>

      <Box mt={5} sx={{ fontSize: 3, textAlign: 'left' }}>
        <Label>
          <Radio
            id="smsRadio"
            mr={3}
            name="option"
            value={chosenOption}
            onChange={handleOptionChange(SmartPINDeliveryMethod.SMS)}
            defaultChecked={true}
          />
          {strings.smartPinDelivery.textItToMe(mdn)}
        </Label>
        {hasEmail && ALLOW_EMAIL_SMART_PIN ? (
          <Label mt={2}>
            <Radio
              id="emailRadio"
              mr={3}
              name="option"
              value={chosenOption}
              onChange={handleOptionChange(SmartPINDeliveryMethod.Email)}
            />
            {strings.smartPinDelivery.emailItToMe(email)}
          </Label>
        ) : (
          ''
        )}
      </Box>

      <Button
        data-cy="continueButton"
        mt={5}
        height={60}
        width={300}
        fontSize={3}
        showArrow={true}
        onClick={handleContinue}
        isLoading={isLoading}
      >
        {strings.misc.continue}
      </Button>
    </Flex>
  )
}

export default SmartPinDelivery
