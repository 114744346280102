import moment from 'moment'
import { EnrollmentStrings } from '../localization/en'

export default function validateExpDate({
  expMonth,
  expYear,
  strings,
}: {
  expMonth: number
  expYear: number
  strings: EnrollmentStrings
}): { isValid: boolean; error: string | null } {
  const nowMoment = moment()
  const expDateMoment = moment(
    `${expMonth}`.padStart(2, '0') + `/${expYear}`,
    'MM/YYYY',
    true
  )
  const isValidDate = expDateMoment.isValid()
  const isInFuture =
    expDateMoment.isValid() && nowMoment.isBefore(expDateMoment)
  let errorMessage = 'null'

  if (!isValidDate) {
    errorMessage = strings.validateExpDate.invalidDate
  } else if (!isInFuture) {
    errorMessage = strings.validateExpDate.future
  }

  return {
    error: errorMessage,
    isValid: isValidDate && isInFuture,
  }
}
