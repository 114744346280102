import enrollmentConfig from '../../att/enrollment/config'
import React from 'react'
import { AlreadyCovered } from '../enrollment/components/AlreadyCovered'
import { BannerMsgId, TerminalErrMsgId, TerminationReason } from '../interfaces'
import { NotACustomer } from '../enrollment/components/NotACustomer'
import { Partner } from '../interfaces'
import { LocalizedStrings } from './en'
import { PastThirtyDays } from '../enrollment/components/PastThirtyDays'

const CUST_SERV_PHONE_NO = enrollmentConfig.CUST_SERV_PHONE_NO

const es: LocalizedStrings = {
  addressInput: {
    billingAddress: 'Dirección de facturación',
    mustBeSelected: 'Debe seleccionar la dirección',
  },
  agentSignIn: {
    title: 'Inicio de sesión del agente',
    subtitle: 'Ingrese toda su información para comenzar.',
    dealerCode: 'Código del intermediario',
    dealerCodeError: 'Debe tener 5 caracteres',
    attUid: 'ATTUID',
    repName: 'Nombre del representante',
    submit: 'Comenzar',
  },
  alreadyCovered: {
    goodNews: '¡Buenas noticias! Su dispositivo ya está protegido.',
    viewYourAccount: 'Vea su cuenta.',
    alreadyCoveredMobileProtection:
      '¡Felicidades! Ya está inscrito en Protección de teléfonos móviles. Si desea cambiarse al Seguro móvil, llame a Asurion al 866.727.1998.',
    alreadyCoveredMobileInsurance:
      '¡Felicidades! Ya está inscrito en Seguro móvil. Si desea cambiar a Protección de teléfonos móviles, llame a Asurion al 866.727.1998.',
  },
  cantEnroll: {
    sorry: 'Lo sentimos, no podemos inscribir su teléfono en esta condición.',
  },
  confirmPhone: {
    isThisThePhone: '¿Es este el teléfono que está inscribiendo?',
    anImageAlt: 'Una imagen de un teléfono',
  },
  deviceSelection: {
    whatKind: '¿Qué tipo de teléfono está inscribiendo?',
    search: 'Busque la marca y el modelo del teléfono a continuación.',
    begin: 'Comience a escribir la marca y el modelo',
    phoneMake: 'Marca y modelo del teléfono',
  },
  legal: {
    keyTerms: 'Términos y condiciones clave',
    monthlyCharge: 'Cargo mensual',
    plusTax: ' + impuesto',
    insuranceCoverage: 'Cobertura del seguro',
    coverageAgainstLoss:
      'Cobertura por pérdida, robo, accidente físico o daños por líquidos, y/o mal funcionamiento fuera de garantía.',
    replacement: 'Reemplazo',
    claims:
      'Las reclamaciones se resolverán con teléfonos nuevos o reacondicionados, que han sido abiertos y/o usados con anterioridad (que pueden contener piezas de repuesto originales o no originales) del mismo modelo u otros modelos de clase y calidad similares.',
    claimLimits: 'Límites de reclamación',
    twoClaims:
      'Dos reclamaciones dentro de un período de 12 meses consecutivos con un valor máximo de teléfono de $2,000 por incidente.',
    deductibles: 'Deducibles',
    replacementDeductible: 'Deducible de reemplazo',
    standardDeductible: 'Deducible estándar',
    tierA: 'Nivel A',
    tierB: 'Nivel B',
    tierC: 'Nivel C',
    tierD: 'Nivel D',
    tierE: 'Nivel E',
    screenRepairDeductible: 'Deducible de reparación de pantalla',
    equipment: 'Equipo',
    cancellation: 'Cancelación',
    youMayCancel: `
 Puede cancelar su cobertura de seguro opcional en cualquier momento y recibir un reembolso de las primas o los cargos mensuales no devengados. Podemos cancelar o cambiar las condiciones mediante aviso previo por escrito según lo exija la ley. Se le reembolsarán los montos de las primas o los cargos no devengados.
    `,
    exclusions: 'Exclusiones y limitaciones',
    thisInsuranceCoverage: `
 La cobertura del seguro contiene limitaciones y exclusiones. Se excluye la pérdida debido a una pérdida indirecta o consecuente, actos intencionales, actos deshonestos, actos fraudulentos, actos delictivos, abuso, obsolescencia o depreciación tecnológica, daños estéticos, reparación o reemplazo no autorizados, contaminantes, pérdida o daños de baterías, no seguimiento de las instrucciones del fabricante, retiro de producto del mercado, falla mecánica o eléctrica durante el período de la garantía del fabricante, malware, reacción nuclear o radiación, guerra, acción gubernamental, daño a los datos, medios externos no estándares, y software no estándar, y la incapacidad para proteger razonablemente el dispositivo de cualquier pérdida. Todas las exclusiones y limitaciones se pueden encontrar en los términos y condiciones completos.
    `,
    mobileProtection: `
 El plan de protección de teléfonos móviles para AT&T Prepaid (SM) incluye el Seguro móvil para AT&T Prepaid y ProTech para el servicio AT&T Prepaid. El Seguro móvil para AT&T PREDPAID está suscrito por Continental Casualty Company, Chicago, IL, una compañía CNA (CNA) y está administrado por Asurion Protection Services, LLC (en Iowa, lic. n.º 1001002300, en California, Asurion Protection Services Insurance Agency, LLC, CA lic. n.º 0D63161; en Puerto Rico, Asurion Protection Services of Puerto Rico, Inc.), un agente autorizado de CNA.
    `,
    notEligible: 'No elegible',
    viewTermsAndConditions: 'Ver Términos y Condiciones',
  },
  noPhone: {
    toContinue: 'Para continuar, debe tener su teléfono con usted y encendido.',
    weNeedToTextYou: `
 Necesitamos enviarle un código de verificación. Esto nos permite verificar y registrar su dispositivo en el plan de protección de teléfonos móviles para AT&T Prepaid℠. Si ahora no tiene su dispositivo, o si lo pidió pero aún no lo recibió, regrese cuando lo tenga.
    `,
    youHave: `
 Tiene 30 días a partir del momento en que activa o actualiza su dispositivo para inscribirse en el plan de protección de teléfonos móviles para el servicio AT&T Prepaid℠.
    `,
  },
  notACustomer: {
    heading(partnerName: string) {
      return [
        `Parece que su número de teléfono no es un número ${partnerName} activo. Por favor, `,
        `comuníquese con ${partnerName} `,
        `para confirmar el estado de su cuenta.`,
      ]
    },
  },
  pastThirtyDays: {
    youCanOnlyEnroll(partnerName: string) {
      return [
        `Solo puede inscribirse en el plan de protección de teléfonos móviles dentro de los 30 días a partir de la compra de su dispositivo. Si compró su dispositivo dentro de los últimos 30 días, `,
        `comuníquese con ${partnerName} `,
        `para confirmar el estado de su cuenta.`,
      ]
    },
  },
  paymentInformation: {
    invalidCCNo: 'Número de tarjeta de crédito inválido',
    invalidSecCode: 'Código de seguridad inválido',
    addressLookupErrMsg: 'Debe seleccionar la dirección',
    enterYourPayment: 'Ingrese su información de pago.',
    chargeThisCard(amount: string) {
      return `Al introducir su información de pago, usted nos autoriza a cargar esta tarjeta ${amount} mensualmente + impuesto.`
    },
    nameOnCard: 'Nombre que aparece en la tarjeta',
    cardNumber: 'Número de la tarjeta',
    pleaseEnterValidCC: 'Ingrese un número de tarjeta de crédito válido',
    pleaseEnterValidSecCode: 'Ingrese un código de seguridad válido',
    expirationDate: 'Fecha de vencimiento',
    pleaseEnterValidExpDate: 'Ingrese una fecha de vencimiento válida',
    streetAddress: 'Dirección',
    apartment: 'Departamento, suite, unidad, etc. (Opcional)',
    city: 'Ciudad',
    state: 'Estado',
    zip: 'Código postal',
    pleaseEnterValidZip: 'Ingrese un código postal válido',
    billingAddress: 'Dirección de facturación',
    startTyping: 'Comience a escribir y elija la dirección correcta.',
    enterItManually: 'Ingrese la información en forma manual.',
    emailAddress: 'Dirección de correo electrónico',
    pleaseEnterValidEmail: 'Ingrese una dirección de correo electrónico válida',
    contactInformation: 'Información de contacto igual a la de facturación',
    firstName: 'Nombre',
    lastName: 'Apellido',
    secCode: 'Código de seguridad',
  },
  phoneCondition: {
    tellUs(deviceName: string) {
      return `Cuéntenos acerca de su ${deviceName}`
    },
    answerSome: 'Responda algunas preguntas y firme a continuación.',
    anImage: 'Una imagen de un teléfono',
    malfunctioning: '¿Está funcionando mal?',
    cracked: '¿Está rota la pantalla?',
    warped: '¿Están los lados del teléfono deformados, separados o abollados?',
    submerged: '¿Lo ha expuesto al agua u otro líquido?',
    signHere: 'firme aquí',
    typeYourName: 'Escriba su nombre legal completo',
    dateHere: 'coloque la fecha aquí',
    pleaseEnterValidDate: 'Ingrese una fecha válida',
    typeTodays: 'Escriba la fecha de hoy en el formato: mm/dd/aa',
  },
  planSelection: {
    whatPlan: 'Elija el plan adecuado para usted.',
    theseOptions:
      'Estas opciones están disponibles para clientes que residen en el estado de Nueva York. Elija un plan de protección para continuar.',
    chooseAPlan: 'Elija un plan de protección para continuar.',
  },
  review: {
    almostDone: '¡Casi termina!',
    confirm: 'Confirme y complete su inscripción.',
    newYork: 'Clientes que residen en New York',
    addForPrice: '+$3/mes',
    newYorkNote(planName: string) {
      return [
        `Además de ${planName}, Asurion ofrece opciones alternativas para los residentes del estado de Nueva York. Si está interesado, llame al `,
        ' de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este.',
      ]
    },
    headset: 'Un ícono de una persona con auriculares',
    callOrChat: 'Llama o chatea con un experto en ProTech en vivo.',
    hideDetails: 'Ocultar detalles',
    viewDetails: 'Ver detalles',
    included: '¡Incluido!',
    anIconOfAPhone: 'Un ícono de un teléfono',
    phoneProtection: 'Protección de teléfonos',
    coverage:
      'Cobertura por pérdida, robo, daño y mal funcionamiento fuera de la garantía.',
    crackedScreen: 'Deducible de reparación de pantalla rota',
    phoneReplacement: 'Deducible de teléfono de reemplazo',
    fiftyGB:
      '50 GB de almacenamiento para sus fotos y videos con la aplicación AT&T Photo Storage℠.',
    anIconOfAStack: 'Un ícono de una pila de fotos',
    attPhotoStorage: 'AT&T Photo Storage℠',
    anImageOfAPhone: 'Una imagen de un teléfono',
    iAffirm(name: string) {
      return `YO, ${name}, afirmo lo siguiente sobre el teléfono que estoy inscribiendo en este programa:`
    },
    functioningNormally: 'Está funcionando normalmente',
    notCracked: 'La pantalla no está rota',
    notWarped: 'Los lados no están deformados, separados o abollados',
    notSubmerged: 'No lo he expuesto al agua u otro líquido',
    signed: 'firmado',
    paymentInformation: 'Información de pago',
    contactInformation: 'Información de contacto',
    enrollNow: 'Inscríbase ahora',
    endingIn: 'que termina en',
    downloadBrochureText: 'AT&T Prepaid Folleto',
    downloadBrochure: 'Descargar',
    viewBrochure: 'Ver folleto',
    plusTax: ' + impuesto',
    attProTech: 'AT&T ProTech℠ con AT&T Photo Storage℠',
    includeAttProTech:
      'Incluir AT&T ProTech℠ con AT&T Photo Storage℠ por $3/mes',
  },
  success: {
    greenCheckmarkImage: 'Imagen de marca de verificación verde',
    youreEnrolled: '¡Está inscripto!',
    welcome(planName: string) {
      return `Bienvenido a ${planName}`
    },
    startGetting:
      'Comience a obtener respuestas a todas las preguntas que tenga acerca de su teléfono mediante la programación de una llamada con un experto ProTech ahora.',
    scheduleCall: 'Programar una llamada',
    viewAccount: 'Ver cuenta',
  },
  terminalErrorPage: {
    override: 'Reemplazar',
    dismiss: 'Descartar',
  },
  textReminder: {
    textACustomer: 'Enviar un mensaje recordatorio a un cliente',
    wellSend: 'Le enviaremos un mensaje de texto con un enlace a',
    customersMobileNumber: 'Número de teléfono móvil del cliente',
    byContinuing:
      'Al continuar, confirma que este número puede recibir un mensaje de texto de parte nuestra.',
  },
  textReminderSent: {
    sent: 'Enviado.',
    weveSent: 'Le hemos enviado un mensaje de texto con un enlace a',
    to: 'para',
  },
  validateExpDate: {
    invalidDate: 'Fecha inválida',
    future: 'La fecha de vencimiento debe ser una fecha futura',
  },
  terminalErrorCopy: {
    [TerminalErrMsgId.D]: {
      title: 'Lo sentimos, no podemos continuar.',
      subHeading: 'Ocurrió un error en el sistema.',
      body: `Espere unos minutos e inténtelo de nuevo. Si el problema persiste, llámenos al ${CUST_SERV_PHONE_NO} de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este. Estamos encantados de ayudarle.`,
    },
    [TerminalErrMsgId.F]: {
      title: 'Lo sentimos, no podemos continuar.',
      subHeading:
        'Ha alcanzado la cantidad máxima de intentos para verificar su número de teléfono móvil.',
      body: `Llame al ${CUST_SERV_PHONE_NO} de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este. Estamos encantados de ayudarle.`,
    },
    [TerminalErrMsgId.H]: {
      title: 'Lo sentimos, no podemos continuar.',
      subHeading: 'Ocurrió un error en el sistema.',
      body: `
 Espere unos minutos e inténtelo de nuevo. Si el problema persiste, por favor, llámenos al ${CUST_SERV_PHONE_NO} y nos complacerá ayudarle.
    `,
    },
    [TerminalErrMsgId.I]: {
      title: 'Lo sentimos, no podemos enviar el mensaje de texto SMS.',
      subHeading:
        'Aquí encontrará la información necesaria para inscribirse más adelante:',
      body: 'Visite el sitio prepaidprotection.att.com para inscribirse (también aparece en el folleto).',
    },
    [TerminalErrMsgId.K]: {
      title: 'Lo sentimos, no podemos completar su inscripción.',
      subHeading: 'Se produjo un error al procesar su información.',
      body: `Llame al ${CUST_SERV_PHONE_NO} de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este. Estamos encantados de ayudarle.`,
    },
    [TerminalErrMsgId.TBD1]: {
      title: 'Lo sentimos, no podemos continuar su inscripción.',
      subHeading: 'Pedimos disculpas por cualquier molestia ocasionada.',
      body: ``,
    },
    [TerminalErrMsgId.TBD3]: {
      title: 'Lo sentimos, no podemos continuar su inscripción.',
      subHeading: 'El dispositivo ingresado no es elegible para protección.',
      body: `Llame al ${CUST_SERV_PHONE_NO} de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este. Estamos encantados de ayudarle.`,
    },
    [TerminalErrMsgId.TBD5]: {
      title: 'Lo sentimos, no podemos continuar su inscripción.',
      subHeading: '',
      body: `Si ha comprado un dispositivo en los últimos 30 días, comuníquese con AT&T para verificar el estado de la cuenta.`,
    },
    [TerminalErrMsgId.PAST_ELIGIBILITY_WINDOW]: {
      title:
        'Lo sentimos, el período de inscripción de este dispositivo ha vencido.',
      subHeading: '',
      body: (<PastThirtyDays partnerName={Partner.ATT} />) as React.ReactNode,
    },
    [TerminalErrMsgId.NOT_A_CUSTOMER]: {
      title: 'Lo sentimos, no podemos continuar su inscripción.',
      subHeading: '',
      body: `Parece que su número de teléfono no es un número AT&T activo. Comuníquese con AT&T para confirmar el estado de su cuenta.`,
    },
    [TerminalErrMsgId.AGENT_1_A]: {
      title:
        '[AEP-300] El cliente no cumple con los requisitos de elegibilidad para la protección PREPAGA.',
      subHeading: '',
      body: 'Primero, confirme la fecha de activación en la red prepaga y la compra del dispositivo.',
      bullets: [
        'Si ambos exceden los 30 días, el cliente no es elegible para inscribirse.',
        'Si el cliente cree que está dentro de los 30 días de las fechas de compra o activación, transfiera la llamada al grupo del agente CSAT.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_B]: {
      title:
        '[AEP-402] El cliente no cumple con los requisitos de elegibilidad para la protección PREPAGA.',
      subHeading: '',
      body: 'Primero, confirme la fecha de activación en la red prepaga y la compra del dispositivo.',
      bullets: [
        'Si ambos exceden los 30 días, el cliente no es elegible para inscribirse.',
        'Si el cliente cree que está dentro de los 30 días de las fechas de compra o activación, transfiera la llamada al grupo del agente CSAT.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_C]: {
      title:
        '[AEP-403] El cliente no cumple con los requisitos de elegibilidad para la protección PREPAGA.',
      subHeading: '',
      body: 'Primero, confirme la fecha de activación en la red prepaga y la compra del dispositivo.',
      bullets: [
        'Si ambos exceden los 30 días, el cliente no es elegible para inscribirse.',
        'Si el cliente cree que está dentro de los 30 días de las fechas de compra o activación, transfiera la llamada al grupo del agente CSAT.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_D]: {
      title:
        '[AEP-406] El cliente no cumple con los requisitos de elegibilidad para la protección PREPAGA.',
      subHeading: '',
      body: 'Primero, confirme la fecha de activación en la red prepaga y la compra del dispositivo.',
      bullets: [
        'Si ambos exceden los 30 días, el cliente no es elegible para inscribirse.',
        'Si el cliente cree que está dentro de los 30 días de las fechas de compra o activación, transfiera la llamada al grupo del agente CSAT.',
      ],
    },
    [TerminalErrMsgId.AGENT_2]: {
      title:
        '[AEP-400] Este número del dispositivo móvil ya está asociado con un plan de protección Asurion y no es elegible para la inscripción.',
      subHeading: '',
      body: 'Con la interfaz del usuario (User Interface, UI) de facturación de la prima, confirme el estado suspendido y determine el curso de acción adecuado según el factor contribuyente para la suspensión (si el pago está pendiente, ofrezca quitar el pago del cliente).',
    },
    [TerminalErrMsgId.AGENT_3]: {
      title:
        '[AEP-401] Este número del dispositivo móvil ya está asociado con un plan de protección Asurion suspendido y no es elegible para la inscripción.',
      subHeading: '',
      body: '',
    },
    [TerminalErrMsgId.AGENT_4]: {
      title:
        '[AEP-404] El dispositivo seleccionado no es elegible para la inscripción.',
      subHeading: '',
      body: '',
    },
    [TerminalErrMsgId.AGENT_5_A_DEFAULT]: {
      title:
        '[AEP-405] Hubo un problema al intentar procesar la tarjeta de crédito. ¿Le gustaría intentarlo nuevamente o utilizar un método de pago diferente?',
      subHeading: '',
      body: [
        'Intente nuevamente con la misma información o proporcione una forma de pago alternativa.',
        'Nota: el procesamiento del pago tiene un límite de 3 intentos.',
      ],
    },
    [TerminalErrMsgId.AGENT_5_A_FINAL]: {
      title:
        '[AEP-405] Hubo un problema al intentar procesar la tarjeta de crédito. ¿Le gustaría intentarlo nuevamente o utilizar un método de pago diferente?',
      subHeading: '',
      body: `Informe al cliente que el pago no puede ser procesado en este momento y revise el billete de fraude.`,
    },
    [TerminalErrMsgId.AGENT_5_B_DEFAULT]: {
      title:
        '[AEP-407] Hubo un problema al intentar procesar la tarjeta de crédito. ¿Le gustaría intentarlo nuevamente o utilizar un método de pago diferente?',
      subHeading: '',
      body: [
        'Intente nuevamente con la misma información o proporcione una forma de pago alternativa.',
        'Nota: el procesamiento del pago tiene un límite de 3 intentos.',
      ],
    },
    [TerminalErrMsgId.AGENT_5_B_FINAL]: {
      title:
        '[AEP-407] Hubo un problema al intentar procesar la tarjeta de crédito. ¿Le gustaría intentarlo nuevamente o utilizar un método de pago diferente?',
      subHeading: '',
      body: `Informe al cliente que el pago no puede ser procesado en este momento y revise el billete de fraude.`,
    },
    [TerminalErrMsgId.AGENT_6]: {
      title: '[AEP-500] Se produjo un error interno en el sistema.',
      subHeading: '',
      body: [
        'Discúlpese con el cliente y pídale que lo intente en 1 o 2 horas.',
        'Nota: si el problema persiste, informe este problema a su supervisor.',
      ],
    },
    [TerminalErrMsgId.SUPER_1]: {
      title: 'Atención',
      subHeading:
        'Confirme si el dispositivo que el cliente está intentando inscribir está activado con el número de dispositivo móvil (Mobile Device Number, MDN) proporcionado.',
      body: `Busque Helix para las inscripciones manuales de AT&T Prepaid para revisar el proceso completo de las inscripciones manuales.`,
    },
    [TerminalErrMsgId.ALREADY_COVERED]: {
      title: 'Lo sentimos, no podemos continuar su inscripción.',
      subHeading: '',
      body: <AlreadyCovered />,
    },
    [TerminalErrMsgId.CARD_DECLINED]: {
      title: 'Lo sentimos, no podemos completar su inscripción.',
      subHeading: 'Método de pago rechazado.',
      body: `No pudimos procesar su pago con el método de pago proporcionado. Comuníquese con la compañía de la tarjeta de crédito para resolver el problema.`,
    },
  },
  bannerCopy: {
    [BannerMsgId.B]: {
      message:
        'Lo sentimos, no podemos continuar. Iniciar sesión no está disponible. Espere unos minutos e inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.E]: {
      message:
        'Lo sentimos, ese código de verificación no coincide. Inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.G]: {
      message:
        'Lo sentimos, ese código de verificación ha vencido. Use el enlace para volver a enviar el código de verificación e inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.L]: {
      message:
        'Lo sentimos, no podemos completar su inscripción. Inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.CARD_DECLINED]: {
      message:
        'No pudimos procesar su pago con el método de pago proporcionado. Proporcione un método de pago diferente e inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.NOT_A_CUSTOMER]: {
      message: <NotACustomer partnerName={Partner.ATT} />,
      type: 'error',
    },
    [BannerMsgId.TBD2]: {
      message:
        'Disculpe, pero el dispositivo ingresado no es elegible para protección. Inscriba un dispositivo diferente.',
      type: 'error',
    },
    [BannerMsgId.TBD4]: {
      message:
        '¡Gracias! Hemos reenviado un mensaje de texto al número proporcionado. Este recordatorio incluirá un enlace para inscribirse en el plan de protección de teléfonos móviles para el servicio AT&T Prepaid.',
      type: 'error',
    },
    [BannerMsgId.SUCCESS_1]: {
      message: 'Código de verificación reenviado.',
      type: 'success',
    },
    [BannerMsgId.SUCCESS_2]: {
      message:
        'Hemos enviado un correo electrónico de confirmación con los términos y condiciones completos a la dirección de correo electrónico proporcionada.',
      type: 'success',
    },
    [BannerMsgId.SUCCESS_3]: {
      message:
        'Hemos enviado un correo electrónico de confirmación a la dirección de correo electrónico proporcionada.',
      type: 'success',
    },
    [BannerMsgId.ONBOARDING_1]: {
      message: 'Hubo un problema al reservar su cita. Inténtelo de nuevo.',
      type: 'error',
    },
    [BannerMsgId.PRODUCT_NOT_AVAILABLE]: {
      message: 'Este producto no está disponible en Nueva York.',
      type: 'error',
    },
  },

  // ACCOUNT MANAGEMENT

  accountActions: {
    failedToFetch: 'No se pudo recuperar la información de facturación.',
    didNotRecognize:
      'No reconocimos ese número de teléfono móvil. Intente ingresarlo nuevamente a continuación.',
    thanksBanner:
      '¡Gracias! Hemos actualizado el día de facturación. Aún le cobraremos un monto prorrateado en la fecha de facturación original. Luego, en su nueva fecha de facturación, comenzaremos a cobrarle el monto total.',
    failedToFetchMDN: 'No se pudo recuperar la información del MDN',
    doesNotMatch:
      'Lo sentimos, ese código de verificación no coincide. Inténtelo de nuevo.',
    expired:
      'Lo sentimos, ese código de verificación ha vencido. Use el enlace para volver a enviar el código de verificación e inténtelo de nuevo.',
    thanksBanner2: '¡Gracias! Hemos actualizado su nombre.',
    failedName: 'No se pudo actualizar su nombre',
    failedBillingDate: 'No se pudo actualizar la fecha de facturación',
    updated({ email1, email2 }: { email1: string; email2: string }) {
      return email1
        ? `Hemos actualizado su dirección de correo electrónico y enviado correos electrónicos de confirmación a ${email1} y ${email2}.`
        : `Hemos actualizado su dirección de correo electrónico y enviado un correo electrónico de confirmación a ${email2}.`
    },
    failedEmail: 'No se pudo actualizar el correo electrónico',
    successCatchUp: `Se ha puesto al día con el pago.`,
    errorPleaseCall(error: string, num: string) {
      return `${error} Llame a ${num}.`
    },
    errorMakingPayment(num: string) {
      return `Ocurrió un error al realizar el pago. Intente de nuevo más tarde o llame ${num}.`
    },
    failedToFetchSecToken: 'No se pudo recuperar el token de seguridad',
    errorUpdatingPayment(num: string) {
      return `No se pudo actualizar la información de pago. Intente de nuevo más tarde o llame ${num}.`
    },
    updatedBillingInfo(email: string) {
      return (
        'Hemos actualizado su información de facturación y enviado un correo electrónico de confirmación a ' +
        email
      )
    },
    failureUpdatingPaymentInfo(num: string) {
      return `No se pudo actualizar la información de pago. Intente de nuevo más tarde o llame ${num}.`
    },
    failedToUpdatePaymentInfo: 'No se pudo actualizar la información de pago',
    successfullySignedOut: `Ha cerrado correctamente la sesión.`,
    sentVerification(destination: string) {
      return `Hemos enviado su código de verificación a ${destination}.`
    },
    successfulCancellationGeneric: `
 Ha cancelado correctamente su plan y le hemos enviado un correo electrónico de confirmación.
    `,
    successfulCancellationWithDate(date: string) {
      return `Ha cancelado correctamente su plan y le hemos enviado un correo electrónico de confirmación. Continuará teniendo cobertura hasta ${date}.`
    },
    successfulCancellationRefundEligible: `
 Ha cancelado correctamente su plan y le hemos enviado un correo electrónico de confirmación. La prima del primer mes se reembolsará en 7 días hábiles.
    `,
    errorCancellingPlan(num: string) {
      return `Error al cancelar plan. Intente de nuevo más tarde o llame ${num}.`
    },
    missingFindAgreementsResponseData: 'Faltan los datos findAgreementResponse',
    missingBillingDetails:
      'Falta información de facturación en updateBankAccountPaymentMethod',
  },
  accountMain: {
    yourAccount: 'Su cuenta',
    overview: 'Descripción general',
    billingAndPaymentHistory: 'Facturación e historial de pagos',
  },
  accountApp: {
    dontHaveAnAccount: '¿No tiene una cuenta?',
    enrollNow: 'Inscríbase ahora.',
  },
  bankAccountChangeForm: {
    nameOfAccountHolder: 'Nombre del titular de la cuenta',
    sortCode: 'Ordenar código',
    accountNumber: 'Número de cuenta',
  },
  misc: {
    loading: 'Cargando',
    change: 'Cambiar',
    cancel: 'Cancelar',
    saveChanges: 'Guardar cambios',
    view: 'Ver',
    name: 'Nombre',
    email: 'Correo electrónico',
    bankAccountEndingIn: 'Cuenta bancaria que termina en',
    noPaymentMethod: 'No hay método de pago registrado.',
    makeAPaymentNow: 'Efectúe un pago ahora.',
    pleaseContact: 'Contáctenos',
    yourAccountIsPastDue: 'Su cuenta está atrasada.',
    yourAccountIsPending: 'Su cuenta está pendiente.',
    pleaseUpdatePaymentMethod: 'Actualice el método de pago.',
    updatePaymentMethod: 'Actualice el método de pago.',
    pay: 'Pagar',
    closeIcon: 'Ícono de cerrar',
    cardNumber: 'Número de la tarjeta',
    creditCardIcon: 'Ícono de tarjeta de crédito',
    continue: 'Continuar',
    close: 'Cerrar',
    yes: 'Sí',
    no: 'No',
    unknown: 'Desconocido',
  },
  billing: {
    billingInformation: 'Información de facturación',
    billingStatus: 'Estado de facturación',
    current: 'Actual',
    pay: 'Pagar',
    yourNextPayment: 'Su próximo pago',
    ofTheMonth: 'del mes',
    recurringPaymentTotal: 'Total de pagos recurrentes',
    paymentMethod: 'Método de pago',
    paymentHistory: 'Historial de pagos',
    referenceId: 'Identificación de referencia',
    amount: 'Monto',
    date: 'Fecha',
    haveABillingQuestion:
      '¿Tiene alguna pregunta sobre la facturación? Llámenos al',
  },
  billingDateModalContent: {
    changeBillingDate: 'Cambiar fecha de facturación',
    currentBillingDay: 'Día de facturación actual',
    everyDayOfTheMonth(day: string) {
      return `Cada ${day} del mes`
    },
    newBillingDay: 'Nuevo día de facturación',
  },
  cancelPlanModalContent: {
    cancelPlanReason: {
      // TODO: If we use this on AT&T we will need to get Spanish here
      [TerminationReason.REGRETTERM]: 'I changed my mind',
      [TerminationReason.ND]:
        'I declined to take insurance but was added anyway',
      [TerminationReason.PRSSRDSL]: 'I was pressured to take insurance',
      [TerminationReason.INSRNCNTRQ]: "I wasn't aware I had insurance",
      [TerminationReason.NOMNYTERM]:
        "The insurance is too expensive / I can't afford it",
      [TerminationReason.SVCPRVCHNG]: 'I found insurance elsewhere',
      [TerminationReason.NDVC]: 'I no longer have this device',
      [TerminationReason.TIEDSALOLD]: 'The cost was not explained to me',
      [TerminationReason.PF]: 'Fraudulent policy', // NOT SHOWN
      [TerminationReason.CSTDSFNCLT]: 'I am receiving poor service',
      [TerminationReason.CSTMDSTSFN]:
        'I am receiving poor service from Asurion', // NOT SHOWN
    },
    areYouSure: '¿Está seguro de que desea cancelar su plan?',
    ifYouCancel(endingDate: string) {
      return `Si cancela su plan, ya no tendrá protección para su dispositivo. Cualquier incidente que se produzca después ${endingDate} no estará cubierto.`
    },
    questions(num: string) {
      return `¿Tiene preguntas sobre su plan? Antes de cancelar, llámenos al ${num}.`
    },
    yesCancel: 'Sí, cancelar mi plan.',
    noCancel: 'No, mantener mi plan.',
    pleaseShare: 'Explique por qué cancela su plan.',
    cancelNow: 'Cancelar mi plan ahora.',
    nevermind: 'No importa, mantener mi plan.',
  },
  creditCardChangeForm: {
    address1: 'Dirección 1',
    address2: 'Dirección 2',
    streetAddressPlaceholder: 'Dirección',
    streetAddress2Placeholder: 'casilla de correo, depto., suite, etc.',
    city: 'Ciudad',
    county: 'Condado',
    state: 'Estado',
    postCode: 'Código postal',
    zipCode: 'Código postal',
    zipCodeHint: 'El código postal debe tener 5 dígitos',
    address: 'Dirección',
    enterManually: 'Ingrese la dirección manualmente.',
    nameOnCard: 'Nombre que aparece en la tarjeta',
    nameHint: 'Debe ingresar un nombre',
    creditCardNumber: 'Número de tarjeta de crédito',
    expirationDate: 'Fecha de vencimiento',
    securityCode: 'Código de seguridad',
    cardNumberPlaceholder: 'Número de la tarjeta',
    cardNumberHint: 'Ingrese un número de tarjeta de crédito válido',
    expDatePlaceholder: 'MM/AAAA',
    cvvHint: 'Debe tener 3 o 4 números',
    saveChanges: 'Guardar cambios',
    havingTrouble: '¿teniendo problemas?',
  },
  emailModalContent: {
    changeEmail: 'Cambiar correo electrónico',
    currentEmail: 'Correo electrónico actual',
    newEmail: 'Correo electrónico nuevo',
    newEmailHint: 'Ingrese dirección de correo electrónico válida',
    confirmNewEmail: 'Confirmar correo electrónico nuevo',
    confirmNewEmailHint: 'Los correos electrónicos no coinciden',
    saveChanges: 'Guardar cambios',
  },
  enrolledDeviceModalContent: {
    yourEnrolledDevice: 'Su dispositivo inscripto',
    currentEnrolledDevice: 'Dispositivo inscripto actual',
    deviceAltText: 'Dispositivo',
    enrolledDate: 'Fecha de inscripción',
  },
  makePaymentModalContent: {
    makePayment: 'Realizar un pago',
    billingStatus: 'Estado de facturación',
    paymentAmount: 'Monto del pago',
    paymentMethod: 'Método de pago',
    methodOnFileRadio(method: string) {
      return `Usar el método de pago registrado: ${method}`
    },
    useDifferentMethod:
      'Usar un método de pago diferente y convertirlo en mi nuevo método de pago para una facturación futura',
    updateAndMakePayment: 'Actualizar y realizar un pago.',
  },
  nameModalContent: {
    changeName: 'Cambiar nombre',
    name: 'Nombre',
  },
  overview: {
    lastPayment(date: string) {
      return `El último pago fue en ${date}`
    },
    noPreviousPayments: 'No se registran pagos anteriores.',
    youAreNotEnrolled: 'Usted no está inscripto en un plan.',
    planInformation: 'Información del plan',
    planDetails: 'Detalles del plan',
    terminationDate: 'Fecha de finalización',
    enrolledDate: 'Fecha de inscripción',
    enrolledPhoneNumber: 'Número de teléfono inscripto',
    enrolledDevice: 'Dispositivo inscripto',
    paymentMethod: 'Método de pago',
    yourNextPayment: 'Su próximo pago',
    everyOfTheMonth(day: string) {
      return `Cada ${day} del mes`
    },
    paymentHistory: 'Historial de pagos',
    cancelPlan: 'Cancelar el plan',
    contactInformation: 'Información de contacto',
    communications: 'Comunicaciones',
    emailsAndOther: 'Correos electrónicos y otras comunicaciones digitales',
  },
  paymentModalContent: {
    changePaymentMethod: 'Cambiar el método de pago',
  },
  paymentStatus: {
    statusIconAltText: 'Ícono de estado',
    current: 'Actual',
    pastDue: 'Vencido',
    pending: 'Pendiente',
  },
  planModalContent: {
    planDetails: 'Detalles del plan',
    comprehensive: 'Cobertura y soporte integral para su teléfono móvil.',
    insurance: 'Seguro',
    asSoonAsNext:
      'Reemplazo de teléfono tan pronto como al día siguiente por pérdida, robo, daño y mal funcionamiento fuera de la garantía.',
    screenRepair:
      'Reparación de pantalla en el mismo día para teléfonos elegibles en áreas selectas.',
    upToTwo:
      'Hasta dos reclamaciones en un período de 12 meses consecutivos con un valor máximo de dispositivo de $2,000 por reclamación.',
    proTech: 'Aplicación y soporte ProTech',
    accessToExpert:
      'Acceso a expertos en soporte técnico para su teléfono cubierto cuando lo necesite.',
    helpWithDevice:
      'Ayuda con la configuración del dispositivo y la conexión con Bluetooth y otros dispositivos inteligentes.',
    callOrChat: 'Llama o chatea con un experto en ProTech en vivo.',
    attPhotoStorageApp: 'Aplicación AT&T Photo Storage',
    freeUpSpace:
      'Libere espacio en su teléfono con 50 GB de almacenamiento seguro para fotos y videos.',
    automatically:
      'Haga copias de seguridad de manera automática y segura de sus fotografías y videos sin sacrificar la calidad.',
  },
  smartPinDelivery: {
    weWillSend: 'Le enviaremos un código de verificación.',
    textMyCode: 'Enviar mi código por mensaje de texto',
    emailMyCode: 'Enviar mi código por correo electrónico',
    textItToMe(mdn: string) {
      return `Enviar un mensaje a ${mdn}`
    },
    emailItToMe(email: string) {
      return `Enviar un correo electrónico a ${email}`
    },
  },
  terminalError: {
    sorry: 'Lo sentimos, no podemos continuar.',
    aSystemError: 'Ocurrió un error en el sistema.',
    pleaseWait(num: string) {
      return `Espere unos minutos e inténtelo de nuevo. Si el problema persiste, llámenos al ${num} de lunes a viernes de 8 a. m. a 10 p. m. hora del este y los fines de semana de 9 a. m. a 9 p. m. hora del este. Estamos encantados de ayudarle.`
    },
  },
  'AEP-430': {
    h1: 'Lo sentimos, no podemos registrarlo.',
    p({
      partnerName,
      customerServiceNumber,
    }: {
      partnerName: Partner
      customerServiceNumber: string
    }) {
      return `${partnerName} la administración de cuentas en línea no admite planes familiares actualmente. Llame a ${customerServiceNumber} para administrar su cuenta.`
    },
  },
  'AEP-431': {
    h1: 'Contacto',
    sub() {
      return 'Notamos un problema con su cuenta.'
    },
    p({
      partnerName,
      customerServiceNumber,
    }: {
      partnerName: Partner
      customerServiceNumber: string
    }) {
      return `Llame a ${customerServiceNumber} para hablar con un agente de ${partnerName} sobre el problema.`
    },
    content() {
      return 'Puede llamarnos entre las 9 a.m. y las 7 p.m. de lunes a viernes de 9 a.m. a 6 p.m. los fines de semana. También estamos abiertos los días festivos (excepto el día de Navidad y el domingo de Pascua). Las llamadas a números 03 cuestan lo mismo que las llamadas a teléfonos fijos del Reino Unido a partir del 01 y 02. Las llamadas desde teléfonos fijos y móviles se incluyen en paquetes de llamadas gratuitas o inclusivas.'
    },
  },

  sharedComponents: {
    signIn: {
      continue: 'Continuar',
      signIn: 'Iniciar sesión',
      getYouEnrolled: '¡Inscríbase!',
      dontHavePhone: 'No tengo mi teléfono',
      disclaimer:
        'Al continuar, usted acepta recibir información sobre el programa por mensaje de texto al número que proporcionó. Se pueden aplicar tarifas de datos.',
      makeSure: [
        'Asegúrese de tener su teléfono con usted y encendido. ',
        'A continuación, introduzca el número de teléfono.',
      ],
    },
    smartPin: {
      enterCode: 'Ingrese el código de verificación que le acabamos de enviar.',
      itMayTake: 'Puede tardar un minuto en recibirlo.',
      sendAgain: 'Envíe el código nuevamente.',
    },
    misc: {
      continue: 'Continuar',
      back: 'Atrás',
    },
    pinEntry: {
      verificationCode: 'Código de verificación',
    },
  },

  onboarding: {
    confirm: {
      confirmYourProTechCall: 'Confirme su llamada con ProTech.',
      proTechExpertWillCall: ['El exporto ProTech lo llamará al', ' el'],
      heWillCallYouAt: 'Él/Ella lo llamará a usted a la(s)',
      confirm: 'Confirmar',
      pleaseChoose: 'Elija una cita disponible.',
    },
    dayPicker: {
      showOtherDays: 'Mostrar otros días',
      today: 'Hoy',
      chooseADay: 'Elija un día:',
    },
    end: {
      pleaseChoose: 'Elija una cita disponible.',
      title: 'Llamada a ProTech',
      location: 'Llamada telefónica',
      description:
        'El experto ProTech se comunicará con usted en este momento.',
      youreAllSet: '¡Todo está listo!',
      yourProTechCall: 'Su llamada a ProTech',
      when: 'Cuándo',
      where: 'Dónde',
      wellCallYou: 'Lo llamaremos a',
      notes: 'Notas',
      makeSure:
        'Asegúrese de tener el teléfono inscripto disponible cuando lo llamemos.',
      phoneWithFixedScreen: 'Teléfono con la pantalla arreglada.',
      download: 'Descargue la aplicación AT&T ProTech',
      getFast:
        'Obtenga acceso rápido a expertos en soporte técnico con la aplicación AT&T ProTech.',
      chat: 'Comuníquese con un experto para obtener soporte personalizado',
      selfHelp:
        'Obtenga consejos personalizados de autoayuda para su dispositivo',
      linkDirectly:
        'Aproveche el enlace directo a la aplicación AT&T Photo Storage℠',
      googlePlayButton: 'Botón de Google Play',
    },
    misc: {
      change: 'Cambiar',
      or: 'o',
      phoneNumber: 'Número de teléfono',
      continue: 'Continuar',
      back: 'Atrás',
    },
    numberPicker: {
      whatNumber: '¿A qué número de teléfono debemos llamar?',
      yourEnrolledDevice: 'Su dispositivo inscripto:',
      anotherNumber: 'Otro número:',
    },
    onboarding: {
      stepTitles: [
        'Comenzar',
        'Díganos cuándo llamar',
        'Díganos a qué número debemos llamar',
        'Finalizar',
      ],
    },
    onboardingHeader: {
      backButtonIcon: 'Ícono del botón Atrás',
    },
    scheduleHeader: {
      whenToCall(firstName: string) {
        return `${firstName}, ¿cuándo le gustaría que el experto ProTech lo llame?`
      },
      whatTime(date: string) {
        return `¿A qué hora le gustaría que el experto ProTech lo llame?${date}`
      },
    },
    timePicker: {
      chooseATime: 'Elija un momento...',
      selectTime: 'Seleccione una hora...',
    },
  },
}

export default es
