import SubmitButton from '../../sharedComponents/SubmitButton'
import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import validateEmail from '../../sharedHelpers/validateEmail'
import { useActions, useStore } from '../state/accountStateMgmt'
import { useLocale } from '../../localization'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IProps {
  currentEmail: string
  onClose: () => void
}
const EmailModalContent: React.FC<IProps> = (props) => {
  const [values, setValues] = useState({ newEmail: '', confirmEmail: '' })
  const classes = useStyles()
  const { loading } = useStore()
  const { updateEmail } = useActions()
  const { strings } = useLocale()

  const isFormValid =
    !!values.newEmail &&
    values.newEmail.toLowerCase() === values.confirmEmail.toLowerCase() &&
    validateEmail(values.newEmail)

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isFormValid) {
      updateEmail(values.newEmail).then(() => {
        props.onClose()
      })
    }
  }

  return (
    <>
      <Box>
        <Typography data-cy="modal-title" variant="h6">
          {strings.emailModalContent.changeEmail}
        </Typography>
      </Box>

      <Box fontWeight={500} component="h4" color="black" mt={2} mb={0}>
        {strings.emailModalContent.currentEmail}
      </Box>

      <Typography variant="body2">{props.currentEmail}</Typography>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" py={3} mt={1}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.emailModalContent.newEmail}
          </Typography>
          <TextField
            id={'email'}
            value={values.newEmail}
            error={!!values.newEmail && !validateEmail(values.newEmail)}
            helperText={
              !!values.newEmail && !validateEmail(values.newEmail)
                ? strings.emailModalContent.newEmail
                : ''
            }
            onChange={handleChange('newEmail')}
            margin="normal"
            variant="outlined"
          />
          <br />
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.emailModalContent.confirmNewEmail}
          </Typography>
          <TextField
            id={'email-confirm'}
            value={values.confirmEmail}
            onChange={handleChange('confirmEmail')}
            margin="normal"
            variant="outlined"
            error={!isFormValid && !!values.confirmEmail}
            helperText={
              !isFormValid && !!values.confirmEmail
                ? strings.emailModalContent.confirmNewEmailHint
                : ''
            }
          />
          <SubmitButton
            id={'email-submit'}
            text={strings.emailModalContent.saveChanges}
            disabled={!isFormValid}
            loading={loading}
          />
        </Box>
      </form>
      <Box
        className={classes.link}
        onClick={props.onClose}
        textAlign="center"
        fontWeight={500}
      >
        {strings.misc.cancel}
      </Box>
    </>
  )
}

export default EmailModalContent
