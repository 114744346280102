import { Moment } from 'moment'
import { Country } from '../interfaces'

const formatDateByCountry = (dateMoment: Moment, country: Country): string => {
  switch (country) {
    case Country.Brazil: {
      return dateMoment.format('DD/MM/YYYY')
    }
    case Country.GB: {
      return dateMoment.format('DD/MM/YYYY')
    }
    // Same as default
    case Country.US:
      return dateMoment.format('MM/DD/YYYY')
    default:
      return dateMoment.format('MM/DD/YYYY')
  }
}

export default formatDateByCountry
