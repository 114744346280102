import React from 'react'
import { Box } from 'rebass'

interface VerticalSeparatorProps {
  color?: string
  height?: number
}
const Divider: React.FC<VerticalSeparatorProps> = ({ color, height }) => {
  return (
    <Box
      height={height || '100%'}
      minHeight={10} // minHeight set just to avoid confusion with a 0 height invisible divider
      sx={{ borderRight: '1px solid', borderColor: color || 'white' }}
    />
  )
}

export default Divider
