import validateExpirationDate from './validateExpirationDate'
import PaymentApiClient from '../PaymentApiClient'
import { ICCErrors, ICCInfo } from '../interfaces'
import { EnrollmentStrings } from '../localization/en'

export default (
  ccInfo: ICCInfo,
  strings: EnrollmentStrings,
  ALLOWED_CC_TYPES: string
): ICCErrors => {
  const expValidity = validateExpirationDate({
    expMonth: ccInfo.expMonth || -1,
    expYear: ccInfo.expYear || -1,
    strings,
  })
  const ccValidity = PaymentApiClient.validateCC({
    allowedCCTypes: ALLOWED_CC_TYPES,
    creditCardNumber: ccInfo.creditCardNumber.replace(/\s/g, ''),
  })
  return {
    securityCode:
      ccInfo.securityCode.length > 2
        ? undefined
        : strings.paymentInformation.invalidSecCode,
    expirationDate:
      expValidity.isValid || !expValidity.error ? undefined : expValidity.error,
    creditCardNumber: ccValidity.isValid
      ? undefined
      : strings.paymentInformation.invalidCCNo,
  }
}
