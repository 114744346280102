import { Country } from '../interfaces'
const matchers = {
  [Country.US]: /^(\d{3})(\d{3})(\d{4})$/,
  [Country.Brazil]: /^(\d{2})(\d{5})(\d{4})$/,
}
export default function formatPhoneNumber(
  phoneNumber: string,
  country = Country.US
) {
  // UK numbers have not yet required any formatting
  if (country === Country.GB) {
    return phoneNumber
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = matchers[country].exec(cleaned)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}
