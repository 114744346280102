import React from 'react'
import { partnerNameToLinkMap } from '../../sharedHelpers/partnerLinkMap'
import { Partner } from '../../interfaces'
import { Heading, Link } from 'rebass'
import { useLocale } from '../../localization'

export const NotACustomer: React.FC<{ partnerName: Partner }> = ({
  partnerName,
}) => {
  const { strings } = useLocale()
  const linkTarget = partnerNameToLinkMap[partnerName]
  const headingStrings = strings.notACustomer.heading(partnerName)

  return (
    <Heading>
      {headingStrings[0]}
      <Link
        href={linkTarget}
        sx={{
          color: 'hsl(180, 100%, 70%)',
          textDecoration: 'none',
        }}
      >
        {headingStrings[1]}
      </Link>
      {headingStrings[2]}
    </Heading>
  )
}
