import config from '../../tesco/accountMgmt/config'
import React from 'react'
import { AlreadyCovered } from '../enrollment/components/AlreadyCovered'
import { BannerMsgId, TerminalErrMsgId, TerminationReason } from '../interfaces'
import { NotACustomer } from '../enrollment/components/NotACustomer'
import { Partner } from '../interfaces'
import { PastThirtyDays } from '../enrollment/components/PastThirtyDays'
import { LocalizedStrings } from './en'
// import { AxiosError } from 'axios'

const CUST_SERV_PHONE_NO = config.CUST_SERV_PHONE_NO

const enGb: LocalizedStrings = {
  addressInput: {
    billingAddress: 'Billing Address',
    mustBeSelected: 'Address must be selected',
  },
  agentSignIn: {
    title: 'Agent sign in',
    subtitle: 'Please enter all your information to get started.',
    dealerCode: 'Dealer code',
    dealerCodeError: 'Must be 5 characters',
    attUid: 'ATTUID',
    repName: 'Rep name',
    submit: 'Get started',
  },
  alreadyCovered: {
    goodNews: 'Good news! You already have device protection.',
    viewYourAccount: 'View your account.',
    alreadyCoveredMobileProtection:
      'Congratulations! You are already enrolled in Mobile Protection. If you’d like to switch to Mobile Insurance, please give Asurion a call at 866.727.1998.',
    alreadyCoveredMobileInsurance:
      'Congratulations! You are already enrolled in Mobile Insurance. If you’d like to switch to Mobile Protection, please give Asurion a call at 866.727.1998.',
  },
  cantEnroll: {
    sorry: "Sorry, we can't enroll your phone in this condition.",
  },
  confirmPhone: {
    isThisThePhone: "Is this the phone you're enrolling?",
    anImageAlt: 'An image of a phone',
  },
  deviceSelection: {
    whatKind: 'What kind of phone are you enrolling?',
    search: "Search the phone's make and model below.",
    begin: 'Begin typing make and model',
    phoneMake: 'Phone make and model',
  },
  legal: {
    keyTerms: 'Key terms and conditions',
    monthlyCharge: 'Monthly charge',
    plusTax: ' + tax',
    insuranceCoverage: 'Insurance coverage',
    coverageAgainstLoss:
      'Coverage against loss, theft, accidental physical or liquid damage, and/or out-of-warranty',
    replacement: 'Replacement',
    claims:
      'Claims may be fulfilled with new or refurbished phones, which are previously opened, and/or used (which may contain original or non-original replacement parts) of the same model or other models of like kind or quality.',
    claimLimits: 'Claim Limits',
    twoClaims:
      'Two claims within any consecutive 12 months with a maximum phone value of $2,000 per occurrence.',
    deductibles: 'Deductibles',
    replacementDeductible: 'Replacement Deductible',
    standardDeductible: 'Standard Deductible',
    tierA: 'Tier A',
    tierB: 'Tier B',
    tierC: 'Tier C',
    tierD: 'Tier D',
    tierE: 'Tier E',
    screenRepairDeductible: 'Screen Repair Deductible',
    equipment: 'Equipment',
    cancellation: 'Cancellation',
    youMayCancel: `
      You may cancel your optional insurance coverage at any time and receive
      a refund of your unearned monthly premium/charges. We may cancel or
      change terms by giving you prior written notice as required by law. Any
      unearned premium/charges will be refunded to you.
    `,
    exclusions: 'Exclusions and Limitations',
    thisInsuranceCoverage: `
      This insurance coverage does contain limitations and exclusions. Loss
      due to indirect or consequential loss, intentional acts, dishonest acts,
      fraudulent acts, criminal acts, abuse, technological obsolescence or
      depreciation, cosmetic damage, unauthorized repair or replacement,
      pollutants, loss or damage to or of batteries, failure to follow the
      manufacturer’s instructions, manufacturer recall, mechanical or
      electrical failure during the manufacturer’s warranty period, malware,
      nuclear reaction or radiation, war, governmental action, damage to data,
      nonstandard external media, and nonstandard so􀃬 ware, and failure to
      reasonably protect the device from any further loss are excluded. All
      exclusions and limitations can be found in the full terms and
      conditions.
    `,
    mobileProtection: `
      Mobile Protection for AT&T Prepaid (SM) includes Mobile Insurance for
      AT&T Prepaid and ProTech for AT&T Prepaid. Mobile Insurance for AT&T
      PREPAID is underwritten by Continental Casualty Company, Chicago, IL, a
      CNA Company (CNA), and administered by Asurion Protection Services, LLC (in
      Iowa, Lic. #1001002300, in California, Asurion Protection Services
      Insurance Agency, LLC, Lic. #0D63161; in Puerto Rico, Asurion Protection
      Services of Puerto Rico, Inc.), a licensed agent of CNA.
    `,
    notEligible: 'Not eligible',
    viewTermsAndConditions: 'View Terms and Conditions',
  },
  noPhone: {
    toContinue: 'To continue, you must have your phone with you and turned on.',
    weNeedToTextYou: `
      We need to text you a verification code. This allows us to verify and
      enroll your device in Mobile Protection for AT&T Prepaid℠. If you
      don’t have your device with you now—or if you ordered it but haven’t
      received it yet—come back when you do.
    `,
    youHave: `
      You have 30 days from when you activate or upgrade your device to
      enroll in Mobile Protection for AT&T Prepaid℠.
    `,
  },
  notACustomer: {
    heading(partnerName: string) {
      return [
        `It appears your phone number is not an active ${partnerName} number. Please `,
        `contact ${partnerName} `,
        `to confirm your account status.`,
      ]
    },
  },
  pastThirtyDays: {
    youCanOnlyEnroll(partnerName: string) {
      return [
        `You can only enroll in Mobile Protection within 30 days of purchasing your device. If you purchased your device within the last 30 days, `,
        `contact ${partnerName} `,
        `to confirm your account status.`,
      ]
    },
  },
  paymentInformation: {
    invalidCCNo: 'Invalid credit card number',
    invalidSecCode: 'Invalid security code',
    addressLookupErrMsg: 'Address must be selected',
    enterYourPayment: 'Enter your payment information.',
    chargeThisCard(amount: string) {
      return `Upon entering your payment information, you authorize us to charge this card ${amount}/month + tax.`
    },
    nameOnCard: 'Name on card',
    cardNumber: 'Card number',
    pleaseEnterValidCC: 'Please enter a valid credit card number',
    pleaseEnterValidSecCode: 'Please enter a valid security code',
    expirationDate: 'Expiration date',
    pleaseEnterValidExpDate: 'Please enter a valid expiration date',
    streetAddress: 'Street address',
    apartment: 'Apartment, suite, unit, etc. (Optional)',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    pleaseEnterValidZip: 'Please enter a valid ZIP code',
    billingAddress: 'Billing address',
    startTyping: 'Start typing and choose the correct address.',
    enterItManually: 'Enter it manually.',
    emailAddress: 'Email address',
    pleaseEnterValidEmail: 'Please enter a valid email',
    contactInformation: 'Contact information same as billing',
    firstName: 'First name',
    lastName: 'Last name',
    secCode: 'Sec. code',
  },
  phoneCondition: {
    tellUs(deviceName: string) {
      return `Tell us about your ${deviceName}`
    },
    answerSome: 'Answer some questions and sign below.',
    anImage: 'An image of a phone',
    malfunctioning: 'Is it malfunctioning?',
    cracked: 'Is the screen cracked?',
    warped: 'Are the sides of the phone warped, separated or dented?',
    submerged: 'Has it been exposed to water or other liquid?',
    signHere: 'sign here',
    typeYourName: 'Type your full legal name',
    dateHere: 'date here',
    pleaseEnterValidDate: 'Please enter a valid date',
    typeTodays: "Type today's date using the format: mm/dd/yy",
  },
  planSelection: {
    whatPlan: 'What plan works best?',
    theseOptions:
      'These options are available for customers residing in the state of New York. Choose a protection plan to continue.',
    chooseAPlan: 'Choose a protection plan to continue.',
  },
  review: {
    almostDone: 'Almost done!',
    confirm: 'Confirm and complete your enrollment.',
    newYork: 'Customers residing in New York',
    addForPrice: '+$3/month',
    newYorkNote(planName: string) {
      return [
        `In addition to ${planName}, Asurion offers alternative options for residents of the state of New York. If interested, please call `,
        ' weekdays 8am — 10pm ET and weekends 9am — 9pm ET.',
      ]
    },
    headset: 'An icon of a person wearing a headset',
    callOrChat: 'Call or chat with a live ProTech℠ expert.',
    hideDetails: 'Hide details',
    viewDetails: 'View details',
    included: 'Included!',
    anIconOfAPhone: 'An icon of a phone',
    phoneProtection: 'Phone Protection',
    coverage:
      'Coverage for loss, theft, damage and out-of-warranty malfunctions.',
    crackedScreen: 'Deductible for cracked screen repair',
    phoneReplacement: 'Deductible for phone replacement',
    fiftyGB:
      '50GB of storage for your photos and videos with the AT&T Photo Storage℠ app.',
    anIconOfAStack: 'An icon of a stack of photos',
    attPhotoStorage: 'AT&T Photo Storage℠',
    anImageOfAPhone: 'An image of a phone',
    iAffirm(name: string) {
      return `I, ${name}, affirm the following about the phone I'm enrolling in this program:`
    },
    functioningNormally: "It's functioning normally",
    notCracked: 'The screen is not cracked',
    notWarped: 'The sides are not warped, separated or dented',
    notSubmerged: 'It has not been exposed to water or other liquid',
    signed: 'signed',
    paymentInformation: 'Payment information',
    contactInformation: 'Contact information',
    enrollNow: 'Enroll now',
    endingIn: 'ending in',
    downloadBrochureText: 'AT&T Prepaid Brochure',
    downloadBrochure: 'Download',
    viewBrochure: 'View brochure',
    plusTax: ' + tax',
    attProTech: 'AT&T ProTech℠ with AT&T Photo Storage℠',
    includeAttProTech:
      'Include AT&T ProTech℠ with AT&T Photo Storage℠ for $3/month',
  },
  success: {
    greenCheckmarkImage: 'Green checkmark image',
    youreEnrolled: "You're enrolled!",
    welcome(planName: string) {
      return `Welcome to ${planName}`
    },
    startGetting:
      'Start getting answers to all the questions you have about your phone by scheduling a ProTech call now.',
    scheduleCall: 'Schedule call',
    viewAccount: 'View account',
  },
  terminalErrorPage: {
    override: 'Override',
    dismiss: 'Dismiss',
  },
  textReminder: {
    textACustomer: 'Text a customer reminder',
    wellSend: "We'll send a text message with a link to",
    customersMobileNumber: "Customer's mobile number",
    byContinuing:
      'By continuing, you confirm that this number may receive a text from us.',
  },
  textReminderSent: {
    sent: 'Sent.',
    weveSent: 'We’ve sent a text message with a link to',
    to: 'to',
  },
  validateExpDate: {
    invalidDate: 'Invalid date',
    future: 'Expiration date must be in future',
  },
  terminalErrorCopy: {
    [TerminalErrMsgId.D]: {
      title: "Sorry, we're unable to continue.",
      subHeading: 'A system error has occurred.',
      body: `Please wait a few minutes and try again. If the problem continues, please call us at ${CUST_SERV_PHONE_NO} weekdays 8am – 9pm, Saturdays 8am – 8pm, and Sundays 9am – 6pm. We're happy to help.`,
    },
    [TerminalErrMsgId.F]: {
      title: "Sorry, we're unable to continue.",
      subHeading:
        "You've reached the maximum number of attempts to verify your mobile number.",
      body: `Please wait a few minutes and try again. If the problem continues, please call us at ${CUST_SERV_PHONE_NO} weekdays 8am – 9pm, Saturdays 8am – 8pm, and Sundays 9am – 6pm. We're happy to help.`,
    },
    [TerminalErrMsgId.H]: {
      title: "Sorry, we're unable to continue.",
      subHeading: 'A system error has occurred.',
      body: `
      Please wait a few minutes and try again. If the problem continues, please
      call us at ${CUST_SERV_PHONE_NO} and we'll be happy to help you.
    `,
    },
    [TerminalErrMsgId.I]: {
      title: "Sorry, we're unable to send the SMS text message.",
      subHeading: "Here's the information needed to enroll later:",
      body:
        'Go to prepaidprotection.att.com to enroll (also listed on the brochure).',
    },
    [TerminalErrMsgId.K]: {
      title: "Sorry, we're unable to complete your enrolment.",
      subHeading: 'An error occurred while processing your information.',
      body: `Please wait a few minutes and try again. If the problem continues, please call us at ${CUST_SERV_PHONE_NO} weekdays 8am – 9pm, Saturdays 8am – 8pm, and Sundays 9am – 6pm. We're happy to help.`,
    },
    [TerminalErrMsgId.TBD1]: {
      title: "Sorry, we're unable to continue your enrolment.",
      subHeading: 'We apologize for any inconvenience.',
      body: ``,
    },
    [TerminalErrMsgId.TBD3]: {
      title: "Sorry, we're unable to continue your enrolment.",
      subHeading: 'The device entered is not eligible for protection.',
      body: `Please wait a few minutes and try again. If the problem continues, please call us at ${CUST_SERV_PHONE_NO} weekdays 8am – 9pm, Saturdays 8am – 8pm, and Sundays 9am – 6pm. We're happy to help.`,
    },
    [TerminalErrMsgId.TBD5]: {
      title: "Sorry, we're unable to continue your enrolment.",
      subHeading: '',
      body: `If you've purchased a device in the last 30 days, contact AT&T to verify the account status.`,
    },
    [TerminalErrMsgId.PAST_ELIGIBILITY_WINDOW]: {
      title: 'Sorry, the enrolment period for this device has passed.',
      subHeading: '',
      body: <PastThirtyDays partnerName={Partner.ATT} />,
    },
    [TerminalErrMsgId.NOT_A_CUSTOMER]: {
      title: "Sorry, we're unable to continue your enrolment.",
      subHeading: '',
      body: `It appears your phone number is not an active AT&T number. Please contact AT&T to confirm your account status.`,
    },
    [TerminalErrMsgId.AGENT_1_A]: {
      title:
        '[AEP-300] Customer does not meet eligibility requirements for PREPAID protection.',
      subHeading: '',
      body:
        'First, confirm the date of activation on the prepaid network, and device purchase.',
      bullets: [
        'If both of these exceed 30 days, customer is not eligible to enroll.',
        'If the customer believes that they are within 30 days of their purchase or activation dates, transfer the call to the CSAT agent group.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_B]: {
      title:
        '[AEP-402] Customer does not meet eligibility requirements for PREPAID protection.',
      subHeading: '',
      body:
        'First, confirm the date of activation on the prepaid network, and device purchase.',
      bullets: [
        'If both of these exceed 30 days, customer is not eligible to enroll.',
        'If the customer believes that they are within 30 days of their purchase or activation dates, transfer the call to the CSAT agent group.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_C]: {
      title:
        '[AEP-403] Customer does not meet eligibility requirements for PREPAID protection.',
      subHeading: '',
      body:
        'First, confirm the date of activation on the prepaid network, and device purchase.',
      bullets: [
        'If both of these exceed 30 days, customer is not eligible to enroll.',
        'If the customer believes that they are within 30 days of their purchase or activation dates, transfer the call to the CSAT agent group.',
      ],
    },
    [TerminalErrMsgId.AGENT_1_D]: {
      title:
        '[AEP-406] Customer does not meet eligibility requirements for PREPAID protection.',
      subHeading: '',
      body:
        'First, confirm the date of activation on the prepaid network, and device purchase.',
      bullets: [
        'If both of these exceed 30 days, customer is not eligible to enroll.',
        'If the customer believes that they are within 30 days of their purchase or activation dates, transfer the call to the CSAT agent group.',
      ],
    },
    [TerminalErrMsgId.AGENT_2]: {
      title:
        '[AEP-400] This mobile device number is already associated with an Asurion protection plan and is not eligible for enrolment.',
      subHeading: '',
      body:
        'Using the Premium Billing UI, confirm suspended status, and determine appropriate course of action based upon contributing factor for suspension (if payment is outstanding offer to take customer payment).',
    },
    [TerminalErrMsgId.AGENT_3]: {
      title:
        '[AEP-401] This mobile device number is already associated with a suspended Asurion protection plan and is not eligible for enrolment.',
      subHeading: '',
      body: '',
    },
    [TerminalErrMsgId.AGENT_4]: {
      title: '[AEP-404] The chosen device is not eligible for enrolment.',
      subHeading: '',
      body: '',
    },
    [TerminalErrMsgId.AGENT_5_A_DEFAULT]: {
      title:
        '[AEP-405] There was a problem processing the credit card. Would you like to try again or use a different payment method?',
      subHeading: '',
      body: [
        'Please try again with the same information or have customer provide an alternate form of payment.',
        'Note: Payment processing is limited to 3 attempts.',
      ],
    },
    [TerminalErrMsgId.AGENT_5_A_FINAL]: {
      title:
        '[AEP-405] There was a problem processing the credit card. Would you like to try again or use a different payment method?',
      subHeading: '',
      body: `Please advise customer that their payment cannot be processed at this time and submit an eye on Fraud ticket.`,
    },
    [TerminalErrMsgId.AGENT_5_B_DEFAULT]: {
      title:
        '[AEP-407] There was a problem processing the credit card. Would you like to try again or use a different payment method?',
      subHeading: '',
      body: [
        'Please try again with the same information or have customer provide an alternate form of payment.',
        'Note: Payment processing is limited to 3 attempts.',
      ],
    },
    [TerminalErrMsgId.AGENT_5_B_FINAL]: {
      title:
        '[AEP-407] There was a problem processing the credit card. Would you like to try again or use a different payment method?',
      subHeading: '',
      body: `Please advise customer that their payment cannot be processed at this time and submit an eye on Fraud ticket.`,
    },
    [TerminalErrMsgId.AGENT_6]: {
      title: '[AEP-500] There was an internal system error.',
      subHeading: '',
      body: [
        'Apologize to the customer and ask them to try back in 1-2 hours.',
        'Note: If problem persists, please report this issue to your supervisor.',
      ],
    },
    [TerminalErrMsgId.SUPER_1]: {
      title: 'Attention',
      subHeading:
        'Please confirm the device the customer is trying to enroll is activated on the MDN provided.',
      body: `Search Helix for ATT PREPAID Manual Enrollments to review the full process for manual enrolments.`,
    },
    [TerminalErrMsgId.ALREADY_COVERED]: {
      title: "Sorry, we're unable to continue your enrolment.",
      subHeading: '',
      body: <AlreadyCovered />,
    },
    [TerminalErrMsgId.CARD_DECLINED]: {
      title: "Sorry, we're unable to complete your enrolment.",
      subHeading: 'Payment method declined.',
      body: `We were unable to process your payment with the provided payment method. Please contact the credit card company to resolve the issue.`,
    },
  },
  bannerCopy: {
    [BannerMsgId.B]: {
      message:
        "Sorry, we're unable to continue. Sign on is unavailable. Please wait a few minutes and try again.",
      type: 'error',
    },
    [BannerMsgId.E]: {
      message: "Sorry, that verification code doesn't match. Please try again.",
      type: 'error',
    },
    [BannerMsgId.G]: {
      message:
        'Sorry, that verification code has expired. Use the link to resend verifcation code and try again.',
      type: 'error',
    },
    [BannerMsgId.L]: {
      message:
        "Sorry, we're unable to complete your enrolment. Please try again.",
      type: 'error',
    },
    [BannerMsgId.CARD_DECLINED]: {
      message:
        'We were unable to process your payment with the provided payment method. Please provide a different payment method and try again.',
      type: 'error',
    },
    [BannerMsgId.NOT_A_CUSTOMER]: {
      message: <NotACustomer partnerName={Partner.ATT} />,
      type: 'error',
    },
    [BannerMsgId.TBD2]: {
      message:
        'Sorry, the device entered is not eligible for protection. Please enroll a different device.',
      type: 'error',
    },
    [BannerMsgId.TBD4]: {
      message:
        "Thanks! We've re-sent a text message to the number provided. This reminder will include a link to enroll in Mobile Protection for AT&T Prepaid.",
      type: 'error',
    },
    [BannerMsgId.SUCCESS_1]: {
      message: 'Verification code resent.',
      type: 'success',
    },
    [BannerMsgId.SUCCESS_2]: {
      message:
        'We sent a confirmation email with full Terms and Conditions to the email address provided.',
      type: 'success',
    },
    [BannerMsgId.SUCCESS_3]: {
      message: 'We sent a confirmation email to the email address provided.',
      type: 'success',
    },
    [BannerMsgId.ONBOARDING_1]: {
      message:
        'There was a problem reserving your appointment. Please try again.',
      type: 'error',
    },
    [BannerMsgId.PRODUCT_NOT_AVAILABLE]: {
      message: 'This product is not available in New York.',
      type: 'error',
    },
  },

  // ACCOUNT MANAGEMENT
  accountActions: {
    failedToFetch: 'Failed to fetch billing info',
    didNotRecognize:
      "We didn't recognize that mobile number. Try entering it again below.",
    thanksBanner:
      "Thanks! We've updated your billing day.\nWe'll still charge you a prorated amount on your original billing date. Then on your new billing date, we'll begin charging you the full amount.",
    failedToFetchMDN: 'Failed to fetch MDN info',
    doesNotMatch:
      "Sorry, that verification code doesn't match. Please try again.",
    expired:
      'Sorry, that verification code has expired. Use the link to resend verification code and try again.',
    thanksBanner2: "Thanks! We've updated your name.",
    failedName: 'Failed to update name',
    updated({ email1, email2 }) {
      return email1
        ? `We've updated your email address and sent confirmation emails to ${email1} and ${email2}.`
        : `We've updated your email address and sent a confirmation email to ${email2}.`
    },
    failedEmail: 'Failed to update email',
    successCatchUp: `You have successfully made a catch up payment.`,
    errorPleaseCall(error, num) {
      return `${error} Please call ${num}.`
    },
    errorMakingPayment(num) {
      return `Error occurred while making payment. Please try again later or call ${num}.`
    },
    failedToFetchSecToken: 'Failed to fetch security token',
    errorUpdatingPayment(num) {
      return `Failure updating payment info. Please try again later or call ${num}.`
    },
    updatedBillingInfo(email) {
      return (
        "We've updated your billing info and sent a confirmation email to: " +
        email
      )
    },
    failureUpdatingPaymentInfo(num) {
      return `Failure updating payment info. Please try again later or call ${num}.`
    },
    failedToUpdatePaymentInfo: 'Failed to update payment info',
    successfullySignedOut: `You have successfully signed out.`,
    sentVerification(destination) {
      return `We've sent your verification code to ${destination}.`
    },
    successfulCancellationGeneric: `
      You have successfully cancelled your plan and we have sent you a
      confirmation email.
    `,
    successfulCancellationWithDate(date) {
      return `You have successfully cancelled your plan and we have sent you a
      confirmation email. You will continue to have coverage until ${date}.`
    },
    successfulCancellationRefundEligible: `
      You have successfully cancelled your plan and we have sent you a
      confirmation email. Your first month's premium will be refunded within 7 business days.
    `,
    errorCancellingPlan(num) {
      return `Error canceling plan. Please try again later or call ${num}.`
    },
    failedBillingDate: 'Failed to update billing date',
    missingFindAgreementsResponseData: 'Missing findAgreementResponse data',
    missingBillingDetails:
      'Billing Details are missing in updateBankAccountPaymentMethod',
  },
  accountMain: {
    yourAccount: 'Your account',
    overview: 'Overview',
    billingAndPaymentHistory: 'Billing & payment history',
  },
  accountApp: {
    dontHaveAnAccount: "Don't have an account?",
    enrollNow: 'Enroll now.',
  },
  bankAccountChangeForm: {
    nameOfAccountHolder: 'Name of account holder',
    sortCode: 'Sort code',
    accountNumber: 'Account number',
  },
  misc: {
    loading: 'Loading',
    change: 'Change',
    cancel: 'Cancel',
    saveChanges: 'Save changes',
    view: 'View',
    name: 'Name',
    email: 'Email',
    bankAccountEndingIn: 'Bank account ending in',
    noPaymentMethod: 'No payment method on file.',
    makeAPaymentNow: 'Make a payment now.',
    pleaseContact: 'Please contact',
    yourAccountIsPastDue: 'Your account is past due!',
    yourAccountIsPending: 'Your account is pending.',
    pleaseUpdatePaymentMethod: 'Please update payment method.',
    updatePaymentMethod: 'Update payment method',
    pay: 'Pay',
    closeIcon: 'Close icon',
    cardNumber: 'Card number',
    creditCardIcon: 'Credit card icon',
    continue: 'Continue',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
  },
  billing: {
    billingInformation: 'Billing information',
    billingStatus: 'Billing status',
    current: 'Current',
    pay: 'Pay',
    yourNextPayment: 'Your next payment',
    ofTheMonth: 'of the month',
    recurringPaymentTotal: 'Recurring payment total',
    paymentMethod: 'Payment method',
    paymentHistory: 'Payment history',
    referenceId: 'Reference ID',
    amount: 'Amount',
    date: 'Date',
    haveABillingQuestion: 'Have a billing question? Call us at',
  },
  billingDateModalContent: {
    changeBillingDate: 'Change billing date',
    currentBillingDay: 'Current billing day',
    everyDayOfTheMonth(day) {
      return `Every ${day} of the month`
    },
    newBillingDay: 'New billing day',
  },
  cancelPlanModalContent: {
    cancelPlanReason: {
      [TerminationReason.REGRETTERM]: 'I changed my mind',
      [TerminationReason.ND]:
        'I declined to take insurance but was added anyway',
      [TerminationReason.PRSSRDSL]: 'I was pressured to take insurance',
      [TerminationReason.INSRNCNTRQ]: "I wasn't aware I had insurance",
      [TerminationReason.NOMNYTERM]:
        "The insurance is too expensive / I can't afford it",
      [TerminationReason.SVCPRVCHNG]: 'I found insurance elsewhere',
      [TerminationReason.NDVC]: 'I no longer have this device',
      [TerminationReason.TIEDSALOLD]: 'The cost was not explained to me',
      [TerminationReason.PF]: 'Fraudulent policy', // NOT SHOWN
      [TerminationReason.CSTDSFNCLT]: 'I am receiving poor service',
      [TerminationReason.CSTMDSTSFN]:
        'I am receiving poor service from Asurion', // NOT SHOWN
    },
    areYouSure: 'Are you sure you want to cancel your plan?',
    ifYouCancel(endingDate) {
      return `If you cancel your plan, you will no longer have protection for your
          device. Any incident that occurs after ${endingDate} will not be
          covered.`
    },
    questions(num) {
      return `Questions about your plan? Before canceling, call us at ${num}.`
    },
    yesCancel: 'Yes, cancel my plan.',
    noCancel: 'No, keep my plan.',
    pleaseShare: "Please share why you're cancelling your plan.",
    cancelNow: 'Cancel my plan now.',
    nevermind: 'Nevermind, keep my plan.',
  },
  creditCardChangeForm: {
    address1: 'Address 1',
    address2: 'Address 2',
    streetAddressPlaceholder: 'Street address',
    streetAddress2Placeholder: 'PO box, apt., suite, etc.',
    city: 'City',
    county: 'County',
    state: 'State',
    postCode: 'Post code',
    zipCode: 'ZIP code',
    zipCodeHint: 'ZIP code must be 5 digits',
    address: 'Address',
    enterManually: 'Enter address manually.',
    nameOnCard: 'Name on card',
    nameHint: 'Must enter a name',
    creditCardNumber: 'Credit card number',
    expirationDate: 'Expiration date',
    securityCode: 'Security code',
    cardNumberPlaceholder: 'Card number',
    cardNumberHint: 'Enter a valid credit card number',
    expDatePlaceholder: 'MM/YYYY',
    cvvHint: 'Must be 3 or 4 numbers',
    saveChanges: 'Save changes',
    havingTrouble: 'Having trouble?',
  },
  emailModalContent: {
    changeEmail: 'Change email',
    currentEmail: 'Current email',
    newEmail: 'New email',
    newEmailHint: 'Enter valid email address',
    confirmNewEmail: 'Confirm new email',
    confirmNewEmailHint: 'Emails do not match',
    saveChanges: 'Save changes',
  },
  enrolledDeviceModalContent: {
    yourEnrolledDevice: 'Your enrolled device',
    currentEnrolledDevice: 'Current enrolled device',
    deviceAltText: 'Device',
    enrolledDate: 'Enrolled date',
  },
  makePaymentModalContent: {
    makePayment: 'Make Payment',
    billingStatus: 'Billing status',
    paymentAmount: 'Payment amount',
    paymentMethod: 'Payment method',
    methodOnFileRadio(method) {
      return `Use payment method on file: ${method}`
    },
    useDifferentMethod:
      'Use a different payment method and make it my new payment method for future billing',
    updateAndMakePayment: 'Update and make payment',
  },
  nameModalContent: {
    changeName: 'Change name',
    name: 'Name',
  },
  overview: {
    lastPayment(date) {
      return `Last payment was on ${date}`
    },
    noPreviousPayments: 'No previous payments.',
    youAreNotEnrolled: 'You are not enrolled in a plan.',
    planInformation: 'Plan information',
    planDetails: 'Plan details',
    enrolledDate: 'Enrolled date',
    terminationDate: 'End date',
    enrolledPhoneNumber: 'Enrolled phone number',
    enrolledDevice: 'Enrolled device',
    paymentMethod: 'Payment method',
    yourNextPayment: 'Your next payment',
    everyOfTheMonth(day) {
      return `Every ${day} of the month`
    },
    paymentHistory: 'Payment history',
    cancelPlan: 'Cancel plan',
    contactInformation: 'Contact information',
    communications: 'Communications',
    emailsAndOther: 'Emails and other digital communications',
  },
  paymentModalContent: {
    changePaymentMethod: 'Change payment method',
  },
  paymentStatus: {
    statusIconAltText: 'Status icon',
    current: 'Current',
    pastDue: 'Past due',
    pending: 'Pending',
  },
  planModalContent: {
    planDetails: 'Plan details',
    comprehensive: 'Comprehensive coverage and support for your mobile phone.',
    insurance: 'Insurance',
    asSoonAsNext:
      'As soon as next-day phone replacement for loss, theft, damage, and out-of-warranty malfunctions.',
    screenRepair:
      'Screen repair as soon as the same day for eligible phones in select areas.',
    upToTwo:
      'Up to two claims within any consecutive 12 months with a maximum device value of $2,000 per claim.',
    proTech: 'ProTech support and app',
    accessToExpert:
      'Access to expert tech support for your covered phone when you need it.',
    helpWithDevice:
      'Help with device set up and connecting to Bluetooth and other smart devices.',
    callOrChat:
      'Call or chat with an expert directly through the AT&T ProTech app.',
    attPhotoStorageApp: 'AT&T Photo Storage App',
    freeUpSpace:
      'Free up space on your phone with 50GB of secure photo/video storage.',
    automatically:
      'Automatically and securely back up your photos and videos without sacrificing quality.',
  },
  smartPinDelivery: {
    weWillSend: "We'll send you a verification code.",
    textMyCode: 'Text my code',
    emailMyCode: 'Email my code',
    textItToMe(mdn: string) {
      return `Text it to me at ${mdn}`
    },
    emailItToMe(email: string) {
      return `Email it to me at ${email}`
    },
  },
  terminalError: {
    sorry: "Sorry, we're unable to continue.",
    aSystemError: 'A system error has occurred.',
    pleaseWait(num) {
      return `Please wait a few minutes and try again. If the problem continues,
        please call us at ${num} weekdays 8am – 9pm, Saturdays 8am –
        8pm, and Sundays 9am – 6pm. We're happy to help.
      `
    },
  },
  'AEP-430': {
    h1: "Sorry, we can't sign you in.",
    p({
      partnerName,
      customerServiceNumber,
    }: {
      partnerName: string
      customerServiceNumber: string
    }) {
      return `${partnerName} online account management currently doesn't support family plans. Please call ${customerServiceNumber} to manage your account.`
    },
  },
  'AEP-431': {
    h1: 'Contact Us',
    sub() {
      return 'We noticed a problem with your account.'
    },
    p({
      partnerName,
      customerServiceNumber,
    }: {
      partnerName: Partner
      customerServiceNumber: string
    }) {
      return `Please call ${customerServiceNumber} to speak to a ${partnerName} agent about the issue.`
    },
    content() {
      return 'You can call the Tesco Mobile Protect team on +44(0)345 030 3290, 8am-9pm Monday to Friday, 8am-8pm on Saturday and 9am-6pm on Sunday. We are also open on Bank Holidays (excluding Christmas Day and Easter Sunday). Calls to 03 numbers cost the same as calls to UK landlines starting 01 and 02. Calls from landlines and mobiles are included in free or inclusive calls packages.'
    },
  },
  sharedComponents: {
    signIn: {
      continue: 'Continue',
      signIn: 'Sign in',
      getYouEnrolled: "Let's get you enrolled!",
      dontHavePhone: "I don't have my phone",
      disclaimer:
        'By continuing, you agree to receive program information by text message at the number you provided. Data rates may apply.',
      makeSure: [
        'Make sure you have your phone with you and turned on.',
        'Then, enter the phone number below.',
      ],
    },
    smartPin: {
      enterCode: 'Enter the verification code we just sent you.',
      itMayTake: 'It may take a minute to receive it.',
      sendAgain: 'Send the code again.',
    },
    misc: {
      continue: 'Continue',
      back: 'Back',
    },
    pinEntry: {
      verificationCode: 'Verification code',
    },
  },

  onboarding: {
    confirm: {
      confirmYourProTechCall: 'Confirm your ProTech call.',
      proTechExpertWillCall: ['Your ProTech Expert will call you at', 'on'],
      heWillCallYouAt: 'He/she will call you at',
      confirm: 'Confirm',
      pleaseChoose: 'Please choose an available appointment.',
    },
    dayPicker: {
      showOtherDays: 'Show Other Days',
      today: 'Today',
      chooseADay: 'Choose a day:',
    },
    end: {
      pleaseChoose: 'Please choose an available appointment.',
      title: 'ProTech Call',
      location: 'Phone Call',
      description: 'Your ProTech Expert will call you at this time.',
      youreAllSet: "You're all set!",
      yourProTechCall: 'Your ProTech Call',
      when: 'When',
      where: 'Where',
      wellCallYou: "We'll call you at",
      notes: 'Notes',
      makeSure:
        'Make sure you have your enrolled phone available when we call.',
      phoneWithFixedScreen: 'Phone with fixed screen.',
      download: 'Download the AT&T ProTech App',
      getFast: 'Get fast access to tech support with the AT&T ProTech app.',
      chat: 'Chat with or call an expert for personalized support',
      selfHelp: 'Get self-help tips customized for your device',
      linkDirectly: 'Link directly to the AT&T Photo Storage℠ app',
      googlePlayButton: 'Google Play button',
    },
    misc: {
      change: 'Change',
      or: 'or',
      phoneNumber: 'Phone number',
      continue: 'Continue',
      back: 'Back',
    },
    numberPicker: {
      whatNumber: 'What phone number should we call?',
      yourEnrolledDevice: 'Your enrolled device:',
      anotherNumber: 'Another number:',
    },
    onboarding: {
      stepTitles: [
        'Start',
        'Tell us when to call',
        'Tell us what number to call',
        'Finish',
      ],
    },
    onboardingHeader: {
      backButtonIcon: 'Back button icon',
    },
    scheduleHeader: {
      whenToCall(firstName: string) {
        return `${firstName}, when would you like your ProTech Expert to call you?`
      },
      whatTime(date: string) {
        return `What time would you like your ProTech Expert to call you ${date}`
      },
    },
    timePicker: {
      chooseATime: 'Choose a time...',
      selectTime: 'Select time...',
    },
  },
}

export default enGb
