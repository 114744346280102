export const CHAR_LIMIT = {
  FIRST_NAME_LIMIT: 15,
  LAST_NAME_LIMIT: 35,
  ADDRESS_1_LIMIT: 50,
  ADREESS_2_LIMIT: 15,
  CITY_LIMIT: 40,
  STATE_LIMIT: 30,
}

export const REG_EXP = {
  EMPTY_STRING: new RegExp(/([^\s])/),
  EXTRA_WHITE_SPACE: new RegExp(/\s+/g),
}

export const NOTE_TEXTS = {
  CONFIRM_LOWER_PREMIUM: `Survey Outcome: Customer confirmed 'yes' for lower (screen cover) premium program`,
  DECLINE_LOWER_PREMIUM: `Survey Outcome: Customer declined the lower (screen cover) premium program`,
  NO_RESPONSE_TO_LOWER_PREMIUM: `Survey Outcome: Customer didn't respond to the lower (screen cover) premium program`,
}

export const NOTE_TYPES = {
  AGREEMENT: 'AGRMNT',
}

export const enum TXN_TYPE {
  CC = 'CC',
  DD = 'DD',
}

export const MICRO_UI_ERROR_CODES = {
  'BG-90025': {
    message: 'Max attempts exceeded. Please try again later.',
    closeMicroUI: true,
    showAlert: false,
  },
  'Tokenization Failed - 3D Not Authenticated': {
    message: '3D Secure authentication failed.',
    closeMicroUI: false,
    showAlert: true,
  },
  'Tokenization Failed - CVC Declined': {
    message: 'Invalid CVV. Please enter a valid CVV.',
    closeMicroUI: false,
    showAlert: true,
  },
}

export const MICRO_UI_ERROR_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
}

export const NOTIFICATION_TIMEOUT = 10000
