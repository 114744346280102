import moment from 'moment'
import { IInvoice } from '../interfaces'

export default (
  invoices: IInvoice[]
): { invoiceIds: number[]; amount: number } => {
  const invoiceIds: number[] = []
  let amount = 0

  invoices
    .filter(({ status, billingCycleStartDate }) => {
      return (
        (status === 'SCHEDULED' ||
          status === 'PAYMENTERRORED' ||
          status === 'UNPAID' ||
          status === 'FAILED' ||
          status === 'CREATED') &&
        moment(billingCycleStartDate, 'YYYY-MM-DD').isSameOrBefore(moment())
      )
    })
    .forEach((invoice) => {
      amount += invoice.invoiceAmount
      invoiceIds.push(parseInt(invoice.invoiceId, 10))
    })

  return {
    invoiceIds,
    amount,
  }
}
