import { useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'

// This one doesn't work because useMediaQuery is not yet able to
//   take an array of queryInput. We're on the bleeding edge like haxxzorz!
//   https://github.com/mui-org/material-ui/pull/15678/commits/c4a264d9f910cb7c4b068a2ac97f39b1edd1076f
// export default function useWidth() {
//   const theme = useTheme()
//   const keys = [...theme.breakpoints.keys].reverse()
//   const queries = useMediaQuery(keys.map(key => theme.breakpoints.only(key)))
//
//   return (
//     queries.reduce((output, matches, index) => {
//       return !output && matches ? keys[index] : output
//     }, null) || 'xs'
//   )
// }

export default function useWidth() {
  const theme = useTheme()

  return (
    [...theme.breakpoints.keys].reverse().reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.only(key))

      return !output && matches ? key : output
    }, null) || 'xs'
  )
}
