import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { IFindAgreementResponse } from '../../interfaces'
import moment from 'moment'
import formatDateByCountry from '../../sharedHelpers/formatDateByCountry'
import chooseCountryFromPartner from '../../../sharedHelpers/getCountryFromPartner'
import { useConfig } from '../state/accountConfigSetup'
import { useLocale } from '../../localization'

interface IProps {
  details: IFindAgreementResponse
}

const EnrolledDeviceModalContent: React.FC<IProps> = ({ details }) => {
  const { strings } = useLocale()
  const { PARTNER } = useConfig()
  const COUNTRY = chooseCountryFromPartner(PARTNER)

  return (
    <>
      <Box mb={2}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.enrolledDeviceModalContent.yourEnrolledDevice}
        </Typography>
      </Box>

      <Box fontWeight={500} component="h4" color="black" mb={3}>
        {strings.enrolledDeviceModalContent.currentEnrolledDevice}
      </Box>

      <Box display="flex" mb={3}>
        {details.asset.imagePath && (
          <img
            width="38"
            height="76"
            src={details.asset.imagePath}
            alt={strings.enrolledDeviceModalContent.deviceAltText}
          />
        )}
        <Box data-cy="device-name" ml={details.asset.imagePath ? 2 : 0}>
          {details.asset.assetCatalog.name}
          <br />
          <Box fontSize={14}>
            {strings.enrolledDeviceModalContent.enrolledDate}{' '}
            {formatDateByCountry(
              moment(details.product.startDate, 'YYYY-MM-DD'),
              COUNTRY
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EnrolledDeviceModalContent
