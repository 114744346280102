import React from 'react'
import { Redirect } from 'react-router'
import { useActions } from '../state/accountStateMgmt'

const SignOut = () => {
  useActions().signOut()

  return <Redirect to="/" />
}

export default SignOut
