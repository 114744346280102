import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography, Box } from '@material-ui/core'
import MainPaper from './MainPaper'
import { useConfig } from '../state/accountConfigSetup'
import { useLocale } from '../../localization'
import { useStore } from '../state/accountStateMgmt'
import { AccountErrorCode } from '../../interfaces'

const useStyles = makeStyles((_: Theme) => ({
  container: {
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 600,
  },
}))

const TerminalError = () => {
  const { CUST_SERV_PHONE_NO, PARTNER } = useConfig()
  const { strings } = useLocale()
  const { errorCode } = useStore()
  const classes = useStyles()

  const chooseError = (code?: AccountErrorCode) => {
    switch (code) {
      case AccountErrorCode['AEP-430']: {
        return (
          <MainPaper>
            <Typography variant="h4" component="h1" data-cy="title">
              {strings['AEP-430'].h1}
            </Typography>

            <Box mt={3}>
              <Typography variant="body1" component="p" data-cy="body">
                {strings['AEP-430'].p({
                  customerServiceNumber: CUST_SERV_PHONE_NO,
                  partnerName: PARTNER,
                })}
              </Typography>
            </Box>
          </MainPaper>
        )
      }
      case AccountErrorCode['AEP-431']: {
        return (
          <MainPaper>
            <Typography variant="h4" component="h1" data-cy="title">
              {strings['AEP-431'].h1}
            </Typography>
            <Typography variant="h5" data-cy="subheading">
              {strings['AEP-431'].sub()}
            </Typography>
            <Box mt={3}>
              <Typography variant="body1" component="p" data-cy="body">
                {strings['AEP-431'].p({
                  customerServiceNumber: CUST_SERV_PHONE_NO,
                  partnerName: PARTNER,
                })}
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="body1" component="p" data-cy="body">
                {strings['AEP-431'].content()}
              </Typography>
            </Box>
          </MainPaper>
        )
      }

      default: {
        return (
          <MainPaper>
            <Typography variant="h4" component="h1" data-cy="title">
              {strings.terminalError.sorry}
            </Typography>

            <Box mt={1}>
              <Typography variant="h5" component="h2" data-cy="subHeading">
                {strings.terminalError.aSystemError}
              </Typography>
            </Box>

            <Box mt={3}>
              <Typography variant="body1" component="p" data-cy="body">
                {strings.terminalError.pleaseWait(CUST_SERV_PHONE_NO)}
              </Typography>
            </Box>
          </MainPaper>
        )
      }
    }
  }

  return (
    <Box px={4} py={8}>
      <div className={classes.container}>{chooseError(errorCode)}</div>
    </Box>
  )
}

export default TerminalError
