import { IDevice } from '../interfaces'

export const buildDeviceList = (rawList: IDevice[]): IDevice[] => {
  return [
    {
      color: '',
      id: '7031A',
      name: 'My phone is not listed',
      make: '',
      model: '',
      imei: '',
    },
    ...rawList.map((d: IDevice) => {
      return {
        ...d,
        name: `${d.name} ${d.color}`,
      }
    }),
  ]
}
