import globalConfig from '../../config'
import theme from './theme'
import { IAccountMgmtConfig, Locale, Partner } from '../../generic/interfaces'

const config: IAccountMgmtConfig = {
  API_BASE_URL: globalConfig.TESCO_API_BASE_URL,
  BG_ENDPOINT: globalConfig.BG_ENDPOINT,
  DISABLE_CAPTCHA: globalConfig.DISABLE_CAPTCHA,
  DISABLE_REFRESH: globalConfig.DISABLE_REFRESH,
  MIXPANEL_ENABLED: false, //globalConfig.MIXPANEL_ENABLED,
  PROTECH_LINK: globalConfig.PROTECH_LINK,
  RECAPTCHA_KEY: globalConfig.RECAPTCHA_KEY,
  IS_DOWN_FOR_MAINTENANCE: globalConfig.IS_DOWN_FOR_MAINTENANCE,
  SPEEDCLAIMS_URL: globalConfig.SPEEDCLAIMS_URL,
  COMMS_URL: globalConfig.COMMS_URL,
  ACCOUNT_PATH: `/account`,
  ALLOWED_CC_TYPES: 'VISA|MASTER|AMEX|DISC',
  APP_MAX_WIDTH: 1024,
  APP_MIN_WIDTH: 350,
  BYOD_ID: '7031A',
  CUST_SERV_PHONE_NO: '+44(0)345 030 3290',
  ENROLL_PATH: `/enroll`,
  THEME: theme,
  DEFAULT_LOCALE: Locale.EnglishGB,
  SHOW_ENROLL_NOW_CTA: false,
  SHOW_TEXT_MESSAGE_DISCLAIMER: false,
  SHOW_COMMUNICATIONS_PANEL: false,
  SHOW_CANCEL_PLAN_PANEL: true,
  SHOW_CANCELLATION_REASONS: true,
  USE_BRITISH_ADDRESSING: true,
  MDN_MASK: '99999999999',
  COUNTRY_CALLING_CODE: '44',
  PARTNER: Partner.Tesco,
  BILLING_PROGRAM_ID: '702294',
  ALLOW_NAME_CHANGE: false,
  ALLOW_MAKEUP_PAYMENT: false,
  ALLOW_EMAIL_SMART_PIN: false,
  TESCO_MICRO_UI_BPID: globalConfig.TESCO_MICRO_UI_BPID,
  TESCO_MICRO_UI_FEATURE_FLAG: globalConfig.TESCO_MICRO_UI_FEATURE_FLAG,
}

export default config
