import { useApi } from '../../state/tescoMarketingStateMgmt'
import { EventType } from '../../../../generic/interfaces'

export const useHandleLinkClick = (
  eventType: EventType,
  href: string,
  from?: string
) => {
  const apiClient = useApi()
  return async () => {
    await apiClient.trackEvent(eventType, {
      to: href,
      from: from || 'Tesco Homepage',
      flow: 'AcctMgmt',
    })
  }
}
