/* eslint-disable */

import 'react-app-polyfill/ie9'
import 'url-search-params-polyfill'

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict'
    if (typeof start !== 'number') {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    enumerable: false,
    value(obj) {
      let newArr = this.filter(function (el) {
        // noinspection EqualityComparisonWithCoercionJS
        return el == obj
      })
      return newArr.length > 0
    },
  })
}
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      let o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      let len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      let thisArg = arguments[1]

      // 5. Let k be 0.
      let k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        let kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return undefined.
      return undefined
    },
  })
}

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0 // truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (this.length >= targetLength) {
      return String(this)
    } else {
      targetLength = targetLength - this.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this)
    }
  }
}

if (!Object.freeze) {
  Object.freeze = function (obj) {
    const props = Object.getOwnPropertyNames(obj)

    for (let i = 0; i < props.length; i++) {
      const desc = Object.getOwnPropertyDescriptor(obj, props[i])

      if ('value' in desc) {
        desc.writable = false
      }

      desc.configurable = false
      Object.defineProperty(obj, props[i], desc)
    }

    return Object.preventExtensions(obj)
  }
}

if (!Promise.prototype.finally) {
  Promise.prototype['finally'] = function (callback) {
    const constructor = this.constructor
    return this.then(
      function (value) {
        return constructor.resolve(callback()).then(function () {
          return value
        })
      },
      function (reason) {
        return constructor.resolve(callback()).then(function () {
          throw reason
        })
      }
    )
  }
}

if (!Array.prototype.fill) {
  Object.defineProperty(Array.prototype, 'fill', {
    value: function (value) {
      // Steps 1-2.
      if (this == null) {
        throw new TypeError('this is null or not defined')
      }

      var O = Object(this)

      // Steps 3-5.
      var len = O.length >>> 0

      // Steps 6-7.
      var start = arguments[1]
      var relativeStart = start >> 0

      // Step 8.
      var k =
        relativeStart < 0
          ? Math.max(len + relativeStart, 0)
          : Math.min(relativeStart, len)

      // Steps 9-10.
      var end = arguments[2]
      var relativeEnd = end === undefined ? len : end >> 0

      // Step 11.
      var final =
        relativeEnd < 0
          ? Math.max(len + relativeEnd, 0)
          : Math.min(relativeEnd, len)

      // Step 12.
      while (k < final) {
        O[k] = value
        k++
      }

      // Step 13.
      return O
    },
  })
}

if (!Object.values) {
  Object.values = function (object) {
    return Object.keys(object).map((key) => object[key])
  }
}

if (!Object.entries)
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
