import { Partner } from '../generic/interfaces'

const partnersAndCurrencies: Record<Partner, string> = {
  [Partner.ATT]: 'USD',
  [Partner.Tesco]: 'GBP',
}

export default (partner: Partner): string => {
  return partnersAndCurrencies[partner]
}
