import SubmitButton from '../../sharedComponents/SubmitButton'
import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import convertNameToNameArray from '../../sharedHelpers/convertNameToNameArray'
import { useActions, useStore } from '../state/accountStateMgmt'
import { useLocale } from '../../localization'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IProps {
  currentName: string[]
  onClose: () => void
}
const NameModalContent: React.FC<IProps> = (props) => {
  const [newName, setNewName] = useState(props.currentName.join(' '))
  const classes = useStyles()
  const { loading } = useStore()
  const { updateName } = useActions()
  const { strings } = useLocale()

  const isFormValid = !!newName

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value?.replace('Backspace', ''))
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isFormValid) {
      updateName(
        // @ts-ignore
        ...convertNameToNameArray(newName)
      ).then(() => {
        props.onClose()
      })
    }
  }

  return (
    <>
      <Box mb={2}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.nameModalContent.changeName}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" py={3}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.nameModalContent.name}
          </Typography>
          <TextField
            value={newName}
            id={'name-input'}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <SubmitButton
            id={'name-submit'}
            text={strings.misc.saveChanges}
            disabled={!isFormValid}
            loading={loading}
          />
        </Box>
      </form>
      <Box
        className={classes.link}
        onClick={props.onClose}
        textAlign="center"
        fontWeight={500}
      >
        {strings.misc.cancel}
      </Box>
    </>
  )
}

export default NameModalContent
