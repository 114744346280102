import React from 'react'
import { Box, Flex, Image, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import Divider from '../../../../sharedComponents/atoms/Divider'
import logo from '../../assets/tesco-logo.png'
import TescoConstrainer from '../utilities/TescoConstrainer'
import { EventType } from '../../../../generic/interfaces'
import { useHandleLinkClick } from '../utilities/useHandleLinkClick'

const TescoFooter: React.FC<{ isSignedIn?: boolean }> = ({ isSignedIn }) => {
  return (
    <Flex flexWrap="wrap">
      <Box width={[1]} px={[3, 3, 5]} pt={3} pb={5}>
        <TescoConstrainer>
          <RouterLink to="/account/overview">
            <Image alt="Tesco logo" src={logo} width={150} />
          </RouterLink>

          <Flex
            mt={3}
            alignItems={['flex-start', 'center']}
            flexDirection={['column', 'row']}
            fontSize={1}
          >
            <Box
              sx={{
                display: isSignedIn ? 'none' : 'flex',
                alignItems: 'center',
              }}
            >
              <RouterLink
                to="/account/overview"
                style={{ textDecoration: 'none' }}
                onClick={useHandleLinkClick(
                  EventType.FooterMyAccountLinkClicked,
                  'My Account'
                )}
              >
                <Box
                  data-cy="manageAccount"
                  mr={[0, 3]}
                  sx={{ color: 'footerGray' }}
                >
                  Manage Account
                </Box>
              </RouterLink>
              <Box sx={{ display: ['none', 'block'] }}>
                <Divider color="black" height={20} />
              </Box>
            </Box>

            <Link
              href="https://www.tescomobile.com/about-us/terms-and-conditions"
              target="_blank"
              mx={[0, 3]}
              mt={[3, 0]}
              variant="footerLink"
              onClick={useHandleLinkClick(
                EventType.FooterTAndCLinkClicked,
                'https://www.tescomobile.com/about-us/terms-and-conditions'
              )}
            >
              Terms & Conditions
            </Link>
            <Box sx={{ display: ['none', 'block'] }}>
              <Divider color="black" height={20} />
            </Box>
            <Link
              href="https://europe.asurion.com/pdf/TermsOfUse.pdf"
              target="_blank"
              mx={[0, 3]}
              mt={[3, 0]}
              variant="footerLink"
              onClick={useHandleLinkClick(
                EventType.FooterTermsOfUseClicked,
                'https://europe.asurion.com/pdf/TermsOfUse.pdf'
              )}
            >
              Terms of Use
            </Link>
            <Box sx={{ display: ['none', 'block'] }}>
              <Divider color="black" height={20} />
            </Box>
            <Link
              href="https://europe.asurion.com/pdf/PrivacyPolicy.pdf"
              target="_blank"
              mx={[0, 3]}
              mt={[3, 0]}
              variant="footerLink"
              onClick={useHandleLinkClick(
                EventType.FooterPrivacyLinkClicked,
                'https://europe.asurion.com/pdf/PrivacyPolicy.pdf'
              )}
            >
              Privacy Policy
            </Link>
            <Box sx={{ display: ['none', 'block'] }}>
              <Divider color="black" height={20} />
            </Box>
            <Link
              href="https://www.tescomobile.com/contact-us/pay-monthly"
              target="_blank"
              mx={[0, 3]}
              mt={[3, 0]}
              variant="footerLink"
              onClick={useHandleLinkClick(
                EventType.FooterContactUsLinkClicked,
                'https://www.tescomobile.com/contact-us/pay-monthly'
              )}
            >
              Contact us
            </Link>
          </Flex>
        </TescoConstrainer>
      </Box>
    </Flex>
  )
}

export default TescoFooter
