import React from 'react'
import { Flex, Text } from 'rebass'
import { ErrorOutline } from '@material-ui/icons'

const ErrorBanner: React.FC<{ children: React.ReactNode | undefined }> = ({
  children,
}) => {
  return (
    <Flex py={3} pl={3} pr={4} bg="error" color="white" justifyContent="center">
      <ErrorOutline fontSize="large" />

      <Text mt="6px" ml={3} fontWeight={700}>
        {children}
      </Text>
    </Flex>
  )
}

export default ErrorBanner
