import React from 'react'
import { Box, Flex, Image, SxStyleProp, BoxProps } from 'rebass'
// import { FlexWrapProperty } from 'csstype'
import { ResponsiveValue } from 'styled-system'
import Constrainer from '../utilities/Constrainer'

interface ImageOverlapHeroProps extends BoxProps {
  imgAlt: string
  imgSrc: string
  backgroundStyle?: SxStyleProp
  displayBgArt?: boolean
  alignItems?: string
  flexWrap?: ResponsiveValue<'nowrap' | 'wrap' | 'wrap-reverse'>
  justifyContent?: string
  imageStyle?: SxStyleProp
  imagePaddingTop?: Array<string | number>
  imagePaddingRight?: Array<string | number>
  imagePaddingLeft?: Array<string | number>
  imagePaddingBottom?: Array<string | number>
  maxWidth: number
  minWidth: number
}
const ImageOverlapHero: React.FC<ImageOverlapHeroProps> = ({
  imgAlt,
  imgSrc,
  children,
  backgroundStyle = { backgroundColor: 'attDark' },
  alignItems = 'flex-start',
  flexWrap = 'wrap',
  justifyContent = 'space-between',
  imageStyle = {
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    display: ['none', 'none', 'block'],
  },
  imagePaddingTop = [0, 0, 50],
  imagePaddingRight = [0, 0, 50],
  imagePaddingLeft = [0, 0, 70],
  maxWidth,
  minWidth,
}) => {
  return (
    <Box pb={[5, 0]} sx={backgroundStyle}>
      <Constrainer maxWidth={maxWidth} minWidth={minWidth}>
        <Flex
          alignItems={alignItems}
          justifyContent={justifyContent}
          flexWrap={flexWrap}
        >
          <Box
            width={[1, 0.3, 0.4]}
            mt={[4, 4, 0]}
            mb={-6}
            sx={{ position: 'relative', display: ['none', 'none', 'block'] }}
          >
            <Box
              pt={imagePaddingTop}
              pr={imagePaddingRight}
              pl={imagePaddingLeft}
            >
              <Image alt={imgAlt} src={imgSrc} sx={imageStyle} />
            </Box>
          </Box>
          <Box width={[1, 1, 0.6]} color="white" pt={5} pb={[0, 4]} pr={3}>
            {children}
          </Box>
        </Flex>

        <Flex
          width={1}
          justifyContent="center"
          px={60}
          sx={{
            display: ['block', 'none'],
            position: 'absolute',
            left: '-8%',
          }}
        >
          <Image
            alt={imgAlt}
            src={imgSrc}
            sx={{
              height: 'auto',
              width: 'auto',
              maxHeight: '100%',
              maxWidth: '100%',
              display: ['none', 'block', 'block'],
            }}
          />
        </Flex>
      </Constrainer>
    </Box>
  )
}

export default ImageOverlapHero
