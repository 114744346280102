import { BillingDetails, IFindAgreementResponse } from '../interfaces'

export default (
  billingDetails: BillingDetails,
  details: IFindAgreementResponse
) => {
  return (
    billingDetails.name ||
    details.customer.firstName + ' ' + details.customer.lastName
  )
}
