import { Paper, makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(() => ({
  main: {
    display: 'block', // Fix IE 11 issue.
  },
  paper: {
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
}))

function MainPaper(props: { children?: ReactNode | ReactNode[] }) {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Paper className={classes.paper}>{props.children}</Paper>
    </div>
  )
}

export default MainPaper
