import React, { ReactElement, useEffect } from 'react'
import useRouter from 'use-react-router'

const ScrollToTop: React.FC<{ children?: React.ReactNode | undefined }> = ({
  children,
}) => {
  const {
    location: { pathname },
  } = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (children as ReactElement) || null
}

export default ScrollToTop
