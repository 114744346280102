import React from 'react'
import { Box, Button, ButtonProps, Flex } from 'rebass'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { useLocale } from '../../generic/localization'

interface BackProps extends ButtonProps {
  color?: string
  onClick: () => void
}
const Back: React.FC<BackProps> = ({ color, onClick, ...otherProps }) => {
  const { strings } = useLocale()

  return (
    <Button variant="unstyled" type="button" onClick={onClick} {...otherProps}>
      <Flex
        alignItems="center"
        color={color || 'primary'}
        sx={{ cursor: 'pointer' }}
      >
        <KeyboardArrowLeft style={{ color: 'inherit' }} fontSize="large" />
        <Box ml={-1} sx={{ fontWeight: 500 }}>
          {strings.sharedComponents.misc.back}
        </Box>
      </Flex>
    </Button>
  )
}

export default Back
