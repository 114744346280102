/* eslint-disable @typescript-eslint/camelcase */
/* This is mostly from the Mixpanel JS library to keep compatibility */

const _ = {
  includes: function (str, needle) {
    return str.indexOf(needle) !== -1
  },
}

const browser = (user_agent, vendor, opera) => {
  vendor = vendor || '' // vendor is undefined for at least IE9
  if (opera || _.includes(user_agent, ' OPR/')) {
    if (_.includes(user_agent, 'Mini')) {
      return 'Opera Mini'
    }
    return 'Opera'
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry'
  } else if (
    _.includes(user_agent, 'IEMobile') ||
    _.includes(user_agent, 'WPDesktop')
  ) {
    return 'Internet Explorer Mobile'
  } else if (_.includes(user_agent, 'Edge')) {
    return 'Microsoft Edge'
  } else if (_.includes(user_agent, 'FBIOS')) {
    return 'Facebook Mobile'
  } else if (_.includes(user_agent, 'Chrome')) {
    return 'Chrome'
  } else if (_.includes(user_agent, 'CriOS')) {
    return 'Chrome iOS'
  } else if (
    _.includes(user_agent, 'UCWEB') ||
    _.includes(user_agent, 'UCBrowser')
  ) {
    return 'UC Browser'
  } else if (_.includes(user_agent, 'FxiOS')) {
    return 'Firefox iOS'
  } else if (_.includes(vendor, 'Apple')) {
    if (_.includes(user_agent, 'Mobile')) {
      return 'Mobile Safari'
    }
    return 'Safari'
  } else if (_.includes(user_agent, 'Android')) {
    return 'Android Mobile'
  } else if (_.includes(user_agent, 'Konqueror')) {
    return 'Konqueror'
  } else if (_.includes(user_agent, 'Firefox')) {
    return 'Firefox'
  } else if (
    _.includes(user_agent, 'MSIE') ||
    _.includes(user_agent, 'Trident/')
  ) {
    return 'Internet Explorer'
  } else if (_.includes(user_agent, 'Gecko')) {
    return 'Mozilla'
  } else {
    return ''
  }
}

const browserVersion = (userAgent, vendor, opera) => {
  const b = browser(userAgent, vendor, opera)
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  }
  const regex = versionRegexs[b]
  if (regex === undefined) {
    return null
  }
  const matches = userAgent.match(regex)
  if (!matches) {
    return null
  }
  return parseFloat(matches[matches.length - 2])
}

const os = () => {
  const a = navigator.userAgent
  if (/Windows/i.test(a)) {
    if (a.includes('Phone') || a.includes('WPDesktop')) {
      return 'Windows Phone'
    }
    return 'Windows'
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS'
  } else if (a.includes('Android')) {
    return 'Android'
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry'
  } else if (/Mac/i.test(a)) {
    return 'Mac OS X'
  } else if (a.includes('Linux')) {
    return 'Linux'
  } else if (a.includes('CrOS')) {
    return 'Chrome OS'
  } else {
    return ''
  }
}

const device = (user_agent) => {
  if (/Windows Phone/i.test(user_agent) || user_agent.includes('WPDesktop')) {
    return 'Windows Phone'
  } else if (user_agent.includes('iPad')) {
    return 'iPad'
  } else if (user_agent.includes('iPod')) {
    return 'iPod Touch'
  } else if (user_agent.includes('iPhone')) {
    return 'iPhone'
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry'
  } else if (user_agent.includes('Android')) {
    return 'Android'
  } else {
    return ''
  }
}

const referringDomain = (referrer) => {
  const split = referrer.split('/')
  if (split.length >= 3) {
    return split[2]
  }
  return ''
}

const strip_empty_properties = (p) => {
  const ret = {}
  for (const [k, v] of Object.entries(p)) {
    if (typeof v === 'string' && v.length > 0) {
      ret[k] = v
    }
  }
  return ret
}

const trackingProps = () => {
  return {
    ...strip_empty_properties({
      $os: os(),
      $browser: browser(navigator.userAgent, navigator.vendor, window.opera),
      $referrer: document.referrer,
      $referring_domain: referringDomain(document.referrer),
      $device: device(navigator.userAgent),
    }),
    $current_url: window.location.href,
    $browser_version: browserVersion(
      navigator.userAgent,
      navigator.vendor,
      window.opera
    ),
    $screen_height: window.screen.height,
    $screen_width: window.screen.width,
    ...JSON.parse(window.sessionStorage.getItem('t_p') || {}),
    $browser_language: navigator.language,
  }
}

export default trackingProps
