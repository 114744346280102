import {
  BillingDetails,
  BillingStatus,
  Channel,
  IBillingAddress,
  IBillingContact,
  IDevice,
  IInvoice,
  ISecurityToken,
  Partner,
} from './misc'
import { EventType } from './events'
import { TXN_TYPE } from '../accountMgmt/common/constants'

export interface IPostCCParams {
  cc_no: number
  cvv: number // 3 or 4 digits
  cc_exp_month: number // MM
  cc_exp_year: string // YYYY
  billingContact: {
    fname: string
    lname: string
    addr1: string
    addr2?: string
    city: string
    state: string
    zip: string
    email?: string
  }
  encryption_key: string
  security_token: string
}

export interface IPostBankAccountParams {
  billingContact: {
    fname: string
    lname: string
    addr1: string
    addr2?: string
    city: string
    state: string
    zip: string
    email?: string
  }
  bank_acct_no: number // Max length 19
  bank_routing_no: number // Max length 9
  encryption_key: string
  security_token: string
}

export interface IClientOfferServiceResponse {
  offers: IOffer[]
}

export interface IOffer {
  price: number
  name: string
  sku: string
}

export interface IAPIError<T> {
  error: {
    message: string
    code: string
    details?: T
  }
}

export interface IBeginEnrollmentRequest {
  countryCallingCode: string
  pin: string
}

export interface IBeginEnrollmentRequestPayload
  extends IBeginEnrollmentRequest,
    IBasicPayload {}

export interface IBeginEnrollmentResponseSuccess {
  asset: IDevice | null
  securityToken: ISecurityTokenResponse | ISecurityToken
}

export type IBeginEnrollmentResponse =
  | IBeginEnrollmentResponseSuccess
  | IAPIError<any>

export function isError<T, U>(arg: T | IAPIError<U>): arg is IAPIError<U> {
  return !!(arg as IAPIError<U>)?.error
}

export interface ISecurityTokenResponse {
  status: string
  returnCode: string
  returnMsg: string
  token: string
  encryptionKey: string
}

export interface IDeviceEligibilityRequest {
  assetCatalogId: string
  override: boolean
}

export interface IDeviceEligibilityPayload
  extends IDeviceEligibilityRequest,
    IBasicPayload {}

export interface IDeviceEligibilityResponse extends IDeviceEligibilityPayload {
  eligible: boolean
  error?: {
    code: string
    message: string
  }
}

export interface IBasicPayload {
  interactionLineId: string
  correlationId: string
  partner: Partner
  channel: Channel
}

export interface GetSecurityTokenPayload extends IBasicPayload {
  customerId: string
  sourceTransactionReference: string
  txnType?: TXN_TYPE
  subscriptionNumber?: string
  accountNumber?: string
  billingProgramId?: string
}

export interface IUpdateBillDateRequest {
  billDate: string
}

export interface IUpdateBillDatePayload
  extends IBasicPayload,
    IUpdateBillDateRequest {}

export interface IVerifyPinRequest {
  pin: string
}

export interface IVerifyPinPayload extends IVerifyPinRequest, IBasicPayload {}

export type IVerifyPinResponse = IAPIError<any> | {}

export enum VerificationResult {
  Expired,
  NoMatch,
  Verified,
}

export interface ISendSmartPinRequest {
  recaptcha: string
  phoneNumber: string
  countryCallingCode: string
  sendVia: string
}

export interface ISendSmartPinPayload
  extends IBasicPayload,
    ISendSmartPinRequest {
  interactionLineId: string
}

export interface ITrackEventPayload extends IBasicPayload {
  eventType: EventType
  props: object
}

export interface IFetchAccountInfoRequest {
  mdn: string
  countryCallingCode: string
}

export interface IFetchAccountInfoPayload
  extends IBasicPayload,
    IFetchAccountInfoRequest {}

export interface IFetchAccountInfoResponse {
  email: string
  hasEmail: boolean
}

export interface IFindAgreementResponse {
  address: {
    address1: string
    address2?: string
    city: string
    state: string
    postalCode: string
  }
  asset: {
    assetCatalog: {
      assetCatalogId: string
      name: string
      make?: {
        name: string
        makeId: string
      }
      model?: {
        name: string
        modelId: string
      }
    }
    imagePath?: string
    imei?: string
  }
  contact: {
    email?: string
    contactType?: string
  }
  customer: {
    firstName: string
    lastName: string
  }
  externalReferenceId: string
  product: IProduct
  status: string
  mdn: string
  terminationDate?: string // YYYY-MM-DD
  billingProfileId: string
  subscriptionNumber: string
}

export interface IProduct {
  retailAmount: number
  retailName: string
  retailSKU: string
  startDate: string
}

export interface IGetAppointmentsRequest {
  date: string
  utcOffset: string
  begin?: string // 09:00, 14:00, etc.
  end?: string // 09:00, 14:00, etc.
}

export interface IGetAppointmentsPayload
  extends IBasicPayload,
    IGetAppointmentsRequest {}

export interface IGetAppointmentsResponse {
  available: IServiceAvailableDate
}
export interface IServiceAvailableSlot {
  startTime: string
}
export interface IServiceAvailableDate {
  availableDate: string
  availableSlots: IServiceAvailableSlot[]
  duration: number
  timeZone: string
}

export interface IReserveAppointmentRequest {
  date: string // ISO
  contactPhone?: string
  assetId?: string
}
export interface IReserveAppointmentPayload
  extends IBasicPayload,
    IReserveAppointmentRequest {}
export interface IReserveAppointmentResponse {
  reserved: boolean
}

interface DDDetailsPayload {
  BillingAccountNumber: string
  RoutingNumber: string
  AccountHolderFirstName: string
  AccountHolderLastName: string
}

interface CCDetailsPayload {
  CardExpirationMonth: string
  CardExpirationYear: string
  CardHolderFirstName: string
  CardHolderLastName: string
  CardNumber: string
  CardPostalCode: string
  CardType: string
}
export interface IUpdateEnrollmentRequest {
  emailAddress?: string
  firstName?: string
  lastName?: string
  billingAddress?: IBillingAddress
  updatePaymentMethod?: boolean
  securityToken?: string
  paymentMethodDetails?: DDDetailsPayload | CCDetailsPayload
}

export interface IUpdateEnrollmentRequestPayload
  extends IUpdateEnrollmentRequest,
    IBasicPayload {}

export type IUpdateEnrollmentResponse = IAPIError<any> | {}

export interface IFinishEnrollmentRequest {
  address: IBillingAddress
  emailAddress: string
  firstName: string
  lastName: string
  imei: string | undefined
  asset: any
  totalAmount: string
  referral?: string
  salesPersonId?: string
  salesPersonName?: string
  salesDealerCode?: string
  offerSku?: string
  orderNum?: string
}

export interface IFinishEnrollmentPayload
  extends IFinishEnrollmentRequest,
    IBasicPayload {}

export interface IBillingInfoResponse {
  billingAddress: IBillingAddress
  billingContact: IBillingContact
  billingDetails: BillingDetails
  billingHistory: IInvoice[]
  billingStatus: BillingStatus
}

export interface IMakeAdhocPaymentRequest {
  billingProfileId: string
  invoiceIds: number[]
  amount: number
  firstName: string
  lastName: string
}

export interface IMakeAdhocPaymentPayload
  extends IMakeAdhocPaymentRequest,
    IBasicPayload {}

export type IMakeAdhocPaymentResponse =
  | IAPIError<any>
  | {
      status: string
    }

export interface ABTestingConfig {
  experience: string
  deviceHealthCheck?: boolean
  [key: string]: any
}

export interface IInitData {
  configuration?: ABTestingConfig
  interactionLineId: string
  correlationId: string
}

export interface IPlan {
  name: string
  price: number
  sku: string
  description?: string
}

export interface IApiClientConstructorParams {
  apiBaseUrl: string
  channel: Channel
  isTrackingEnabled: boolean
  token?: string
  billingProfileId?: any
  subscriptionId?: any
}

export enum TerminationReason {
  // noinspection JSUnusedGlobalSymbols
  REGRETTERM = 'REGRETTERM',
  ND = 'ND',
  PRSSRDSL = 'PRSSRDSL',
  INSRNCNTRQ = 'INSRNCNTRQ',
  NOMNYTERM = 'NOMNYTERM',
  SVCPRVCHNG = 'SVCPRVCHNG',
  NDVC = 'NDVC',
  TIEDSALOLD = 'TIEDSALOLD',
  PF = 'PF',
  CSTDSFNCLT = 'CSTDSFNCLT',
  CSTMDSTSFN = 'CSTMDSTSFN',
}

export interface ICancelPlanRequest {
  terminationReason: TerminationReason
}

export interface ICancelPlanPayload extends ICancelPlanRequest, IBasicPayload {}

export interface ICancelPlanResponse {
  cancelled: boolean
  cancellationDate?: string
  refundEligible: boolean
}

export interface IDeductibleLookupRequest {
  assetCatalogId: string
  postalCode: string
}

export interface IDeductibleLookupPayload
  extends IBasicPayload,
    IDeductibleLookupRequest {}

export interface IDeductibleLookupResponse {
  repairEligible: boolean
  coveredPeril?: {
    crackedScreen: boolean
    batteryRepair: boolean
    malfunction: boolean
    liquid: boolean
  }
  serviceType?: {
    onSite: boolean
    inStore: boolean
  }
  deductibles?: {
    serviceFees: FormattedServiceFee[]
    currencyCode: string
  }
  assetImagePath?: string
}

export interface FormattedServiceFee {
  amount: number
  coveredEvent?: CoveredEventToLabelMap
  fulfillmentOption: FulfillmentOptionToLabelMap
}

export enum CoveredEventToLabelMap {
  ACSRepair = 'ACSR',
  ACSReplacement = 'ACSREPL',
  CrackScreen = 'CRKSCR',
  CosmeticDamage = 'COSDMG',
  Other = 'OTHDMG',
  LiquidDamage = 'LQDDMG',
  Malfunction = 'DEVMALF',
  Repair = 'DEVREP',
  Replacement = 'DEVREPL',
}

export enum FulfillmentOptionToLabelMap {
  Repair = 'Repair',
  Replacement = 'Replace',
}

export interface IAssetAttributesRequest {
  assetCatalogId: string
}

export interface IAssetAttributesPayload
  extends IBasicPayload,
    IAssetAttributesRequest {}

export interface IAssetAttributesResponse {
  hasImage: boolean
  imagePath: string
}

export interface INoteData {
  noteType: string
  noteText: string
  createdBy: string
}

export interface ICreateNotePayload {
  correlationId: string
  interactionLineId: string
  partner: string
  channel: string
  noteType: string
  noteText: string
  createdBy: string
}

export interface ICreateNoteResponse {
  entityId: string
}
