import { ReactNode } from 'react'

export enum PortalErrId {
  INIT_1,
  RECAPTCHA_1,
  RECAPTCHA_2,
  ELIGIBILITY_1,
  ELIGIBILITY_2,
  ELIGIBILITY_3,
  ELIGIBILITY_4,
  ELIGIBILITY_5,
  SMART_PIN_1,
  SMART_PIN_2,
  SMART_PIN_3,
  SMART_PIN_4,
  CC_1,
  CC_2,
  CC_3,
  FRM_1,
  ENROLLMENT_1,
  SMS_1,
  AGENT_1_A,
  AGENT_1_B,
  AGENT_1_C,
  AGENT_1_D,
  AGENT_2,
  AGENT_3,
  AGENT_4,
  AGENT_5_A,
  AGENT_5_B,
  AGENT_6,
  SUCCESS_2,
  SUCCESS_3,
  ONBOARDING_1,
}

export interface IPortalErr {
  triesAllowed: number
  defaultErrMsg: TerminalErrMsgId | BannerMsgId
  finalErrMsg: TerminalErrMsgId | BannerMsgId
}

export enum BannerMsgId {
  B = 'BANNER_B',
  E = 'BANNER_E',
  G = 'BANNER_G',
  L = 'BANNER_L',
  CARD_DECLINED = 'BANNER_CARD_DECLINED',
  NOT_A_CUSTOMER = 'BANNER_NOT_A_CUSTOMER',
  TBD2 = 'BANNER_TBD2',
  TBD4 = 'BANNER_TBD4',
  SUCCESS_1 = 'BANNER_SUCCESS_1',
  SUCCESS_2 = 'BANNER_SUCCESS_2',
  SUCCESS_3 = 'BANNER_SUCCESS_3',
  ONBOARDING_1 = 'BANNER_ONBOARDING_1',
  PRODUCT_NOT_AVAILABLE = 'PRODUCT_NOT_AVAILABLE',
}

export interface IBannerCopy {
  message: ReactNode
  type: 'error' | 'success'
}

export enum TerminalErrMsgId {
  D = 'TERMINAL_D',
  F = 'TERMINAL_F',
  H = 'TERMINAL_H',
  I = 'TERMINAL_I',
  K = 'TERMINAL_K',
  TBD1 = 'TERMINAL_TBD1',
  TBD3 = 'TERMINAL_TBD3',
  TBD5 = 'TERMINAL_TBD5',
  AGENT_1_A = 'TERMINAL_AGENT_1_A',
  AGENT_1_B = 'TERMINAL_AGENT_1_B',
  AGENT_1_C = 'TERMINAL_AGENT_1_C',
  AGENT_1_D = 'TERMINAL_AGENT_1_D',
  AGENT_2 = 'TERMINAL_AGENT_2',
  AGENT_3 = 'TERMINAL_AGENT_3',
  AGENT_4 = 'TERMINAL_AGENT_4',
  AGENT_5_A_DEFAULT = 'TERMINAL_AGENT_5_A_DEFAULT',
  AGENT_5_A_FINAL = 'TERMINAL_AGENT_5_A_FINAL',
  AGENT_5_B_DEFAULT = 'TERMINAL_AGENT_5_B_DEFAULT',
  AGENT_5_B_FINAL = 'TERMINAL_AGENT_5_B_FINAL',
  AGENT_6 = 'TERMINAL_AGENT_6',
  SUPER_1 = 'SUPER_1',
  ALREADY_COVERED = 'TERMINAL_ALREADY_COVERED',
  PAST_ELIGIBILITY_WINDOW = 'PAST_ELIGIBILITY_WINDOW',
  NOT_A_CUSTOMER = 'NOT_A_CUSTOMER',
  CARD_DECLINED = 'TERMINAL_CARD_DECLINED',
}

export interface ITerminalErrCopy {
  title: string
  subHeading: string
  body: ReactNode
  bullets?: string[]
}

export enum AccountErrorCode {
  'AEP-430',
  'AEP-431',
}
