import { BillingDetails, isCCDetails } from '../interfaces'
import { formatCardType } from '../accountMgmt/components/CreditCardName'
import { AccountMgmtStrings } from '../localization/en'

const paymentMethodDisplay = ({
  billingDetails,
  strings,
}: {
  billingDetails: BillingDetails
  strings: AccountMgmtStrings
}): string => {
  if (isCCDetails(billingDetails)) {
    const formattedCardType = formatCardType(billingDetails.cardType)

    // This handles a special case for Tesco in which IGlobis reutrns CC payment
    // details of '' and we need to show the CC payment details form instead of
    // defaulting to the DD form.
    if (billingDetails.cardNum === '') {
      return 'No payment method on file'
    }

    return `${formattedCardType || ''} **** **** **** ${billingDetails.cardNum}`
  } else {
    if (!billingDetails.acctNum) {
      return strings.misc.noPaymentMethod
    }

    return `${strings.misc.bankAccountEndingIn} ${billingDetails.acctNum}`
  }
}

export default paymentMethodDisplay
