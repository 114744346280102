import React from 'react'
import { Box } from 'rebass'
import { CircularProgress } from '@material-ui/core'
import { attBlue } from '../../att/marketing/theme'

const LoadingOverlay: React.FC<{ isAbsolute?: boolean }> = ({ isAbsolute }) => (
  <>
    <Box
      sx={{
        position: isAbsolute ? 'absolute' : 'fixed',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        bg: 'rgba(0, 0, 0, 0.3)',
        zIndex: '9998',
      }}
    />

    <Box
      sx={{
        position: isAbsolute ? 'absolute' : 'fixed',
        left: '50%',
        top: '45%',
        zIndex: '9999',
      }}
    >
      <CircularProgress style={{ color: attBlue }} />
    </Box>
  </>
)

export default LoadingOverlay
