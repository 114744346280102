import React from 'react'
import AccountApp from '../../generic/accountMgmt/App'
import config from './config'
import ApiClient from '../../generic/ApiClient'

const TescoAccountMgmtApp: React.FC<{
  apiClient?: ApiClient
  onSignIn?: (isSignedIn: boolean) => void
}> = ({ apiClient, onSignIn }) => {
  return (
    <AccountApp
      config={config}
      providedApiClient={apiClient}
      onSignIn={onSignIn}
    />
  )
}

export default TescoAccountMgmtApp
