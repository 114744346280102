import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IInfoProps {
  title: string
  updateText?: string
  onUpdateClick?: () => void
  columnStyling?: boolean
  children?: React.ReactNode | undefined
}
const Info: React.FC<IInfoProps> = ({
  title,
  updateText,
  onUpdateClick,
  columnStyling,
  children,
}) => {
  const classes = useStyles()

  const columnVersion = (
    <>
      <Box fontWeight={500} component="h3" color="black" m={0}>
        {title}
      </Box>

      {children && (
        <Box data-cy={title.toLowerCase() + '-children'} mt={1}>
          {children}
        </Box>
      )}

      {updateText && onUpdateClick && (
        <Box
          data-cy={title.toLowerCase()}
          className={classes.link}
          onClick={onUpdateClick}
          mt={1}
        >
          {updateText}
        </Box>
      )}
    </>
  )

  const rowVersion = (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box fontWeight={500} component="h3" color="black" m={0}>
          {title}
        </Box>

        {updateText && onUpdateClick && (
          <Box
            data-cy={title.toLowerCase()}
            className={classes.link}
            onClick={onUpdateClick}
          >
            {updateText}
          </Box>
        )}
      </Box>

      {children && (
        <Box data-cy={title.toLowerCase() + '-children'} mt={1}>
          {children}
        </Box>
      )}
    </>
  )

  return columnStyling ? columnVersion : rowVersion
}

export default Info
