import {
  PaymentClient,
  Env,
  CreditCardInfo,
  BillingContactInfo,
  SessionData,
} from '@backoffice/fast-payments-client-js-sdk'
import { omitBy as _omitBy, isEmpty as _isEmpty } from 'lodash'
import {
  IBeginEnrollmentRequest,
  isError,
  IGetAppointmentsRequest,
  IReserveAppointmentRequest,
  ISendSmartPinRequest,
  IDevice,
  ISecurityToken,
  IBillingAddress,
  IPlan,
  IReferral,
  IContextFromEnrollmentStateMgmt,
  PortalErrId,
  IHandleErrorArgs,
  IEnrollmentConfig,
  IDeviceEligibilityRequest,
  TerminalErrMsgId,
  Channel,
} from '../../interfaces'
import { EventType } from '../../interfaces/events'
import { SignedPhoneCondition } from '../components/PhoneCondition'
import detectCardType from '../../sharedHelpers/detectCardType'
import { PaymentInfoState } from '../components/PaymentInformation'

interface IContext extends IContextFromEnrollmentStateMgmt {
  config: IEnrollmentConfig
}

let paymentClient: PaymentClient

export const updateReferralParameters = (
  context: IContextFromEnrollmentStateMgmt,
  referral: IReferral
): void => {
  context.updateStore({ referral })
}

export const submitSignIn = (context: IContext): void => {
  const { isTextReminderFlow } = context.store
  isTextReminderFlow
    ? context.history.push(`${context.config.ENROLL_PATH}/text-reminder`)
    : context.history.push(`${context.config.ENROLL_PATH}/select-plan`)
}

export const saveAccountInformation = (
  context: IContext,
  firstName: string,
  lastName: string,
  email: string
): void => {
  const { billingFirstName, billingLastName } = context.store
  context.updateStore({
    firstName,
    lastName,
    email,
    billingFirstName: billingFirstName || firstName,
    billingLastName: billingLastName || lastName,
  })
}

export const textReminderRedirectToSignIn = (context: IContext): void => {
  context.updateStore({ isTextReminderFlow: true })
  context.history.push(`${context.config.ENROLL_PATH}/sign-in`)
}

export const submitTextReminder = (context: IContext): void => {
  context.history.push(`${context.config.ENROLL_PATH}/text-reminder-thanks`)
}

export const updateMobileNumber = (
  context: IContext,
  mobileNumber: string
): void => {
  context.updateStore({ mobileNumber })
}

export const updateReCaptchaToken = (
  context: IContext,
  reCaptchaToken: string
): void => {
  context.updateStore({ reCaptchaToken })
}

export const sendSmartPin = (context: IContext, req: ISendSmartPinRequest) => {
  return context.apiClient.sendSmartPin(req)
}

export const submitCustomerVerification = async (
  context: IContext,
  mdn: string,
  recaptcha: string
): Promise<void> => {
  try {
    setLoading(context, true)

    const response = await sendSmartPin(context, {
      phoneNumber: mdn,
      countryCallingCode: context.config.COUNTRY_CALLING_CODE,
      sendVia: 'sms',
      recaptcha,
    })
    if (response && response.sent) {
      context.updateStore({ mobileNumber: mdn, reCaptchaToken: recaptcha })
      context.history.push(`${context.config.ENROLL_PATH}/smart-pin`)
    } else {
      handleError(context, { portalErrId: PortalErrId.SMART_PIN_2 })
    }
  } catch (error) {
    console.error(error)
    handleError(context, { portalErrId: PortalErrId.SMART_PIN_2 })
  } finally {
    setLoading(context, false)
  }
}

export const notACustomer = (context: IContext): void => {
  context.history.push(`${context.config.ENROLL_PATH}/select-plan`)
}

export const updateAsset = (
  context: IContext,
  {
    userDevice,
    securityToken,
  }: { userDevice: IDevice; securityToken: ISecurityToken }
): void => {
  context.updateStore({
    securityToken,
    chosenDevice: userDevice,
    userAsset: userDevice,
  })
}

export const submitDeviceSelection = (context: IContext): void => {
  const device = context.store.chosenDevice
  const BYOD_ID = context.config.BYOD_ID

  if (device === null) return

  const moveForward = () => {
    context.config.SHOW_DEVICE_HEALTH
      ? context.history.push(`${context.config.ENROLL_PATH}/phone-condition`)
      : context.history.push(
          `${context.config.ENROLL_PATH}/payment-information`
        )
  }

  if (device.id === BYOD_ID) {
    context.updateStore({
      chosenDevice: device,
      // If the device was changed, we should unset the deductibleLookupResponse,
      // in case we have one at this point.
      deductibleLookupResponse: undefined,
    })
    moveForward()

    return
  }

  setLoading(context, true)

  context.apiClient
    .getAssetAttributes({ assetCatalogId: device.id })
    .then(({ imagePath }) => {
      const deviceWithImage = { ...device, imagePath: imagePath || undefined }

      context.updateStore({ chosenDevice: deviceWithImage })
    })
    .catch(() => {
      context.updateStore({ chosenDevice: device })
    })
    .finally(() => {
      setLoading(context, false)
      moveForward()
    })
}

export const updateChosenDevice = (
  context: IContext,
  device: IDevice
): void => {
  context.updateStore({ chosenDevice: device })
}

export const updateBYODevice = (context: IContext, BYODevice: string): void => {
  context.updateStore({ BYODevice })
}

export const saveIsFraudCheckError = (
  context: IContext,
  isFRMError: boolean
): void => {
  context.updateStore({ isFRMError })
}

export const submitPaymentInformation = async (
  context: IContext,
  {
    nameOnCard,
    firstName,
    lastName,
    cardNumber,
    secCode,
    expDate,
    email,
    address: { address1, address2, city, state, zip },
  }: PaymentInfoState
): Promise<void> => {
  const { COUNTRY_CALLING_CODE: countryCallingCode, BYOD_ID } = context.config
  const { securityToken, mobileNumber, channel, chosenDevice } = context.store
  const assetCatalogId = context.store.userAsset?.id

  // I should have all of this by now
  if (!securityToken || !assetCatalogId || !chosenDevice) {
    return
  }

  if (!paymentClient) {
    const env =
      process.env.NODE_ENV === 'production' ? Env['prod-us'] : Env['qa-us']
    paymentClient = new PaymentClient({ env })
  }

  const isByod = chosenDevice.id === BYOD_ID

  const [billingFirstName, ...billingLastNames] = nameOnCard.split(' ')
  const billingLastName = billingLastNames.join(' ')
  const [ccExpMonth, ccExpYear] = expDate.split('/')
  const creditCardInfo: CreditCardInfo = {
    expiration: {
      year: ccExpYear,
      month: ccExpMonth,
    },
    number: cardNumber,
    securityCode: secCode,
  }
  const billingContactInfo: BillingContactInfo = {
    name: {
      first: billingFirstName,
      last: billingLastName,
    },
    address: {
      address1,
      address2,
      city,
      state,
      country: 'US',
      zip,
    },
    contactInfo: {
      email,
      phone: mobileNumber,
    },
  }

  const fraudCheckData = {
    billingAddress: {
      addressLine1: address1,
      addressLine2: address2,
      city,
      countryCode: 'US',
      postalCode: zip,
      state,
      addressUsage: 'BLNG',
    },
    countryCallingCode,
    phoneNumber: mobileNumber,
  }

  try {
    removeBannerError(context)
    setLoading(context, true)

    const sessionData: SessionData = {
      appName: 'ENRPORTAL',
      encryptionKey: securityToken.encryptionKey,
      currency: 'USD',
    }

    paymentClient.addSession(securityToken.token, sessionData)

    await paymentClient.addCreditCardInfo(creditCardInfo)

    await paymentClient.addBillingContactInfo({
      ...billingContactInfo,
      address: {
        ..._omitBy(billingContactInfo.address, _isEmpty),
        zip,
        country: 'US',
      },
    })

    await paymentClient.processPayment()

    // Run fraud check
    const fraudCheckRes = await context.apiClient.runFraudCheck(fraudCheckData)
    if (fraudCheckRes && fraudCheckRes.error) {
      // We have to hold onto this error and display it after final submit on
      // Review page
      saveIsFraudCheckError(context, true)
    }

    // Save account information to app state
    saveAccountInformation(context, firstName, lastName, email)

    // Save payment information to app state
    savePaymentInformation(context, {
      billingAddress: {
        addressLine1: address1,
        addressLine2: address2,
        city,
        state,
        postalCode: zip,
        countryCode: countryCallingCode,
        addressUsage: 'BLNG',
      },
      billingFirstName: billingFirstName,
      billingLastName: billingLastName,
      creditCardLast4: cardNumber.slice(-4),
      creditCardType: detectCardType(cardNumber),
    })

    // Get deductibles for chosen device, unless it is BYOD
    if (!isByod) {
      const deductibleLookupResponse = await context.apiClient.deductibleLookup(
        {
          assetCatalogId,
          postalCode: zip,
        }
      )

      // Save deductibles to app state
      context.updateStore({ deductibleLookupResponse })
    }

    // Move forward
    const showPlanSelection = state === 'NY' && channel === Channel.Agent
    context.history.push(
      showPlanSelection
        ? `${context.config.ENROLL_PATH}/plan-selection`
        : `${context.config.ENROLL_PATH}/review`
    )
  } catch (error) {
    console.error(error)
    handleError(context, { portalErrId: PortalErrId.CC_1 })
  } finally {
    setLoading(context, false)
  }
}

export const savePaymentInformation = (
  context: IContext,
  paymentInformation: {
    billingAddress: IBillingAddress
    billingFirstName: string
    billingLastName: string
    creditCardLast4: string
    creditCardType: string
  }
) => {
  context.updateStore({ ...paymentInformation })
}

export const submitPlanSelection = (context: IContext): void => {
  context.history.push(`${context.config.ENROLL_PATH}/customer-verification`)
}

export const updateSelectedPlan = (
  context: IContext,
  selectedPlan: IPlan | null
): void => {
  context.updateStore({ selectedPlan })
}

export const submitReview = (context: IContext): void => {
  context.history.push(`${context.config.ENROLL_PATH}/success`)
}

export const removeBannerError = (context: IContext): void => {
  context.updateStore({ bannerMsgId: null })
}

export const setLoading = (context: IContext, loading: boolean): void => {
  context.updateStore({ loading })
}

export const handleError = (
  context: IContext,
  { portalErrId }: IHandleErrorArgs
) => {
  const { totalTries } = context.store

  const {
    triesAllowed,
    defaultErrMsg,
    finalErrMsg,
  } = context.config.PORTAL_ERROR_LOOKUP[portalErrId]

  const canRetry = totalTries < triesAllowed
  const errMsgIdToUse = canRetry ? defaultErrMsg : finalErrMsg
  const errIdType = errMsgIdToUse.includes('BANNER')
    ? 'bannerMsgId'
    : 'terminalErrMsgId'

  context.updateStore({
    portalErrId,
    [errIdType]: errMsgIdToUse,
    totalTries: totalTries + 1,
  })
}

export const submitPin = async (context: IContext, pin: string) => {
  removeBannerError(context)

  const request: IBeginEnrollmentRequest = {
    pin,
    countryCallingCode: context.config.COUNTRY_CALLING_CODE,
  }

  setLoading(context, true)

  let beginEnrollResponse

  try {
    beginEnrollResponse = await context.apiClient.beginEnrollment(request)
  } catch (error) {
    console.error(error)
    return handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
  } finally {
    setLoading(context, false)
  }

  if (isError(beginEnrollResponse)) {
    // enrollment errors
    const enrolledSKU = beginEnrollResponse.error.details?.enrolledSKU

    switch (beginEnrollResponse.error.code) {
      case 'AEP-300':
        // Not an AT&T customer
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_2,
          agentPortalErrId: PortalErrId.AGENT_1_A,
        })
        notACustomer(context)
        break
      case 'AEP-400':
        // This phone number has an active protection agreement.
        context.updateStore({
          enrolledSKU,
        })
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_3,
          agentPortalErrId: PortalErrId.AGENT_2,
        })
        break
      case 'AEP-401':
        // This phone number has a suspended protection agreement.
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_1,
          agentPortalErrId: PortalErrId.AGENT_3,
        })
        break
      case 'AEP-402':
        // No agreement data, unable to verify eligibility.
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_1,
          agentPortalErrId: PortalErrId.AGENT_1_B,
        })
        break
      case 'AEP-406':
        // Not an AT&T Prepaid customer
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_1,
          agentPortalErrId: PortalErrId.AGENT_1_D,
        })
        break
      case 'AEP-414':
        handleError(context, { portalErrId: PortalErrId.SMART_PIN_3 })
        break
      case 'AEP-415':
        handleError(context, { portalErrId: PortalErrId.SMART_PIN_4 })
        break
      default:
        handleError(context, { portalErrId: PortalErrId.SMART_PIN_2 })
    }
    return
  } else {
    // Happy path: We have an asset without an agreement that we can use
    if (beginEnrollResponse.asset) {
      updateAsset(context, {
        securityToken: beginEnrollResponse.securityToken,
        userDevice: beginEnrollResponse.asset,
      })
    } else {
      return handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
    }
  }

  context.history.push(`${context.config.ENROLL_PATH}/confirm-phone`)
}

export const agentSignIn = (context: IContext): void => {
  context.updateStore({
    referral: { dealerCode: '', repIdOrUID: '', repName: '' },
  })
  context.history.push('/sign-in')
}

export const initialize = async (context: IContext) => {
  try {
    context.updateStore({ loading: true })

    if (!context.apiClient.isInitialized()) {
      await context.apiClient.initialize(context.config.PARTNER)
    }

    const [listOfPlans, deviceList] = await Promise.all([
      context.apiClient.getOffers(),
      context.apiClient.getDeviceList(),
    ])

    const selectedPlan =
      listOfPlans.find(
        (p: IPlan) => p.sku === context.config.DEFAULT_PLAN_SKU
      ) || null

    context.updateStore({
      deviceList,
      listOfPlans,
      selectedPlan,
    })
  } finally {
    context.updateStore({ loading: false })
  }
}

export const confirmPhone = (context: IContext, isConfirmed: boolean): void => {
  if (!isConfirmed) {
    context.history.push(`${context.config.ENROLL_PATH}/device-selection`)
    return
  }

  checkDeviceEligibility(context, { override: false }).then(({ eligible }) => {
    if (eligible) {
      context.config.SHOW_DEVICE_HEALTH
        ? context.history.push(`${context.config.ENROLL_PATH}/phone-condition`)
        : context.history.push(
            `${context.config.ENROLL_PATH}/payment-information`
          )
    }
  })
}

export const signPhoneCondition = (
  context: IContext,
  { sigData, signedName, signedDate }: SignedPhoneCondition
) => {
  context.updateStore({ sigData, signedName, signedDate })
  context.history.push(`${context.config.ENROLL_PATH}/payment-information`)
}

export const checkDeviceEligibility = async (
  context: IContext,
  { override }: { override: boolean }
) => {
  const chosenDevice = context.store.chosenDevice

  if (chosenDevice === null) {
    return { eligible: false }
  }

  try {
    setLoading(context, true)

    const request: IDeviceEligibilityRequest = {
      assetCatalogId: chosenDevice.id,
      override,
    }

    const deviceEligibility = await context.apiClient.getEligibility(request)

    if (deviceEligibility.error) {
      if (deviceEligibility.error.code === 'AEP-403') {
        // Customer account is older than 30 days.
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_5,
          agentPortalErrId: PortalErrId.AGENT_1_C,
          opts: {
            override: checkDeviceEligibility.bind(null, context, {
              override: true,
              chosenDevice,
            }),
            superUserErrId: TerminalErrMsgId.SUPER_1,
          },
        })
      } else if (deviceEligibility.error.code === 'AEP-404') {
        // The selected device is ineligible
        handleError(context, {
          portalErrId: PortalErrId.ELIGIBILITY_4,
          agentPortalErrId: PortalErrId.AGENT_4,
        })
      } else {
        handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
      }

      return { eligible: false }
    } else if (!deviceEligibility.eligible) {
      // The selected device is ineligible
      handleError(context, {
        portalErrId: PortalErrId.ELIGIBILITY_4,
        agentPortalErrId: PortalErrId.AGENT_4,
      })

      return { eligible: false }
    }

    return { eligible: true }
  } catch (error) {
    console.error(error)
    handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
    return { eligible: false }
  } finally {
    setLoading(context, false)
  }
}

// ONBOARDING ACTIONS
export const beginOnboarding = (context: IContext): void => {
  context.history.push(`${context.config.ENROLL_PATH}/onboarding`)
}

export const clearAppointments = (context: IContext) => {
  context.updateStore({ onboardingAppointments: null })
  return
}

export const getAppointments = (
  context: IContext,
  params?: IGetAppointmentsRequest
) => {
  removeBannerError(context)

  if (params == null) {
    return
  }

  clearAppointments(context)
  setLoading(context, true)

  const req = {
    ...params,
  }
  context.apiClient
    .getAppointments(req)
    .then((onboardingAppointments) => {
      if (!isError(onboardingAppointments)) {
        context.updateStore({ onboardingAppointments })
      } else {
        // Throw up a generic error
        handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
      }

      return onboardingAppointments
    })
    .catch(() => {
      // Throw up a generic error
      handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
    })
    .finally(() => {
      setLoading(context, false)
    })
}

export const reserveAppointment = (
  context: IContext,
  params: IReserveAppointmentRequest
) => {
  return context.apiClient
    .reserveAppointment(params)
    .then((res) => {
      if (!isError(res)) {
        context.history.push(`${context.config.ENROLL_PATH}/onboarding/end`)
      } else {
        // Show a banner error to allow a retry
        handleError(context, { portalErrId: PortalErrId.ONBOARDING_1 })
      }

      return res
    })
    .catch((err) => {
      // Throw up a generic error
      handleError(context, { portalErrId: PortalErrId.ELIGIBILITY_1 })
      throw err
    })
    .finally(() => {
      setLoading(context, false)
    })
}
export async function trackEvent(
  context: IContext,
  eventType: EventType,
  props?: object
): Promise<void> {
  return await context.apiClient.trackEvent(eventType, props)
}
