import './index.css'
import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../sharedComponents/utilities/ScrollToTop'
import theme from './theme'
import { Box, Flex } from 'rebass'
import { ConfigProvider, useConfig } from './state/tescoMarketingConfigSetup'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import TescoLanding from './components/TescoLanding'
import config from './config'
import TescoHeader from './components/organisms/TescoHeader'
import TescoFooter from './components/sections/TescoFooter'
import TescoAccountMgmtApp from '../accountMgmt/AccountMgmtApp'
import { ApiProvider, useApi } from './state/tescoMarketingStateMgmt'
import ApiClient from '../../generic/ApiClient'
import { sourceToChannel } from '../../generic/enrollment/state/enrollmentStateInit'
import loadParams from '../../generic/sharedHelpers/loadParams'
import ErrorBanner from '../../sharedComponents/organisms/ErrorBanner'
import { EventType, isError } from '../../generic/interfaces'

const params = loadParams()

const TescoAppInner: React.FC = () => {
  const apiClient = useApi()
  const { SPEEDCLAIMS_URL } = useConfig()
  const [hasError, setHasError] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)

  useEffect(() => {
    const initialize = async (apiClient: ApiClient) => {
      try {
        await apiClient.initialize(config.PARTNER)
        apiClient.trackEvent(EventType.InitialPageLoad, {
          flow: 'Marketing',
        })
      } catch (err) {
        setHasError(true)
      }
    }

    initialize(apiClient)
  }, [apiClient])

  const handleSignIn = (isSignedIn: boolean) => {
    setIsSignedIn(isSignedIn)
  }

  const redirectingHtml = `
    <html lang="en" title="Redirecting...">
      <head>
        <title>Redirecting</title>
      </head>
      <body>
        Please wait while we redirect you...
      </body>
    </html>
    `
  const handleStartAClaim = async () => {
    apiClient.trackEvent(EventType.ClaimLinkClicked, {
      to: SPEEDCLAIMS_URL,
      from: 'Tesco Homepage',
      flow: 'AcctMgmt',
    })
    // We have to open a window first to avoid async issues and popup blockers
    const w = window.open('', '_blank')
    w?.document.write(redirectingHtml)
    w?.document.close()

    if (!apiClient.hasToken()) {
      w?.location.replace(SPEEDCLAIMS_URL)
      return
    }

    try {
      const createSmartLinkRes = await apiClient.createSmartLink()

      if (isError(createSmartLinkRes)) {
        w?.location.replace(SPEEDCLAIMS_URL)
      } else {
        w?.location.replace(createSmartLinkRes.url)
      }
    } catch {
      w?.location.replace(SPEEDCLAIMS_URL)
    }
  }

  return (
    <>
      <ScrollToTop />

      <Flex
        flexDirection="column"
        sx={{
          height: '100vh',
          fontFamily: 'body',
          lineHeight: 'body',
          WebkitFontSmoothing: 'antialiased',
        }}
      >
        <Box as="header" sx={{ flex: '0 0 auto' }}>
          <TescoHeader
            isSignedIn={isSignedIn}
            onStartAClaim={handleStartAClaim}
          />
        </Box>

        {hasError && <ErrorBanner>Initialization error</ErrorBanner>}

        <Box as="main" role="main" sx={{ flex: '1 1 auto' }}>
          <Switch>
            <Route path={`/`} exact={true}>
              <TescoLanding onStartAClaim={handleStartAClaim} />
            </Route>

            <Route path="/account">
              <TescoAccountMgmtApp
                apiClient={apiClient}
                onSignIn={handleSignIn}
              />
            </Route>

            <Redirect to="/" />
          </Switch>
        </Box>

        <Box as="footer" sx={{ flex: '0 0 auto' }}>
          <TescoFooter isSignedIn={isSignedIn} />
        </Box>
      </Flex>
    </>
  )
}

const App = () => {
  const [apiClient] = useState<ApiClient>(
    () =>
      new ApiClient({
        apiBaseUrl: config.API_BASE_URL,
        channel: sourceToChannel((params.source || 'WEB').toUpperCase()),
        isTrackingEnabled: false, //config.MIXPANEL_ENABLED,
        token: sessionStorage.getItem('token') || undefined,
      })
  )

  return (
    <ConfigProvider config={config}>
      <ThemeProvider theme={theme}>
        <ApiProvider value={apiClient}>
          <TescoAppInner />
        </ApiProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}
export default App
