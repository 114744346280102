import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  makeStyles,
} from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    fontSize: 16,
    fontWeight: 'bold',
    height: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: '100%',
    textTransform: 'inherit',
    width: 300,
  },
  adaTextDisabled: {
    color: 'rgb(90,90,90)',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const SubmitButton = (props: {
  text: string
  disabled?: boolean
  href?: string
  loading?: boolean
  onClick?: () => void
  id?: string
  errorColorOverride?: boolean
}) => {
  const { loading } = props
  const classes = useStyles()
  return (
    <Grid container={true}>
      <Button
        data-cy="submit-button"
        variant="contained"
        type="submit"
        className={classes.submit}
        color={'primary'}
        disabled={props.disabled || loading}
        onClick={props.onClick}
        href={props.href}
        id={props.id}
        // This hack was necessary. MaterialUI will only allow "primary" and
        // "secondary" button colors, and sadly not an "error" variant.
        style={
          props.errorColorOverride
            ? {
                backgroundColor: '#B00020',
                color: 'white',
              }
            : {}
        }
      >
        <div className={props.disabled ? classes.adaTextDisabled : undefined}>
          {props.text}
        </div>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </Grid>
  )
}

export default SubmitButton
