import SubmitButton from '../../sharedComponents/SubmitButton'
import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import moment from 'moment'
import { useActions, useStore } from '../state/accountStateMgmt'
import { useLocale } from '../../localization'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IProps {
  currentDay: number
  onClose: () => void
}

const BillingDateModalContent: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const [newDay, setNewDay] = useState(props.currentDay)
  const isFormValid = !!newDay
  const { loading } = useStore()
  const { updateBillingDate } = useActions()
  const { strings } = useLocale()

  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setNewDay(e.target.value as number)
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const dayStr = moment().date(newDay).format('YYYY-MM-DD')

    updateBillingDate(dayStr).then(() => {
      props.onClose()
    })
  }

  const dayMenuItems = new Array(30).fill(null).map((_, i) => {
    const day = i + 1

    return (
      <MenuItem value={day} key={day}>
        {day}
      </MenuItem>
    )
  })

  // LOCALE
  return (
    <>
      <Box mb={1}>
        <Typography
          data-cy="modal-title"
          variant={'h6'}
          color={'textSecondary'}
        >
          {strings.billingDateModalContent.changeBillingDate}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" py={3}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.billingDateModalContent.currentBillingDay}
          </Typography>
          <Typography variant={'subtitle1'}>
            {strings.billingDateModalContent.everyDayOfTheMonth(
              moment(props.currentDay, 'Do').format('Do')
            )}
          </Typography>

          <Box mt={3} mb={3} width="100%" flexShrink={0} height={58}>
            <FormControl
              variant="outlined"
              fullWidth={true}
              style={{ height: '100%' }}
            >
              <InputLabel htmlFor="billing-day-select">
                {strings.billingDateModalContent.newBillingDay}
              </InputLabel>
              <Select
                style={{ height: '100%' }}
                value={newDay}
                autoWidth={true}
                onChange={handleChange}
                id="billing-day-select"
                input={
                  <OutlinedInput
                    id="billing-day-input"
                    label={strings.billingDateModalContent.newBillingDay}
                    name="day"
                  />
                }
              >
                {dayMenuItems}
              </Select>
            </FormControl>
          </Box>

          <SubmitButton
            id={'billing-day-submit'}
            text={strings.misc.saveChanges}
            disabled={!isFormValid}
            loading={loading}
          />
        </Box>
      </form>
      <Box
        className={classes.link}
        onClick={props.onClose}
        textAlign="center"
        fontWeight={500}
      >
        {strings.misc.cancel}
      </Box>
    </>
  )
}

export default BillingDateModalContent
