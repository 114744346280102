import React from 'react'
import { useConfig } from '../state/enrollmentConfigSetup'
import { Box } from 'rebass'
import { Link } from 'react-router-dom'
import { useLocale } from '../../localization'
import { Partner } from '../../interfaces'
import { useStore } from '../state/enrollmentStateMgmt'

export const AlreadyCovered = () => {
  const { strings } = useLocale()
  const { ACCOUNT_PATH, PARTNER } = useConfig()
  const { enrolledSKU } = useStore()

  const getAlreadyEnrolledText = () => {
    if (PARTNER === Partner.ATT) {
      return enrolledSKU === 'PDPE'
        ? strings.alreadyCovered.alreadyCoveredMobileProtection
        : strings.alreadyCovered.alreadyCoveredMobileInsurance
    }

    return strings.alreadyCovered.goodNews
  }

  return (
    <Box p={3} data-cy={'body'}>
      {getAlreadyEnrolledText()}{' '}
      <Link to={ACCOUNT_PATH}>{strings.alreadyCovered.viewYourAccount}</Link>
    </Box>
  )
}
