import { LinkWithTracking } from '../../sharedComponents/LinkWithTracking'
import React from 'react'
import { IActions } from '../../interfaces/account'
import {
  EventType,
  LinkColorType,
  LinkOpenOptions,
  LinkUnderlineType,
  LinkVariantType,
} from '../../interfaces'
import { connect } from '../state/accountStateMgmt'

interface IProps {
  handler?: () => void
  color?: LinkColorType
  eventType?: EventType
  linkText: string
  style?: React.CSSProperties
  id?: string
  linkTarget: string
  className?: string
  underline?: LinkUnderlineType
  external?: LinkOpenOptions
  variant?: LinkVariantType
}
interface IMappedDispatch {
  trackEvent: (eventType: EventType, props?: object) => Promise<any>
}
const mapDispatchToProps = (actions: IActions): IMappedDispatch => {
  return {
    trackEvent: actions.trackEvent,
  }
}
const AccountLinkWithTracking: React.FC<IProps & IMappedDispatch> = (props) => {
  return <LinkWithTracking {...props} />
}
export default connect(() => ({}), mapDispatchToProps)(AccountLinkWithTracking)
