import React from 'react'
import { Box } from 'rebass'
import { useConfig } from '../../state/tescoMarketingConfigSetup'

type ContainerProps = {
  children: React.ReactNode //👈 children prop typr
}

const TescoConstrainer = (props: ContainerProps) => {
  const { APP_MAX_WIDTH, APP_MIN_WIDTH } = useConfig()

  return (
    <Box
      width={APP_MAX_WIDTH}
      maxWidth="100%"
      minWidth={APP_MIN_WIDTH}
      mx="auto"
      sx={{ position: 'relative' }}
    >
      {props.children}
    </Box>
  )
}

export default TescoConstrainer
