import React from 'react'
import { Box, Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useWidth from '../../sharedHelpers/useWidth'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  backdrop: {
    backgroundColor: 'rgba(256, 256, 256, 0.9)',
  },
})

interface IAccountModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  children?: React.ReactNode | undefined
}
const AccountModal: React.FC<IAccountModalProps> = (props) => {
  const width = useWidth()
  const classes = useStyles()

  return (
    <Dialog
      open={props.isModalOpen}
      fullWidth={true}
      scroll="body"
      fullScreen={width === 'xs'}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Box p={3}>
        <Box position="absolute" top={5} right={5}>
          <IconButton
            data-cy="modal-close"
            aria-label="Close"
            onClick={() => {
              props.onModalClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {props.children}
      </Box>
    </Dialog>
  )
}

export default AccountModal
