import { Link } from '@material-ui/core'
import React from 'react'
import useReactRouter from 'use-react-router'
import {
  parseMatchForFlow,
  parseLocationForFriendlyPageName,
} from './TrackRouteChanges'
import { EventType, ILinkProps } from '../interfaces'
import { LinkOpenOptions } from '../interfaces/tracking'

export const LinkWithTracking: React.FC<ILinkProps> = (props) => {
  const { location, history } = useReactRouter()

  function trackLinkClick(
    eventType: EventType,
    to: string,
    handler?: () => void
  ) {
    const from = parseLocationForFriendlyPageName(location.pathname)
    const friendlyTo = parseLocationForFriendlyPageName(to)
    const flow = parseMatchForFlow(location.pathname)

    return (e: React.MouseEvent) => {
      if (!props.external || props.external !== LinkOpenOptions.Scroll) {
        e.preventDefault()
      }

      props.trackEvent(eventType, { from, to: friendlyTo, flow })

      if (handler) {
        return handler()
      } else if (props.external) {
        if (props.external === LinkOpenOptions.Scroll) {
          return history.push(to)
        }

        return window.open(to, props.external)
      } else {
        return history.push(to)
      }
    }
  }

  return (
    <Link
      data-cy={props['data-cy']}
      component={props.component || 'a'}
      id={props.id}
      style={props.style}
      href={props.linkTarget}
      className={props.className}
      color={props.color}
      variant={props.variant}
      underline={props.underline}
      onClick={trackLinkClick(
        props.eventType || EventType.LinkClicked,
        props.linkTarget,
        props.handler
      )}
      rel={props.rel}
      width={props.width}
    >
      {props.linkText}
    </Link>
  )
}
