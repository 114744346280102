import './polyfills'
import React from 'react'
// import ReactDOM from 'react-dom'
import TescoApp from './tesco/marketing/App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container)

const App = () => (
  <BrowserRouter>
    <TescoApp />
  </BrowserRouter>
)

root.render(<App />)

// ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
