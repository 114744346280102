import React from 'react'
import { partnerNameToLinkMap } from '../../sharedHelpers/partnerLinkMap'
import { Partner } from '../../interfaces'
import { Box, Link } from 'rebass'
import { useLocale } from '../../localization'

export const PastThirtyDays: React.FC<{ partnerName: Partner }> = ({
  partnerName,
}) => {
  const { strings } = useLocale()
  const linkTarget = partnerNameToLinkMap[partnerName]
  const pastThirtyStrings = strings.pastThirtyDays.youCanOnlyEnroll(partnerName)

  return (
    <Box as="p" data-cy={'body'}>
      {pastThirtyStrings[0]}
      <Link href={linkTarget} target="_blank">
        {pastThirtyStrings[1]}
      </Link>
      {pastThirtyStrings[2]}
    </Box>
  )
}
