import React from 'react'
import { Box, Heading, Link } from 'rebass'
import { tescoBlue } from '../../theme'
import TescoConstrainer from '../utilities/TescoConstrainer'
import Button from '../../../../sharedComponents/atoms/Button'
import { useConfig } from '../../state/tescoMarketingConfigSetup'
import { EventType } from '../../../../generic/interfaces/events'
import { useHandleLinkClick } from '../utilities/useHandleLinkClick'

const ClaimDocuments: React.FC = () => {
  const { CLAIM_DOCS_URL } = useConfig()

  return (
    <Box color="white">
      <TescoConstrainer>
        <Box
          width={[1, 1, 0.6]}
          ml={[0, 0, 'auto']}
          pl={[3, 3, 6]}
          pr={3}
          color="deepestGray"
          sx={{ textAlign: ['center', 'center', 'left'] }}
        >
          <Box width={[1, 1, 290]} sx={{ maxWidth: '100%' }}>
            <Heading fontSize={[3, 4, 5]} mt={5}>
              Need to upload claim documents?
            </Heading>

            <Box mt={3}>
              If we've requested documentation for your claim, you can upload it
              here.
            </Box>
          </Box>

          <Link
            mt={4}
            mb={5}
            href={CLAIM_DOCS_URL}
            target="_blank"
            sx={{ display: 'block' }}
            onClick={useHandleLinkClick(
              EventType.UploadClaimDocumentsButtonClicked,
              CLAIM_DOCS_URL
            )}
          >
            <Button
              width={[1, 290]}
              height={48}
              fontSize={3}
              variant="secondary"
              bg={tescoBlue}
            >
              Upload claim documents
            </Button>
          </Link>
        </Box>
      </TescoConstrainer>
    </Box>
  )
}

export default ClaimDocuments
