import config from '../config'
import { EventType, IPostBankAccountParams, IPostCCParams } from './interfaces'
import ApiClient from './ApiClient'

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    validate_ccNo: any
    postPanDataToBG: any
  }
}

const APP_NAME = 'ENRPORTAL'

export interface PaymentApiClientConstructorParams {
  apiClient: ApiClient
  billingProgramId: string
}

export default class PaymentApiClient {
  public static validateCC({
    creditCardNumber,
    allowedCCTypes,
  }: {
    creditCardNumber: string
    allowedCCTypes: string
  }): { isValid: boolean } {
    return window.validate_ccNo(creditCardNumber, allowedCCTypes, 'US')
  }

  public apiClient: ApiClient
  public billingProgramId: string

  constructor({
    apiClient,
    billingProgramId,
  }: PaymentApiClientConstructorParams) {
    this.apiClient = apiClient
    this.billingProgramId = billingProgramId
  }

  public encryptAndPostBankAccount({
    billingContact,
    bank_acct_no,
    bank_routing_no,
    encryption_key,
    security_token,
  }: IPostBankAccountParams) {
    const { apiClient, billingProgramId } = this

    return new Promise((resolve, reject) => {
      const pan_type = 'DD'
      const appName = APP_NAME
      const url = config.BG_ENDPOINT

      if (bank_acct_no.toString().length > 19) {
        return reject('Bank account number exceeds max length.')
      }

      if (bank_routing_no.toString().length > 9) {
        return reject('Bank routing number exceeds max length.')
      }

      const panObject = {
        bank_acct_no,
        bank_routing_no,
        pan_type,
      }

      const basicParams = {
        appName,
        encryption_key,
        security_token,
        url,
        billingProgramId,
      }

      async function success(result: any, status: string) {
        if (status === 'success') {
          if (!result.includes('BG-0')) {
            await apiClient.trackEvent(EventType.FrontendError, {
              message: result,
              serviceName: 'Billing Gateway',
            })
            reject(`Error posting bank account data: ${result}`)
          }

          resolve(`Success posting bank account data: ${result}`)
        } else {
          await apiClient.trackEvent(EventType.FrontendError, {
            message: result,
            serviceName: 'Billing Gateway',
          })
          reject(`Error posting Bank Account data: ${result}`)
        }
      }

      async function failure(xhr: any, status: any, error: any) {
        await apiClient.trackEvent(EventType.FrontendError, {
          message: 'Failed to tokenize bank account',
          serviceName: 'Billing Gateway',
        })
        reject(`Failed posting bank account data: ${error}`)
      }

      const payload = [basicParams, panObject, billingContact, success, failure]
      const responseFromBG = window.postPanDataToBG(...payload)
      const { rtn_code, rtn_msg } = responseFromBG

      if (rtn_code !== 'BG-0') {
        throw new Error('Error posting pan data to BG: ' + rtn_msg)
      }
    })
  }

  public encryptAndPostCCNumber({
    billingContact,
    cc_no,
    cvv,
    cc_exp_month,
    cc_exp_year,
    encryption_key,
    security_token,
  }: IPostCCParams) {
    const { apiClient, billingProgramId } = this
    return new Promise((resolve, reject) => {
      const pan_type = 'CC'
      const saveCard = '1'
      const appName = APP_NAME
      const url = config.BG_ENDPOINT

      const panObject = {
        cc_exp_month,
        cc_exp_year,
        cc_no,
        cvv,
        pan_type,
        saveCard,
      }

      const basicParams = {
        appName,
        encryption_key,
        security_token,
        url,
        billingProgramId,
      }

      async function success(result: any, status: string) {
        if (status === 'success') {
          if (!result.includes('BG-0')) {
            await apiClient.trackEvent(EventType.FrontendError, {
              message: result,
              serviceName: 'Billing Gateway',
            })
            reject(`Error posting CC data: ${result}`)
          }

          resolve(`Success posting CC data: ${result}`)
        } else {
          await apiClient.trackEvent(EventType.FrontendError, {
            message: result,
            serviceName: 'Billing Gateway',
          })
          reject(`Error posting CC data: ${result}`)
        }
      }

      async function failure(xhr: any, status: any, error: any) {
        await apiClient.trackEvent(EventType.FrontendError, {
          message: 'Failed to tokenize credit card',
          serviceName: 'Billing Gateway',
        })
        reject(`Failed posting CC data: ${error}`)
      }

      const payload = [basicParams, panObject, billingContact, success, failure]

      const { rtn_code, rtn_msg } = window.postPanDataToBG(...payload)

      if (rtn_code !== 'BG-0') {
        throw new Error('Error posting pan data to BG: ' + rtn_msg)
      }
    })
  }
}

// Error codes returned from JS library are:
// BG-0 corresponding rtn_msg is AJAX Request has been Submitted successfully
// BG-1065 corresponding rtn_msg is AJAX Request hasn't been Submitted, Encryption Failed In UI JS library, please check 'key' value.
// BG-2222 corresponding rtn_msg is AJAX Request hasn't been Submitted, Invalid Inputs Caught in UI JS library, please check input '<input parameter name>' value.
// Error codes returned from BG service which does the tokenization will be subset of the super set of error codes present in BG API Spec document.
