// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const FIELDS = [
//   'source',
//   'username',
//   'repId',
//   'dealerCode',
//   'repName',
//   'referral',
//   'cid',
//   'enrolled',
//   'dc',
//   'rid',
//   'rn',
// ]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const b64decode = (val: string | null) => {
//   if (!val) {
//     return null
//   }
//   try {
//     return atob(val)
//   } catch (e) {
//     return null
//   }
// }

export default () => {
  const result: any = {}
  const urlParams = new URLSearchParams(window.location.search)
  const fieldParamsLowerCase: Record<string, string> = {}
  urlParams.forEach((value: string, key: string) => {
    fieldParamsLowerCase[key.toLowerCase()] = value
    result[key.toLowerCase()] = value
  })
  window.sessionStorage.setItem('t_p', JSON.stringify(fieldParamsLowerCase))

  let newPath = window.location.pathname

  const paramsAsString = urlParams.toString()
  if (paramsAsString) {
    newPath += `?${paramsAsString}${window.location.hash}`
  } else {
    newPath += window.location.hash
  }

  window.history.replaceState({}, '', newPath)

  return result
}
