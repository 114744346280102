const SHORT_NAME = 'short_name'
const LONG_NAME = 'long_name'
const STREET_NUMBER = 'street_number'
const STREET = 'route'
const CITY = 'locality'
const POSTAL_TOWN = 'postal_town' // City in Great Britain
const STATE = 'administrative_area_level_1'
const COUNTY = 'administrative_area_level_2'
const POSTAL_CODE = 'postal_code'
const POLITICAL = 'political'
const NEIGHBORHOOD = 'neighborhood'
// Puerto Rico, US Virgin Islands, Guam, American Samoa, Northern Mariana Islands
const US_NON_STATE_TERRITORIES = ['PR', 'VI', 'GU', 'AS', 'MP']

export default function (
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  let streetNumber = ''
  let street = ''
  let city = ''
  let state = ''
  let county = ''
  let zip = ''
  let neighborhood = ''

  addressComponents.forEach((part) => {
    if (part.types.includes(STREET_NUMBER)) {
      streetNumber = part[SHORT_NAME]
    }

    if (part.types.includes(STREET)) {
      street = part[SHORT_NAME]
    }

    if (part.types.includes(CITY) || part.types.includes(POSTAL_TOWN)) {
      city = part[LONG_NAME]
    }

    if (part.types.includes(STATE) && !state) {
      state = part[SHORT_NAME]
    }

    // These come in with the territory as the country, so stick it into the state field
    if (
      part.types.includes(POLITICAL) &&
      US_NON_STATE_TERRITORIES.includes(part[SHORT_NAME])
    ) {
      state = part[SHORT_NAME]
    }

    if (part.types.includes(COUNTY)) {
      county = part[SHORT_NAME]
    }

    if (part.types.includes(POSTAL_CODE)) {
      zip = part[SHORT_NAME]
    }

    if (part.types.includes(NEIGHBORHOOD)) {
      neighborhood = part[SHORT_NAME]
    }
  })

  const streetAddress = `${streetNumber} ${street}`

  return { streetAddress, neighborhood, city, state, zip, county }
}
