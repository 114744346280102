import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
  Link,
} from '@material-ui/core'
import React, { ChangeEvent, useState, useEffect } from 'react'
import { IBillingAddress } from '../../interfaces'
import AddressInput from './AddressInput'
import states from '../../sharedHelpers/states'
import InputMask from 'react-input-mask'
import extractAddressComponents from '../../sharedHelpers/extractAddressComponents'
import { useLocale } from '../../localization'
import { useConfig } from '../state/accountConfigSetup'
import { CHAR_LIMIT, REG_EXP } from '../common/constants'
import { TextField as AsurionTextField } from '@soluto-private/mx-asurion-ui-react'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

interface IPaymentModalFormProps {
  oldAddress: IBillingAddress
  loading: boolean
  onChange: (address: IBillingAddress) => void
  textFieldProps?: any | {}
  validateAddress: (isValid: boolean) => void
}
const AddressChangeForm: React.FC<IPaymentModalFormProps> = ({
  onChange,
  oldAddress,
  textFieldProps = {},
  validateAddress,
}) => {
  const { USE_BRITISH_ADDRESSING } = useConfig()
  const { strings } = useLocale()
  const classes = useStyles()

  const [newZipCode, setNewZipCode] = useState('')
  const [newAddress1, setNewAddress1] = useState('')
  const [newAddress2, setNewAddress2] = useState('')
  const [newCity, setNewCity] = useState('')
  const [newState, setNewState] = useState('')
  const [newCounty, setNewCounty] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const [address1Err, setAddress1Err] = useState(false)
  const [address2Err, setAddress2Err] = useState(false)
  const [address1ErrMsg, setAddress1ErrMsg] = useState('')
  const [address2ErrMsg, setAddress2ErrMsg] = useState('')
  const [cityErr, setCityErr] = useState(false)
  const [cityErrMsg, setCityErrMsg] = useState('')
  const [countyErr, setCountyErr] = useState(false)
  const [countyErrMsg, setCountErrMsg] = useState('')

  useEffect(() => {
    onChange({
      addressLine1: newAddress1,
      addressLine2: newAddress2,
      city: newCity,
      state: USE_BRITISH_ADDRESSING ? newCounty : newState,
      postalCode: newZipCode,
      countryCode: oldAddress.countryCode,
      addressUsage: oldAddress.addressUsage,
    })
  }, [
    onChange,
    newAddress1,
    newAddress2,
    newCity,
    newCounty,
    newState,
    newZipCode,
    USE_BRITISH_ADDRESSING,
    oldAddress,
  ])

  // If google is blocked and does not init, do not use AddressInput component and show manual entry instead.
  const [showManualAddressEntry, setShowManualAddressEntry] = useState(
    !window.google
  )

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewZipCode(e.target.value?.replace('Backspace', ''))
  }

  const handleAddress1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setNewAddress1((oldValue) => {
      if (newValue.length > CHAR_LIMIT.ADDRESS_1_LIMIT) {
        setAddress1Err(true)
        setAddress1ErrMsg(
          `Max ${CHAR_LIMIT.ADDRESS_1_LIMIT} characters are accepted`
        )
        setIsDirty(true)
        validateAddress(false)
      } else if (!REG_EXP.EMPTY_STRING.test(newValue)) {
        setAddress1Err(true)
        setAddress1ErrMsg(`Address line 1 required`)
        setIsDirty(true)
        validateAddress(false)
      } else {
        setAddress1Err(false)
        setAddress1ErrMsg('')
        validateAddress(true)
      }
      return newValue.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setNewAddress2((oldValue) => {
      let address
      if (newValue === '') {
        setAddress2Err(false)
        setAddress2ErrMsg('')
        validateAddress(true)
        address = newValue
      } else if (!REG_EXP.EMPTY_STRING.test(newValue)) {
        setAddress2Err(true)
        setAddress2ErrMsg('Only space is not allowed')
        setIsDirty(true)
        validateAddress(false)
        address = newValue
      } else if (newValue.length > CHAR_LIMIT.ADREESS_2_LIMIT) {
        setAddress2Err(true)
        setAddress2ErrMsg(
          `Max ${CHAR_LIMIT.ADREESS_2_LIMIT} characters accepted`
        )
        validateAddress(false)
        address = newValue
      } else {
        setAddress2Err(false)
        setAddress2ErrMsg('')
        validateAddress(true)
        address = newValue
      }
      return address.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCityValue = e.target.value
    setNewCity((oldCityValue) => {
      if (!REG_EXP.EMPTY_STRING.test(newCityValue)) {
        setCityErr(true)
        setCityErrMsg('City is required')
        setIsDirty(true)
        validateAddress(false)
      } else if (newCityValue.length > CHAR_LIMIT.CITY_LIMIT) {
        setCityErr(true)
        setCityErrMsg(`Max ${CHAR_LIMIT.CITY_LIMIT} characters are accepted`)
        setIsDirty(true)
        validateAddress(false)
      } else {
        setCityErr(false)
        setCityErrMsg('')
        validateAddress(true)
      }
      return newCityValue.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleCountyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setNewCounty((oldValue) => {
      if (!REG_EXP.EMPTY_STRING.test(newValue)) {
        setCountyErr(true)
        setCountErrMsg('County is required')
        setIsDirty(true)
        validateAddress(false)
      } else if (newValue.length > CHAR_LIMIT.STATE_LIMIT) {
        setCountyErr(true)
        setCountErrMsg(`Max ${CHAR_LIMIT.STATE_LIMIT} characters are accepted`)
        setIsDirty(true)
        validateAddress(false)
      } else {
        setCountyErr(false)
        setCountErrMsg('')
        validateAddress(true)
      }
      return newValue.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleStateChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setNewState(
      (e.target.value as string)
        ?.replace(/[^a-z-A-Z ]/g, '')
        .replace('Backspace', '')
    )
  }

  const handleAutoAddressEntry = (address: google.maps.GeocoderResult) => {
    console.log('\n Address:', address)
    console.log('\n Address components:', address.address_components)
    const extractedComponents = extractAddressComponents(
      address.address_components
    )

    setNewAddress1(extractedComponents.streetAddress)
    setNewAddress2(extractedComponents.neighborhood)
    setNewCity(extractedComponents.city)
    setNewState(extractedComponents.state)
    setNewZipCode(extractedComponents.zip)
    setNewCounty(extractedComponents.county)
  }

  const handleAddressInputIsDirty = (bool: boolean) => {
    setIsDirty(bool)
  }

  return (
    <>
      <div className={classes.root}>
        {showManualAddressEntry ? (
          // MANUAL ADDRESS ENTRY
          <>
            <Box mt={3} width={'100%'}>
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {strings.creditCardChangeForm.address1}
              </Typography>
              {/* <TextField
                value={newAddress1}
                id={'address-1'}
                placeholder={
                  strings.creditCardChangeForm.streetAddressPlaceholder
                }
                onChange={handleAddress1Change}
                margin="normal"
                variant="outlined"
                fullWidth={true}
                error={address1Err}
                helperText={address1ErrMsg}
              /> */}

              <AsurionTextField
                label={strings.creditCardChangeForm.streetAddressPlaceholder}
                value={newAddress1}
                fieldStatus={address1Err ? 'error' : 'default'}
                helperText={address1ErrMsg}
                onChange={handleAddress1Change}
              />
              <Box my={2} width="100%">
                <Typography variant={'subtitle2'} color={'textSecondary'}>
                  {strings.creditCardChangeForm.address2}
                </Typography>
                {/* <TextField
                  id={'address-2'}
                  value={newAddress2}
                  placeholder={
                    strings.creditCardChangeForm.streetAddress2Placeholder
                  }
                  onChange={handleAddress2Change}
                  margin="normal"
                  variant="outlined"
                  fullWidth={true}
                  error={address2Err}
                  helperText={address2ErrMsg}
                /> */}

                <AsurionTextField
                  label={strings.creditCardChangeForm.streetAddress2Placeholder}
                  value={newAddress2}
                  fieldStatus={address2Err ? 'error' : 'default'}
                  helperText={address2ErrMsg}
                  onChange={handleAddress2Change}
                />
              </Box>

              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <Typography variant={'subtitle2'} color={'textSecondary'}>
                    {strings.creditCardChangeForm.city}
                  </Typography>
                </Grid>
                <Grid item={true} xs={4}>
                  <Typography variant={'subtitle2'} color={'textSecondary'}>
                    {USE_BRITISH_ADDRESSING
                      ? strings.creditCardChangeForm.county
                      : strings.creditCardChangeForm.state}
                  </Typography>
                </Grid>
                <Grid item={true} xs={4}>
                  <Typography variant={'subtitle2'} color={'textSecondary'}>
                    {USE_BRITISH_ADDRESSING
                      ? strings.creditCardChangeForm.postCode
                      : strings.creditCardChangeForm.zipCode}
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={1}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={USE_BRITISH_ADDRESSING ? 4 : 5}>
                    {/* <TextField
                      value={newCity}
                      id="city"
                      fullWidth={true}
                      onChange={handleCityChange}
                      variant="outlined"
                      placeholder={strings.creditCardChangeForm.city}
                    /> */}
                    <AsurionTextField
                      label={strings.creditCardChangeForm.city}
                      value={newCity}
                      onChange={handleCityChange}
                      fieldStatus={cityErr ? 'error' : 'default'}
                      helperText={cityErrMsg}
                    />
                  </Grid>

                  {USE_BRITISH_ADDRESSING ? (
                    <Grid item={true} xs={4}>
                      {/* <TextField
                        value={newCounty}
                        id="county"
                        fullWidth={true}
                        onChange={handleCountyChange}
                        variant="outlined"
                        placeholder={strings.creditCardChangeForm.county}
                      /> */}

                      <AsurionTextField
                        label={strings.creditCardChangeForm.county}
                        value={newCounty}
                        onChange={handleCountyChange}
                        fieldStatus={countyErr ? 'error' : 'default'}
                        helperText={countyErrMsg}
                      />
                    </Grid>
                  ) : (
                    <Grid item={true} xs={3}>
                      <Box>
                        <Select
                          value={newState}
                          fullWidth={true}
                          onChange={handleStateChange}
                          input={<OutlinedInput labelWidth={0} name="state" />}
                        >
                          {states.map((state) => (
                            <MenuItem
                              value={state.abbreviation}
                              key={state.abbreviation}
                            >
                              {state.abbreviation}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                  )}

                  <Grid item={true} xs={4}>
                    {USE_BRITISH_ADDRESSING ? (
                      // <TextField
                      //   value={newZipCode}
                      //   fullWidth={true}
                      //   id="post-code"
                      //   variant="outlined"
                      //   onChange={handleZipCodeChange}
                      // />
                      <AsurionTextField
                        label="Code"
                        value={newZipCode}
                        onChange={handleZipCodeChange}
                      />
                    ) : (
                      <InputMask
                        value={newZipCode}
                        type="text"
                        name="zipCode"
                        id="zip-code"
                        mask="99999"
                        maskChar=""
                        onChange={handleZipCodeChange}
                        {...textFieldProps}
                      >
                        {(inputProps: any) => (
                          <TextField
                            fullWidth={true}
                            variant="outlined"
                            error={!!newZipCode && newZipCode.length !== 5}
                            helperText={`${
                              !!newZipCode && newZipCode.length !== 5
                                ? strings.creditCardChangeForm.zipCodeHint
                                : ''
                            }`}
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          // AUTO ADDRESS ENTRY
          <Box width="100%" mt={3}>
            <Typography variant={'subtitle2'} color={'textSecondary'}>
              {strings.creditCardChangeForm.address}
            </Typography>
            <AddressInput
              error={isDirty && (!newCity || !newState || !newZipCode)}
              handleAutoAddressEntry={handleAutoAddressEntry}
              isDirty={handleAddressInputIsDirty}
            />

            {/* <Box mt={2} width="100%">
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {strings.creditCardChangeForm.address2}
              </Typography>
              <TextField
                value={newAddress2}
                onChange={handleAddress2Change}
                placeholder={
                  strings.creditCardChangeForm.streetAddress2Placeholder
                }
                margin="normal"
                variant="outlined"
                fullWidth={true}
              />
            </Box> */}

            <Box mx={1}>
              {strings.creditCardChangeForm.havingTrouble}{' '}
              <Link
                id={'manual-address-link'}
                style={{ cursor: 'pointer', color: '#0040F0' }}
                onClick={() => {
                  setShowManualAddressEntry(true)
                }}
              >
                {strings.creditCardChangeForm.enterManually}
              </Link>
            </Box>
          </Box>
        )}
      </div>
    </>
  )
}

export default AddressChangeForm
