import React, { ComponentType, useContext, useState, useMemo } from 'react'
import ApiClient from './ApiClient'
import useReactRouter from 'use-react-router'
import { History } from 'history'

export interface ICommonContext<StoreType> {
  apiClient: ApiClient
  history: History
  store: StoreType
  updateStore: (partialStore: Partial<StoreType>) => void
}

interface IStoreProvider<StoreType> {
  apiClient: ApiClient
  store: StoreType
  children?: React.ReactNode | undefined
}

const initStateManagement = <StoreType, ActionType, ContextType>({
  getActions,
}: {
  getActions: (context: ICommonContext<StoreType>) => ActionType
}) => {
  type StateManagementType = <MappedState, MappedMethods>(
    mapStateToProps: (store: StoreType) => MappedState,
    mapDispatchToProps?: (methods: ActionType) => MappedMethods
  ) => <BaseProps>(
    Component: ComponentType<BaseProps & MappedState & MappedMethods>
  ) => ComponentType<BaseProps>

  const ApiClientContext = React.createContext(
    undefined as unknown as ApiClient
  )
  ApiClientContext.displayName = 'ApiClientContext'
  const useApi = (): ApiClient => {
    const apiClient = useContext(ApiClientContext)

    if (apiClient === undefined) {
      throw new Error('You must provide an apiClient to the StoreProvider')
    }

    return apiClient
  }

  const StoreContext = React.createContext<StoreType>(
    undefined as unknown as StoreType
  )
  StoreContext.displayName = 'StoreContext'
  const useStore = (): StoreType => {
    const store = useContext(StoreContext)

    if (store === undefined) {
      throw new Error(
        'You must specify a store. You probably forgot the provider.'
      )
    }

    return store
  }

  const DispatchContext = React.createContext<
    (partialStore: Partial<StoreType>) => void
  >(() => {
    throw new Error(
      'You must specify a dispatch context. You probably forgot the provider.'
    )
  })
  DispatchContext.displayName = 'DispatchContext'
  const useDispatch = () => {
    return useContext(DispatchContext)
  }

  const useActions = (): ActionType => {
    const { history } = useReactRouter()
    return getActions({
      store: useStore(),
      apiClient: useApi(),
      updateStore: useDispatch(),
      history,
    })
  }

  const connect: StateManagementType =
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) => {
      const actions = useActions()
      const mappedDispatch = useMemo(
        () => (mapDispatchToProps ? mapDispatchToProps(actions) : undefined),
        [actions]
      )
      const allProps = {
        ...mapStateToProps(useStore()),
        ...mappedDispatch,
        ...props,
      }
      return <Component {...(allProps as any)} />
    }

  const StoreProvider: React.FC<IStoreProvider<StoreType>> = (props) => {
    const [store, setStore] = useState(props.store)

    const updateStore = (partialStore: Partial<StoreType>) => {
      setStore((s: StoreType) => {
        return {
          ...s,
          ...partialStore,
        }
      })
    }
    return (
      <StoreContext.Provider value={store}>
        <ApiClientContext.Provider value={props.apiClient}>
          <DispatchContext.Provider value={updateStore}>
            {props.children}
          </DispatchContext.Provider>
        </ApiClientContext.Provider>
      </StoreContext.Provider>
    )
  }

  return {
    connect,
    useActions,
    useApi,
    useStore,
    StoreProvider,
  }
}

export default initStateManagement
