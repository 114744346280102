import React, { useContext } from 'react'

const initConfig = <ConfigType extends {}>() => {
  const ConfigContext = React.createContext(null as unknown as ConfigType)
  ConfigContext.displayName = 'ConfigContext'

  const useConfig = () => {
    const config = useContext<ConfigType>(ConfigContext)

    if (config === null) {
      throw new Error(
        'You must specify a config. You probably forgot the provider.'
      )
    }

    return config
  }

  const ConfigProvider: React.FC<{
    config: ConfigType
    children?: React.ReactNode | undefined
  }> = (props) => {
    return (
      <ConfigContext.Provider value={props.config}>
        {props.children}
      </ConfigContext.Provider>
    )
  }

  return { ConfigProvider, useConfig }
}

export default initConfig
