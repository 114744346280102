import React, { useContext } from 'react'
import {
  LocalizedStringsLookup,
  Locale,
  LocaleProviderType,
  UseLocaleType,
} from './interfaces'

const getLocalizedStrings = <LocalizedStringsType>(
  lookup: LocalizedStringsLookup<LocalizedStringsType>,
  locale: Locale
) => {
  const englishFallback = lookup[Locale.English]
  const localizedStrings = lookup[locale]

  if (englishFallback === undefined) {
    throw new Error('Localization error: No localized strings were found.')
  }

  if (localizedStrings === undefined) {
    console.error(
      new Error(
        'Localization error: No localized strings were found. Defaulting to English.'
      )
    )
    return englishFallback
  } else {
    return localizedStrings
  }
}

const initLocale = <LocalizedStringsType>(
  lookup: LocalizedStringsLookup<LocalizedStringsType>
): {
  useLocale: UseLocaleType<LocalizedStringsType>
  LocaleProvider: LocaleProviderType
} => {
  const LocaleContext = React.createContext((undefined as unknown) as Locale)
  const LocaleProvider = LocaleContext.Provider

  const useLocale = () => {
    const locale = useContext(LocaleContext)

    if (locale === undefined) {
      throw new Error(
        'You must specify a locale. You probably forgot the provider.'
      )
    }

    return {
      locale,
      strings: getLocalizedStrings(lookup, locale),
    }
  }

  return { useLocale, LocaleProvider }
}

export default initLocale
