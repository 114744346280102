import React from 'react'
import { Box, Flex, Image } from 'rebass'
import tescoProtectLogo from '../../assets/tesco-protect-logo-blue.png'
import TescoConstrainer from '../utilities/TescoConstrainer'
import { Link } from 'react-router-dom'
import { tescoBlue } from '../../theme'
import { useHandleLinkClick } from '../utilities/useHandleLinkClick'
import { EventType } from '../../../../generic/interfaces'
import Button from '../../../../sharedComponents/atoms/Button'

let portrait: boolean

if (window.innerHeight > window.innerWidth) {
  portrait = true
} else {
  portrait = false
}

interface TescoHeaderProps {
  onStartAClaim: () => void
  isSignedIn?: boolean
}
const allowLogin = true
const TescoHeader: React.FC<TescoHeaderProps> = ({
  isSignedIn,
  onStartAClaim,
}) => {
  const myAccountLink = useHandleLinkClick(
    EventType.HeaderMyAccountLinkClicked,
    'My Account',
    window.location.href
  )
  const signOutLink = useHandleLinkClick(
    EventType.SignOutLinkClicked,
    'Sign Out',
    window.location.href
  )
  return (
    <Box backgroundColor={'#FFF'}>
      <TescoConstrainer>
        <Flex alignItems="center" justifyContent="space-between" py={1} px={3}>
          <Link to="/account/overview">
            <Image
              alt="Tesco Protect logo"
              src={tescoProtectLogo}
              height="auto"
              width="150px"
            />
          </Link>

          <Flex justifyContent="flex-end">
            {isSignedIn && (
              <Button
                ml={4}
                variant="unstyled"
                onClick={onStartAClaim}
                color="primary"
                sx={{ minHeight: '0 !important' }}
              >
                Start a claim
              </Button>
            )}
            {allowLogin && (
              <Box ml={4} sx={{ display: isSignedIn ? 'block' : 'none' }}>
                <Link
                  data-cy="signOut"
                  to="/account/sign-out"
                  style={{
                    flexShrink: 0,
                    textDecoration: 'none',
                    color: tescoBlue,
                  }}
                  onClick={signOutLink}
                >
                  Sign out
                </Link>
              </Box>
            )}
            {allowLogin && (
              <Link
                to="/account/overview"
                style={{
                  display: isSignedIn ? 'none' : 'block',
                  flexShrink: 0,
                  textDecoration: 'none',
                  color: tescoBlue,
                  fontSize: portrait ? '3vh' : '1.8vw',
                  fontWeight: 'bold',
                  letterSpacing: '0.08vw',
                }}
                onClick={myAccountLink}
              >
                Manage Account
              </Link>
            )}
          </Flex>
        </Flex>
      </TescoConstrainer>
    </Box>
  )
}

export default TescoHeader
