import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

const getSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const debouncedResize = debounce(() => {
      setWindowSize(getSize())
    }, 100)

    window.addEventListener('resize', debouncedResize)

    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  return windowSize
}

export default useWindowSize
