import globalConfig from '../../config'
import theme from './theme'
import { IEnrollmentConfig, Locale, Partner } from '../../generic/interfaces'
import portalErrorLookup from '../../generic/sharedHelpers/portalErrorLookup'

const config: IEnrollmentConfig = {
  ACCOUNT_PATH: '/account',
  ALLOWED_CC_TYPES: 'VISA|MASTER|AMEX|DISC',
  APP_MAX_WIDTH: 1024,
  APP_MIN_WIDTH: 350,
  BYOD_ID: '7031A',
  CLIENT_CHANNEL_ID: '80375A75127456EEE053A239030AE1ED',
  CUST_SERV_PHONE_NO: '(866) 727-1998',
  DEFAULT_PLAN_SKU: 'PDPF',
  ENROLL_PATH: '/enroll',
  THEME: theme,
  API_BASE_URL: globalConfig.ATT_API_BASE_URL,
  BG_ENDPOINT: globalConfig.BG_ENDPOINT,
  DISABLE_CAPTCHA: globalConfig.DISABLE_CAPTCHA,
  DISABLE_REFRESH: globalConfig.DISABLE_REFRESH,
  MIXPANEL_ENABLED: globalConfig.MIXPANEL_ENABLED,
  PROTECH_LINK: globalConfig.PROTECH_LINK,
  RECAPTCHA_KEY: globalConfig.RECAPTCHA_KEY,
  IS_DOWN_FOR_MAINTENANCE: globalConfig.IS_DOWN_FOR_MAINTENANCE,
  PORTAL_ERROR_LOOKUP: portalErrorLookup,

  DEFAULT_LOCALE: Locale.English,
  COUNTRY_CALLING_CODE: '1',
  PARTNER: Partner.ATT,
  BILLING_PROGRAM_ID: '942894',
  SHOW_TEXT_MESSAGE_DISCLAIMER: true,

  SHOW_DEVICE_HEALTH: true,
}

export default config
