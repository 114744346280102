import cleanNumberString from '../../sharedHelpers/cleanNumberString'
import { Country } from '../interfaces'

const validators: Record<Country, (str: string) => boolean> = {
  [Country.US](str: string) {
    return str.length === 10
  },
  [Country.Brazil](str: string) {
    return str.length === 11
  },
  [Country.GB](str: string) {
    // +99 999999999, +99 9999999999
    return str.length === 11 || str.length === 12
  },
}

export default function (val: string, country?: Country): boolean {
  const cleanedString = cleanNumberString(val)
  const validator = validators[country || Country.US]

  return validator(cleanedString)
}
