import React from 'react'
import { connect } from '../state/accountStateMgmt'
import LoadingOverlay from '../../../sharedComponents/utilities/LoadingOverlay'

const ConnectedLoadingOverlay = connect((store) => ({
  isScreenBlocked: store.isScreenBlocked,
}))<{}>(
  React.memo(({ isScreenBlocked }: { isScreenBlocked: boolean }) => {
    return isScreenBlocked ? <LoadingOverlay /> : null
  })
)

export default ConnectedLoadingOverlay
