import React from 'react'
import ReactCodesInput from 'react-codes-input'
import { Box, BoxProps } from 'rebass'
import { error } from '../../att/marketing/theme'
import 'react-codes-input/lib/react-codes-input.min.css'
import { useLocale } from '../../generic/localization'

interface PinEntryProps extends BoxProps {
  pinValue: string
  onPinChange: (x: string) => void
  isError?: boolean
}
const PinEntry: React.FC<PinEntryProps> = ({
  pinValue,
  onPinChange,
  isError,
  ...otherProps
}) => {
  const { strings } = useLocale()

  return (
    <Box {...otherProps}>
      {/* For screen readers */}
      <label
        htmlFor="pinEntry"
        style={{
          height: '1px',
          width: '1px',
          margin: '1px',
          overflow: 'hidden',
          position: 'absolute',
          clip: 'rect(0 0 0 0)',
          padding: 0,
        }}
      >
        {strings.sharedComponents.pinEntry.verificationCode}
      </label>
      <ReactCodesInput
        initialFocus={true}
        id="pinEntry"
        codeLength={6}
        type="number"
        onChange={onPinChange}
        value={pinValue}
        customStyleComponent={{
          width: 400,
          fontFamily: 'Helvetica',
          margin: '6px',
          padding: '2px',
          MozAppearance: 'textfield',
          borderRadius: '5px',
          fontSize: 80,
          height: '78px',
          backgroundColor: 'white',
          cursor: 'pointer',
        }}
        customStyleWrapper={{
          border: 'none',
        }}
        customStyleCodeWrapper={{
          border: `solid 1px ${isError ? error : 'black'}`,
          borderRadius: 5,
          margin: '0 10px',
        }}
        customStyleCode={{
          border: 'none',
        }}
        customStyleEnteredValue={{
          color: isError ? error : 'black',
        }}
      />
    </Box>
  )
}

export default PinEntry
