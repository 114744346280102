import {
  Box,
  makeStyles,
  // TextField,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { IBillingAddress, ICCPaymentData } from '../../interfaces'
import SubmitButton from '../../sharedComponents/SubmitButton'
import CreditCardName from './CreditCardName'
import getValidationErrors from '../../sharedHelpers/getValidationErrors'
import InputMask from 'react-input-mask'
import ccTypeDetector from '../../sharedHelpers/detectCardType'
import { useLocale } from '../../localization'
import { useConfig } from '../state/accountConfigSetup'
import AddressChangeForm from './AddressChangeForm'
import { TextField as AsurionTextField } from '@soluto-private/mx-asurion-ui-react'
import { CHAR_LIMIT, REG_EXP } from '../common/constants'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

interface IPaymentModalFormProps {
  // nameOnCard: string
  billingFirstName: string | undefined
  billingLastName: string | undefined
  ccType: string
  address: IBillingAddress
  submitText?: string
  onSaveChanges: (paymentMethodData: ICCPaymentData) => void
  loading: boolean
  textFieldProps?: any | {}
}
const CreditCardChangeForm: React.FC<IPaymentModalFormProps> = ({
  billingFirstName = '',
  billingLastName = '',
  ccType,
  address,
  submitText,
  loading,
  onSaveChanges,
  textFieldProps = {},
}) => {
  const { ALLOWED_CC_TYPES, USE_BRITISH_ADDRESSING } = useConfig()
  const { strings } = useLocale()
  const classes = useStyles()
  // const [newName, setNewName] = useState(nameOnCard || '')
  const [ccNumber, setNewCcNumber] = useState('')
  const [maskedCcNumber, setMaskedCcNum] = useState('')
  const [newCCType, setNewCCType] = useState(ccType || '')
  const [newSecurityCode, setNewSecurityCode] = useState('')
  const [maskedSecCode, setMaskedSecCode] = useState('')
  const [newExpDate, setNewExpDate] = useState('')
  const [isCcInputFocused, setIsCcInputFocused] = useState(false)
  const [isSecCodeFocused, setIsSecCodeFocused] = useState(false)
  const [newAddress, setNewAddress] = useState<IBillingAddress>(address)
  const [firstName, setFirstName] = useState(billingFirstName)
  const [lastName, setLastName] = useState(billingLastName)
  const [fNameErrorMessage, setFNameErrorMessage] = useState('')
  const [lNameErrorMessage, setLNameErrorMessage] = useState('')
  const [fNameError, setFnameError] = useState(false)
  const [lNameError, setLnameError] = useState(false)
  const [isNewAddressValid, setIsNewAddressValid] = useState(true)

  const ccInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const ccInput = ccInputRef.current

    if (ccInput !== null) {
      ccInput.onfocus = () => {
        setIsCcInputFocused(true)
      }

      ccInput.onblur = () => {
        setIsCcInputFocused(false)
      }
    }
  }, [ccInputRef])

  const secCodeRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const secCode = secCodeRef.current

    if (secCode !== null) {
      secCode.onfocus = () => {
        setIsSecCodeFocused(true)
      }

      secCode.onblur = () => {
        setIsSecCodeFocused(false)
      }
    }
  }, [secCodeRef])

  // const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewName(e.target.value)
  // }

  const handleCreditCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCcNumber(e.target.value?.replace('Backspace', ''))
    setMaskedCcNum(
      e.target.value
        .split('')
        .map((c, i) => {
          if (c === ' ') return c

          return i < 15 ? '*' : c
        })
        .join('')
    )
    setNewCCType(
      ccTypeDetector(e.target.value?.replace('Backspace', '')).toUpperCase()
    )
  }

  const handleSecurityCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSecurityCode(e.target.value?.replace('Backspace', ''))
    setMaskedSecCode(e.target.value.replace(/./g, '*').replace('Backspace', ''))
  }

  const handleExpDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewExpDate(e.target.value?.replace('Backspace', ''))
  }

  const currentCCInfo = {
    creditCardNumber: ccNumber,
    creditCardType: newCCType,
    expMonth: parseInt(newExpDate.substr(0, 2), 10),
    expYear: parseInt(newExpDate.substr(3, 4), 10),
    // nameOnCard: newName,
    firstName,
    lastName,
    securityCode: newSecurityCode,
  }

  const validationErrors = getValidationErrors(
    currentCCInfo,
    strings,
    ALLOWED_CC_TYPES
  )

  const isFormValid =
    // !!newName &&
    !validationErrors.expirationDate &&
    !validationErrors.creditCardNumber &&
    !validationErrors.securityCode &&
    !!newAddress.addressLine1 &&
    !!newAddress.city &&
    !!newAddress.state &&
    (USE_BRITISH_ADDRESSING
      ? newAddress.postalCode.length > 1
      : newAddress.postalCode.length === 5) &&
    !fNameError &&
    !lNameError &&
    isNewAddressValid

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isFormValid) {
      onSaveChanges({
        address: newAddress,
        creditCardNumber: ccNumber,
        // nameOnCard: newName,
        firstName,
        lastName,
        ccType: newCCType,
        expDate: newExpDate,
        cvv: newSecurityCode,
      })
    }
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFirstName((oldValue) => {
      if (!REG_EXP.EMPTY_STRING.test(value)) {
        setFnameError(true)
        setFNameErrorMessage('First name required')
      } else if (value.length > CHAR_LIMIT.FIRST_NAME_LIMIT) {
        setFnameError(true)
        setFNameErrorMessage(
          `Max length should be ${CHAR_LIMIT.FIRST_NAME_LIMIT} characters`
        )
      } else {
        setFNameErrorMessage('')
        setFnameError(false)
      }
      return value.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLastName((oldValue) => {
      if (!REG_EXP.EMPTY_STRING.test(value)) {
        setLnameError(true)
        setLNameErrorMessage('Last name required')
      } else if (value.length > CHAR_LIMIT.LAST_NAME_LIMIT) {
        setLnameError(true)
        setLNameErrorMessage(
          `Max length should be ${CHAR_LIMIT.LAST_NAME_LIMIT} characters`
        )
      } else {
        setLnameError(false)
        setLNameErrorMessage('')
      }
      return value.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const validateNewAddress = (isValid: boolean) => {
    setIsNewAddressValid(isValid)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <Box width="100%">
          <Typography
            data-cy="name-on-card"
            variant={'subtitle2'}
            color={'textSecondary'}
          >
            {strings.creditCardChangeForm.nameOnCard}
          </Typography>
          {/* <TextField
            data-cy={'name-on-card'}
            value={newName}
            onChange={handleNameChange}
            error={!newName}
            helperText={`${
              !newName ? strings.creditCardChangeForm.nameHint : ''
            }`}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          /> */}
          <Box display={'flex'} width={'100%'}>
            <div style={{ width: '50%', paddingRight: '15px' }}>
              <AsurionTextField
                value={firstName}
                onChange={handleFirstNameChange}
                fieldStatus={fNameError ? 'error' : 'default'}
                helperText={fNameErrorMessage}
                label="First Name"
                disabled={false}
              />
            </div>
            <div style={{ width: '50%' }}>
              <AsurionTextField
                value={lastName}
                onChange={handleLastNameChange}
                fieldStatus={lNameError ? 'error' : 'default'}
                helperText={lNameErrorMessage}
                label="Last Name"
                disabled={false}
              />
            </div>
          </Box>
        </Box>

        <Box mt={2} width={'100%'}>
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              xs={12}
              sm={6}
              container={true}
              direction="column"
              justify="space-between"
            >
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {strings.creditCardChangeForm.creditCardNumber}
              </Typography>
              <Box style={{ position: 'relative' }}>
                <InputMask
                  formatChars={{
                    '9': '[0-9]',
                    a: '[A-Za-z]',
                    '*': '[A-Za-z0-9]',
                    '.': '.',
                  }}
                  type="text"
                  name="creditCardNumber"
                  id="credit-card-number"
                  autoComplete="cc-number"
                  mask={
                    isCcInputFocused
                      ? '9999 9999 9999 9999'
                      : '.... .... .... ....'
                  }
                  maskChar=""
                  value={isCcInputFocused ? ccNumber : maskedCcNumber}
                  // value={ccNumber}
                  onChange={handleCreditCardChange}
                  {...textFieldProps}
                >
                  {(inputProps: any) => (
                    // <TextField
                    //   inputRef={ccInputRef}
                    //   type="credit"
                    //   fullWidth={true}
                    //   margin={'normal'}
                    //   variant="outlined"
                    //   placeholder={strings.misc.cardNumber}
                    //   error={!!ccNumber && !!validationErrors.creditCardNumber}
                    //   helperText={`${!!ccNumber && !!validationErrors.creditCardNumber
                    //     ? strings.creditCardChangeForm.cardNumberHint
                    //     : ''
                    //     }`}
                    //   {...inputProps}
                    // />
                    <AsurionTextField
                      ref={ccInputRef}
                      label={strings.misc.cardNumber}
                      fieldStatus={
                        !!ccNumber && !!validationErrors.creditCardNumber
                          ? 'error'
                          : 'default'
                      }
                      helperText={
                        !!ccNumber && !!validationErrors.creditCardNumber
                          ? strings.creditCardChangeForm.cardNumberHint
                          : ''
                      }
                      {...inputProps}
                    ></AsurionTextField>
                  )}
                </InputMask>

                <Box position={'absolute'} top={26} right={12}>
                  <CreditCardName
                    creditCardType={newCCType ? newCCType.toLowerCase() : ''}
                    text={false}
                    visible={!validationErrors.creditCardNumber}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item={true}
              xs={6}
              sm={3}
              container={true}
              direction="column"
              justify="space-between"
            >
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {strings.creditCardChangeForm.expirationDate}
              </Typography>
              <InputMask
                value={newExpDate}
                type="text"
                name="expDate"
                id="exp-date"
                mask="99/9999"
                maskChar=""
                placeholder={strings.creditCardChangeForm.expDatePlaceholder}
                onChange={handleExpDateChange}
                {...textFieldProps}
              >
                {(inputProps: any) => (
                  // <TextField
                  //   fullWidth={true}
                  //   margin={'normal'}
                  //   variant="outlined"
                  //   error={!!newExpDate && !!validationErrors.expirationDate}
                  //   {...inputProps}
                  //   helperText={!!newExpDate && validationErrors.expirationDate}
                  // />
                  <AsurionTextField
                    label="MM/YYYY"
                    fieldStatus={
                      !!newExpDate && !!validationErrors.expirationDate
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      !!newExpDate && !!validationErrors.expirationDate
                    }
                    {...inputProps}
                  ></AsurionTextField>
                )}
              </InputMask>
            </Grid>
            <Grid
              item={true}
              xs={6}
              sm={3}
              container={true}
              direction="column"
              justify="space-between"
            >
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {strings.creditCardChangeForm.securityCode}
              </Typography>
              <InputMask
                formatChars={{
                  '9': '[0-9]',
                  a: '[A-Za-z]',
                  '*': '[A-Za-z0-9]',
                  '.': '.',
                }}
                type="text"
                name="securityCode"
                id="security-code"
                mask={isSecCodeFocused ? '9999' : '....'}
                placeholder={'CVV'}
                maskChar=""
                value={isSecCodeFocused ? newSecurityCode : maskedSecCode}
                onChange={handleSecurityCodeChange}
                {...textFieldProps}
              >
                {(inputProps: any) => (
                  // <TextField
                  //   inputRef={secCodeRef}
                  //   variant="outlined"
                  //   error={!!newSecurityCode && !!validationErrors.securityCode}
                  //   margin={'normal'}
                  //   fullWidth={true}
                  //   helperText={`${!!newSecurityCode && !!validationErrors.securityCode
                  //     ? strings.creditCardChangeForm.cvvHint
                  //     : ''
                  //     }`}
                  //   {...inputProps}
                  // />
                  <AsurionTextField
                    label="CVV"
                    ref={secCodeRef}
                    fieldStatus={
                      !!newSecurityCode && !!validationErrors.securityCode
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      !!newSecurityCode && !!validationErrors.securityCode
                        ? strings.creditCardChangeForm.cvvHint
                        : ''
                    }
                    {...inputProps}
                  ></AsurionTextField>
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ width: '100%' }}>
          <AddressChangeForm
            oldAddress={address}
            loading={loading}
            onChange={setNewAddress}
            validateAddress={validateNewAddress}
          />
        </Box>

        <SubmitButton
          text={submitText || strings.creditCardChangeForm.saveChanges}
          id={'payment-submit'}
          disabled={!isFormValid}
          loading={loading}
        />
      </form>
    </>
  )
}

export default CreditCardChangeForm
