import currentIcon from '../../sharedAssets/icon_current.png'
import pastDueIcon from '../../sharedAssets/icon_past-due.png'
import React, { CSSProperties } from 'react'
import { Box, useTheme } from '@material-ui/core'
import { useLocale } from '../../localization'
import { BillingStatus } from '../../interfaces'
import { Warning } from '@material-ui/icons'

const iconImageStyle: CSSProperties = {
  height: 25,
}

const iconMap: { [key: string]: string } = {
  Current: currentIcon,
  'Past Due': pastDueIcon,
}

const PaymentStatus: React.FC<{ billingStatus: BillingStatus }> = ({
  billingStatus,
}) => {
  const theme = useTheme()
  const { strings } = useLocale()
  const textMap: { [key: string]: string } = {
    Current: strings.paymentStatus.current,
    'Past Due': strings.paymentStatus.pastDue,
    Pending: strings.paymentStatus.pending,
  }
  const statusText = textMap[billingStatus]
  const statusImage = (
    <img
      src={iconMap[billingStatus]}
      style={iconImageStyle}
      alt={strings.paymentStatus.statusIconAltText}
    />
  )

  return (
    <Box display={'flex'}>
      {billingStatus === BillingStatus.Pending ? (
        <Warning style={{ color: theme.palette.warning.main }} />
      ) : (
        <Box>{statusImage}</Box>
      )}

      <Box data-cy={'payment-status'} style={{ marginLeft: 5, marginTop: 1 }}>
        {statusText}
      </Box>
    </Box>
  )
}

export default PaymentStatus
