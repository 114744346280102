export enum EventType {
  InitialPageLoad = 'InitialPageLoad',
  InitializationError = 'InitializationError',
  SmsReminderSuccess = 'SMSReminderSuccess',
  SmsReminderFailure = 'SMSReminderFailure',
  DefaultDeviceChanged = 'DefaultDeviceChanged',
  EnrollmentSuccess = 'EnrollmentSuccessPortal',
  EnrollmentFailure = 'EnrollmentFailurePortal',
  InfoChanged = 'InfoChanged',
  ChangeInfoLinkClick = 'ChangeInfoLinkClick',
  ChangeDeviceLinkClick = 'ChangeDeviceLinkClick',
  ChatLinkClicked = 'ChatLinkClick',
  FrontendError = 'FrontendException',
  ResendSmartPin = 'ResendSmartPin',
  PageChange = 'PageChange',
  LinkClicked = 'LinkClicked',
  ManualAddressEnrty = 'ManualAddressEntry',
  ClaimLinkClicked = 'ClaimLinkClick',
  AuthenticatedClaimLinkClick = 'AuthenticatedClaimLinkClicked',
  CallCenterLinkClicked = 'CallCenterLinkClicked',
  PlanSelected = 'PlanSelected',
  ProtechTomorrowSelected = 'ProtechTomorrowSelected',
  ProtechTodaySelected = 'ProtechTodaySelected',
  ProtechOtherDaysSelected = 'ProtechOtherDaysSelected',
  ProtechNewMDNEntered = 'ProtechNewMDNEntered',
  ProtechConfirmationClick = 'ProtechConfirmationClick',
  AddToCalendarClick = 'AddToCalendarClick',
  ProtechSchedulePageLoad = 'ProtechSchedulePageLoad',
  ProtechTimeSelected = 'ProtechTimeSelected',
  ProtechHelpRequest = 'ProtechHelpRequest',
  ProtechClaimLinkClicked = 'ProtechClaimLinkClicked',
  ProtechOverviewLinkClicked = 'ProtechOverviewLinkClicked',
  ProtechSupportLinkClicked = 'ProtechSupportLinkClicked',
  ProtechLinkClicked = 'ProtechLinkClicked',
  ProtechCancelScheduling = 'ProtechCancelScheduling',
  ProtechOtherDaySelected = 'ProtechOtherDaySelected',
  ProtechTimeZoneSelected = 'ProtechTimeZoneSelected',
  ProtechAppLinkClicked = 'ProtechAppLinkClicked',
  FootnoteLinkClicked = 'FootnoteLinkClicked',
  EnrollNowButtonClicked = 'EnrollNowButtonClicked',
  ContinueButtonClicked = 'ContinueButtonClicked',
  ResetCarrierPinInstructionsLinkClicked = 'ResetCarrierPinInstructionsLinkClicked',
  CloseCarrierPinInstructionsModal = 'CloseCarrierPinInstructionsModal',
  BackButtonClicked = 'BackButtonClicked',
  DeductibleInformationModalOpened = 'DeductibleInformationModalOpened',
  DeductibleInformationModalClosed = 'DeductibleInformationModalClosed',
  AgreeToTermsChecked = 'AgreeToTermsChecked',
  LanguageChanged = 'LanguageChanged',
  EnrollAnotherDeviceClicked = 'EnrollAnotherDeviceClicked',
  ContinueToCoverageReviewPageClicked = 'ContinueToCoverageReviewPageClicked',
  CantEnrollBackButtonClick = 'CantEnrollBackButtonClick',
  ShowCantEnroll = 'ShowCantEnroll',
  DeviceConditionIsGood = 'DeviceConditionIsGood',
  StartClaimButtonClick = 'StartClaimButtonClick',
  ProtechLearnMoreLinkClick = 'ProtechLearnMoreLinkClick',
  ProtechLearnMoreButtonClick = 'ProtechLearnMoreButtonClick',
  PhotoStorageLearnMoreLinkClick = 'PhotoStorageLearnMoreLinkClick',
  PhotoStorageLearnMoreButtonClick = 'PhotoStorageLearnMoreButtonClick',
  PhoneProtectionLearnMoreLinkClick = 'PhoneProtectionLearnMoreLinkClick',
  PhoneProtectionLearnMoreButtonClick = 'PhoneProtectionLearnMoreButtonClick',
  ShowContactModalClick = 'ShowContactModalClick',
  ProtechAndroidAppDownloadLinkClick = 'ProtechAndroidAppDownloadLinkClick',
  ProtechIOSAppDownloadLinkClick = 'ProtechIOSAppDownloadLinkClick',
  PhotoStorageAndroidAppDownloadLinkClick = 'PhotoStorageAndroidAppDownloadLinkClick',
  PhotoStorageIOSAppDownloadLinkClick = 'PhotoStorageIOSAppDownloadLinkClick',
  PostEnrollProtechAndroidAppDownloadLinkClick = 'PostEnrollProtechAndroidAppDownloadLinkClick',
  PostEnrollProtechIOSAppDownloadLinkClick = 'PostEnrollProtechIOSAppDownloadLinkClick',
  PostEnrollPhotoStorageAndroidAppDownloadLinkClick = 'PostEnrollPhotoStorageAndroidAppDownloadLinkClick',
  PostEnrollPhotoStorageIOSAppDownloadLinkClick = 'PostEnrollPhotoStorageIOSAppDownloadLinkClick',
  DeductibleLookupFormSubmit = 'DeductibleLookupFormSubmit',
  CloseTAndCModal = 'CloseTAndCModal',
  TAndCDownloadLinkClick = 'TAndCDownloadLinkClick',
  InsuranceDownloadLinkClick = 'InsuranceDownloadLinkClick',
  ProtechTermsOfUseDownloadClick = 'ProtechTermsOfUseDownloadClick',
  NoPhoneLinkClick = 'NoPhoneLinkClick',
  NoPhoneModalClosed = 'NoPhoneModalClosed',
  FinishEnrollmentButtonClick = 'FinishEnrollmentButtonClick',
  PostEnrollmentMyAccountLinkClick = 'PostEnrollmentMyAccountLinkClick',
  HamburgerMenuOpenClick = 'HamburgerMenuOpenClick',
  HamburgerMenuCloseClick = 'HamburgerMenuCloseClick',
  ATTLogoClick = 'ATTLogoClick',
  DeductibleLookupClose = 'DeductibleLookupClose',
  MarketingInitialPageLoad = 'MarketingInitialPageLoad',
  CustomerIndicatedDeviceHadDamageAndChangedAnswer = 'CustomerIndicatedDeviceHadDamageAndChangedAnswer',
  HamburgerInsuranceLinkClick = 'HamburgerInsuranceLinkClick',
  HamburgerPhotoStorageLinkClick = 'HamburgerPhotoStorageLinkClick',
  HamburgerHomeLinkClick = 'HamburgerHomeLinkClick',
  HamburgerProtechLinkClick = 'HamburgerProtechLinkClick',
  HamburgerAccountLinkClick = 'HamburgerAccountLinkClick',
  HamburgerSignOutLinkClick = 'HamburgerSignOutLinkClick',
  HamburgerEnrollLinkClick = 'HamburgerEnrollLinkClick',
  HamburgerClaimLinkClick = 'HamburgerClaimLinkClick',

  // FAQ's
  FAQWhatIsMobileProtection = 'FAQWhatIsMobileProtection',
  FAQWhoIsEligible = 'FAQWhoIsEligible',
  FAQBringOwnPhone = 'FAQBringOwnPhone',
  FAQInsuranceCost = 'FAQInsuranceCost',
  FAQDeductible = 'FAQDeductible',
  FAQHowDoICancel = 'FAQHowDoICancel',
  FAQCutoffForNextDayReplacement = 'FAQCutoffForNextDayReplacement',
  FAQFileAClaim = 'FAQFileAClaim',
  FAQClaimDeductiblePayment = 'FAQClaimDeductiblePayment',
  FAQHowManyClaimsAllowed = 'FAQHowManyClaimsAllowed',
  FAQClaimWaitingPeriod = 'FAQClaimWaitingPeriod',
  FAQHowManyDaysDoIHaveToFileAClaim = 'FAQHowManyDaysDoIHaveToFileAClaim',
  FAQHowFastReplacement = 'FAQHowFastReplacement',
  FAQWhatWillBeReplacement = 'FAQWhatWillBeReplacement',
  FAQWhatHappensWhenMaxClaimsReached = 'FAQWhatHappensWhenMaxClaimReached',
  FAQHowDoICheckClaimStatus = 'FAQHowDoICheckClaimStatus',
  FAQHowDoIUploadClaimDocument = 'FAQHowDoIUploadClaimDocument',
  FAQWhyRepair = 'FAQWhyRepair',
  FAQWhyNotMallKioskOrOtherService = 'FAQWhyNotMallKioskOrOtherService',
  FAQCrackedScreenEligible = 'FAQCrackedScreenEligible',
  FAQWhatDamageIsEligibleForCrackScreenRepair = 'FAQWhatDamageIsEligibleForCrackScreenRepair',
  FAQWhatPhonesAreEligibleForCrackScreenRepair = 'FAQWhatPhonesAreEligibleForCrackScreenRepair',
  FAQHowMuchCrackScreenRepairCost = 'FAQHowMuchCrackScreenRepairCost',
  FAQWhatPartsForRepair = 'FAQWhatPartsForRepair',
  FAQIsTechnicianCertified = 'FAQIsTechnicianCertified',
  FAQDoesRepairCountTowardsMaxClaims = 'FAQDoesRepairCountTowardsMaxClaims',
  FAQWhatDoesRepairWarrantyCover = 'FAQWhatDoesRepairWarrantyCover',
  FAQInStoreAfterFilingClaimHowLongForRepair = 'FAQInStoreAfterFilingClaimHowLongForRepair',
  FAQOtherDamageThanCrackedScreen = 'FAQOtherDamageThanCrackedScreen',
  FAQHowLongDoesItTakeToCompleteRepair = 'FAQHowLongDoesItTakeToCompleteRepair',
  FAQRescheduleOrCancelAppointment = 'FAQRescheduleOrCancelAppointment',
  FAQWhatTimeWindowDoINeedToBeAvailable = 'FAQWhatTimeWindowDoINeedToBeAvailable',
  FAQWhenCanATechRepairMyDevice = 'FAQWhenCanATechRepairMyDevice',
  FAQWhatIsProtechApp = 'FAQWhatIsProtechApp',
  FAQHowDoIUseAppToContactSupportExpert = 'FAQHowDoIUseAppToContactSupportExpert',
  FAQWhatPhonesAreCompatibleWithProtechApp = 'FAQWhatPhonesAreCompatibleWithProtechApp',
  FAQDoesProtechAppUsePlanData = 'FAQDoesProtechAppUsePlanData',
  FAQWhatIsPhotoStorageApp = 'FAQWhatIsPhotoStorageApp',
  FAQHowDoIGetPhotoStorageApp = 'FAQHowDoIGetPhotoStorageApp',
  FAQDoesPhotoStorageAppUsePlanData = 'FAQDoesPhotoStorageAppUsePlanData',
  FAQWhatPhonesAreCompatibleWithPhotoStorageApp = 'FAQWhatPhonesAreCompatibleWithPhotoStorageApp',
  FAQCanIUsePhotoStorageAppOnMultiplePhones = 'FAQCanIUsePhotoStorageAppOnMultiplePhones',
  FAQHowMuchStorageSpaceDoIGet = 'FAQHowMuchStorageSpaceDoIGet',
  FAQWhatContentCanBeBackedUp = 'FAQWhatContentCanBeBackedUp',
  FAQDoesPhotoStorageDowngradeImageQuality = 'FAQDoesPhotoStorageDowngradeImageQuality',
  FAQHowManyFilesFromPhotoStorageGalleryAtOnce = 'FAQHowManyFilesFromPhotoStorageGalleryAtOnce',
  FAQMaximumFileSizeForUpload = 'FAQMaximumFileSizeForUpload',
  FAQHowManyPhotosVideosWillPhotoStorageHold = 'FAQHowManyPhotosVideosWillPhotoStorageHold',
  FAQHowCanITellFilesAreBackedUp = 'FAQHowCanITellFilesAreBackedUp',
  FAQHowDoIBackupNewFiles = 'FAQHowDoIBackupNewFiles',
  FAQWillFilesStillBeVisibleOnPhoneAfterBackingUp = 'FAQWillFilesStillBeVisibleOnPhoneAfterBackingUp',
  FAQIfIDeleteFileFromPhoneWillItStayinPhotoStorage = 'FAQIfIDeleteFileFromPhoneWillItStayinPhotoStorage',
  FAQHowToDownloadBackedUpFilesToPhone = 'FAQHowToDownloadBackedUpFilesToPhone',
  FAQCanIShareDirectlyFromPhotoStorage = 'FAQCanIShareDirectlyFromPhotoStorage',
  FAQPhotoStorageAppIncreasePhoneAvailableStorage = 'FAQPhotoStorageAppIncreasePhoneAvailableStorage',
  FAQHowDoIFreeUpSpaceOnPhone = 'FAQHowDoIFreeUpSpaceOnPhone',

  // ACCT MGMT Summary page links
  MakeAdHocPaymentLinkClicked = 'MakeAdHocPaymentLinkClicked',
  ViewPlanDetailsLinkClicked = 'ViewPlanDetailsLinkClicked',
  ViewEnrolledDeviceLinkClicked = 'ViewEnrolledDeviceLinkClicked',
  ViewPaymentHistoryLinkClicked = 'ViewPaymentHistoryLinkClicked',
  ChangePaymentDateLinkClicked = 'ChangePaymentDateLinkClicked',
  ChangePaymentMethodLinkClicked = 'ChangePaymentMethodLinkClicked',
  ChangeEmailAddressLinkClicked = 'ChangeEmailAddressLinkClicked',
  ChangeNameLinkClicked = 'ChangeNameLinkClicked',
  CancelAgreementLinkClicked = 'CancelAgreementLinkClicked',
  ViewCommunicationsLinkClicked = 'ViewCommunicationsLinkClicked',
  FAQClicked = 'FAQClicked',
  WebsiteTAndCCheckboxChecked = 'WebsiteTAndCCheckboxChecked',
  WebsiteTAndCClicked = 'WebsiteTAndCClicked',
  WebsiteTAndCClickedByCheckbox = 'WebsiteTAndCClickedByCheckbox',
  WebsiteTAndCClickedDetailsPage = 'WebsiteTAndCClickedDetailsPage',
  // Static links
  CookieBannerLinkClick = 'CookieBannerLinkClicked',
  FooterContactUsLinkClicked = 'FooterContactUsLinkClicked',

  FooterMyAccountLinkClicked = 'FooterMyAccountLinkClicked',
  HeaderMyAccountLinkClicked = 'HeaderMyAccountLinkClicked',
  LandingPageManageAccountLinkClicked = 'LandingPageManageAccountLinkClicked',
  LandingPagePrivacyLinkClicked = 'LandingPagePrivacyLinkClicked',
  LandingPageContactLinkClicked = 'LandingPageContactLinkClicked',
  LandingPageTermsLinkClicked = 'LandingPageTermsLinkClicked',
  InformationBannerBrochureLinkClicked = 'InformationBannerBrochureLinkClicked',
  SignOutLinkClicked = 'SignOutLinkClicked',
  SMSReminderLinkClicked = 'SMSReminderLinkClicked',

  // Tesco
  LandingPageGetStartedClaimButtonClick = 'LandingPageGetStartedClaimButtonClick',
  UploadClaimDocumentsButtonClicked = 'UploadClaimDocumentsButtonClicked',
  // HideCookieBanner = 'HideCookieBanner',
  BrowserFingerprint = 'BrowserFingerprint',
  FooterTAndCLinkClicked = 'FooterTAndCLinkClicked',
  FooterTermsOfUseClicked = 'FooterTermsOfUseClicked',
  FooterPrivacyLinkClicked = 'FooterPrivacyLinkClicked',
  FooterTrademarkLinkClicked = 'FooterTrademarkLinkClicked',
  ShowDetailsClick = 'ShowDetailsClick',
  HideDetailsClick = 'HideDetailsClick',
  DoNotSellMyPersonalInformationLinkClicked = 'DoNotSellMyPersonalInformationLinkClicked',
}
