import * as actions from './enrollmentActions'
import { IDispatchMethods, IEnrollmentConfig, IState } from '../../interfaces'
import initStateManagement, { ICommonContext } from '../../initStateMgmt'
import { useConfig } from './enrollmentConfigSetup'

interface IContext extends ICommonContext<IState> {
  config: IEnrollmentConfig
}

let memo: {
  context: IContext
  actions: IDispatchMethods
} | null = null

const bindActions = (context: IContext): IDispatchMethods => {
  const bindContext = (f: Function) => f.bind(null, context)

  return {
    handleError: bindContext(actions.handleError),
    removeBannerError: bindContext(actions.removeBannerError),
    setLoading: bindContext(actions.setLoading),
    updateReferralParameters: bindContext(actions.updateReferralParameters),
    submitSignIn: bindContext(actions.submitSignIn),
    saveAccountInformation: bindContext(actions.saveAccountInformation),
    textReminderRedirectToSignIn: bindContext(
      actions.textReminderRedirectToSignIn
    ),
    submitTextReminder: bindContext(actions.submitTextReminder),
    updateMobileNumber: bindContext(actions.updateMobileNumber),
    updateReCaptchaToken: bindContext(actions.updateReCaptchaToken),
    submitCustomerVerification: bindContext(actions.submitCustomerVerification),
    notACustomer: bindContext(actions.notACustomer),
    updateAsset: bindContext(actions.updateAsset),
    submitDeviceSelection: bindContext(actions.submitDeviceSelection),
    updateChosenDevice: bindContext(actions.updateChosenDevice),
    updateBYODevice: bindContext(actions.updateBYODevice),
    saveIsFraudCheckError: bindContext(actions.saveIsFraudCheckError),
    submitPaymentInformation: bindContext(actions.submitPaymentInformation),
    savePaymentInformation: bindContext(actions.savePaymentInformation),
    submitPlanSelection: bindContext(actions.submitPlanSelection),
    updateSelectedPlan: bindContext(actions.updateSelectedPlan),
    submitReview: bindContext(actions.submitReview),
    submitPin: bindContext(actions.submitPin),
    agentSignIn: bindContext(actions.agentSignIn),
    getAppointments: bindContext(actions.getAppointments),
    reserveAppointment: bindContext(actions.reserveAppointment),
    beginOnboarding: bindContext(actions.beginOnboarding),
    clearAppointments: bindContext(actions.clearAppointments),
    initialize: bindContext(actions.initialize),
    sendSmartPin: bindContext(actions.sendSmartPin),
    trackEvent: bindContext(actions.trackEvent),
    confirmPhone: bindContext(actions.confirmPhone),
    signPhoneCondition: bindContext(actions.signPhoneCondition),
    checkDeviceEligibility: bindContext(actions.checkDeviceEligibility),
  }
}

const getBoundActions = (
  incomingContext: ICommonContext<IState>
): IDispatchMethods => {
  const config = useConfig() // eslint-disable-line react-hooks/rules-of-hooks
  const context = {
    ...incomingContext,
    config,
  }

  if (memo) {
    Object.assign(memo.context, context)
  } else {
    memo = { context, actions: bindActions(context) }
  }
  return memo.actions
}

const {
  connect,
  StoreProvider,
  useActions,
  useApi,
  useStore,
} = initStateManagement({
  getActions: getBoundActions,
})

export { StoreProvider, useStore, useActions, useApi, connect }
