import {
  Channel,
  IApiClientConstructorParams,
  IReferral,
  IState,
} from '../../interfaces'
import ApiClient from '../../ApiClient'

export const sourceToChannel = (source: string) => {
  switch (source) {
    case 'IHX':
      return Channel.Home
    case 'ATTR':
    case 'AR':
      return Channel.Store
    case 'ASURION':
      return Channel.Agent
    case 'ATT':
    case 'WEB':
    case 'PCC':
    default:
      return Channel.Web
  }
}

const sourceToDealerCode = (source: string) => {
  switch (source) {
    case 'ATT':
      return 'ATTCM'
    case 'PCC':
    case 'WEB':
    case 'ASURION':
      return '4RUMW'
    default:
      return ''
  }
}

const initEnrollmentStore = (): IState => {
  const params = JSON.parse(window.sessionStorage.getItem('t_p') || '')
  const source = (params.source || 'WEB').toUpperCase()
  const channel: Channel = sourceToChannel(source)

  let referral: IReferral
  let encryptedReferral: string | undefined

  if (params.referral) {
    encryptedReferral = params.referral
    referral = { dealerCode: '', repIdOrUID: '', repName: '' }
  } else {
    referral = {
      dealerCode: params.dealerCode || params.dc || sourceToDealerCode(source),
      repIdOrUID: params.username || params.rid || params.repId || '',
      repName: params.repName || params.rn || '',
    }
  }

  return {
    billingAddress: null,
    billingFirstName: '',
    billingLastName: '',
    channel,
    chosenDevice: null,
    creditCardLast4: '',
    creditCardType: '',
    deviceList: [],
    email: '',
    encryptedReferral,
    firstName: '',
    lastName: '',
    listOfPlans: [],
    mobileNumber: '',
    reCaptchaToken: '',
    referral,
    securityToken: null,
    selectedPlan: null,
    totalTries: 1,
    BYODevice: '',
    portalErrId: null,
    terminalErrMsgId: null,
    bannerMsgId: null,
    isFRMError: false,
    isTextReminderFlow: false,
    loading: false,
    onboardingAppointments: null,
  }
}

const initEnrollmentApiClient = (
  params: IApiClientConstructorParams
): ApiClient => {
  return new ApiClient(params)
}

export { initEnrollmentStore, initEnrollmentApiClient }
