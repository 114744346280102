import {
  Box,
  makeStyles,
  // TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { IBankAccountPaymentData, IBillingAddress } from '../../interfaces'
import SubmitButton from '../../sharedComponents/SubmitButton'
import InputMask from 'react-input-mask'
import { useLocale } from '../../localization'
import AddressChangeForm from './AddressChangeForm'
import { TextField as AsurionTextField } from '@soluto-private/mx-asurion-ui-react'
import { CHAR_LIMIT, REG_EXP } from '../common/constants'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const sortCodeIsValid = (sortCode: string): boolean => {
  const sortCodeRegExp = /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/
  return sortCodeRegExp.test(sortCode)
}

const accountNumberIsValid = (accountNumber: string): boolean => {
  const accountNumberRegExp = /^\d{8}(\d{1})?$/
  return accountNumberRegExp.test(accountNumber)
}

interface IPaymentModalFormProps {
  address: IBillingAddress
  loading: boolean
  nameOnBankAccount: string
  billingFirstName: string | undefined
  billingLastName: string | undefined
  textFieldProps?: any | {}
  onSaveChanges: (x: IBankAccountPaymentData) => void
}
const BankAccountChangeForm: React.FC<IPaymentModalFormProps> = ({
  address,
  loading,
  nameOnBankAccount,
  onSaveChanges,
  textFieldProps = {},
  billingFirstName = '',
  billingLastName = '',
}) => {
  const { strings } = useLocale()
  const classes = useStyles()
  // const [newAccountHolderName, setNewAccountHolderName] =
  //   useState(nameOnBankAccount)
  const [newSortCode, setNewSortCode] = useState('')
  const [maskedSortCode, setMaskedSortCode] = useState('')
  const [newAccountNumber, setNewAccountNumber] = useState('')
  const [maskedAccountNumber, setMaskedAccountNumber] = useState('')
  const [isSortCodeFocused, setIsSortCodeFocused] = useState(false)
  const [isAccountNumberFocused, setIsAccountNumberFocused] = useState(false)
  const [newAddress, setNewAddress] = useState<IBillingAddress>(address)
  const [firstName, setFirstName] = useState(billingFirstName)
  const [lastName, setLastName] = useState(billingLastName)
  const [fNameErrorMessage, setFNameErrorMessage] = useState('')
  const [lNameErrorMessage, setLNameErrorMessage] = useState('')
  const [fNameError, setFnameError] = useState(false)
  const [lNameError, setLnameError] = useState(false)
  const [isNewAddressValid, setIsNewAddressValid] = useState(true)

  const sortCodeRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const sortCode = sortCodeRef.current

    if (sortCode !== null) {
      sortCode.onfocus = () => {
        setIsSortCodeFocused(true)
      }

      sortCode.onblur = () => {
        setIsSortCodeFocused(false)
      }
    }
  }, [sortCodeRef])

  const accountNumberRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const accountNumber = accountNumberRef.current

    if (accountNumber !== null) {
      accountNumber.onfocus = () => {
        setIsAccountNumberFocused(true)
      }

      accountNumber.onblur = () => {
        setIsAccountNumberFocused(false)
      }
    }
  }, [accountNumberRef])

  // const handleAccountHolderNameChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setNewAccountHolderName(e.target.value)
  // }

  const handleSortCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaskedSortCode(
      e.target.value
        .split('')
        .map((c, i) => {
          if (c === ' ') return c

          return i < 4 ? '*' : c
        })
        .join('')
    )
    setNewSortCode(e.target.value?.replace('Backspace', ''))
  }

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaskedAccountNumber(
      e.target.value
        .split('')
        .map((c, i) => {
          if (c === ' ') return c

          return i < 4 ? '*' : c
        })
        .join('')
    )
    setNewAccountNumber(e.target.value?.replace('Backspace', ''))
  }

  const isFormValid =
    // !!newAccountHolderName &&
    sortCodeIsValid(newSortCode) &&
    accountNumberIsValid(newAccountNumber) &&
    !!newAddress.addressLine1 &&
    // Do not requre addressLine2
    !!newAddress.postalCode &&
    !!newAddress.city &&
    !!newAddress.state &&
    !!newAddress.addressUsage &&
    !!newAddress.countryCode &&
    !fNameError &&
    !lNameError &&
    isNewAddressValid

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isFormValid) {
      onSaveChanges({
        address: newAddress,
        // accountHolderName: newAccountHolderName,
        firstName,
        lastName,
        sortCode: newSortCode,
        accountNumber: newAccountNumber,
      })
    }
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFirstName((oldValue) => {
      if (!REG_EXP.EMPTY_STRING.test(value)) {
        setFnameError(true)
        setFNameErrorMessage('First name required')
      } else if (value.length > CHAR_LIMIT.FIRST_NAME_LIMIT) {
        setFnameError(true)
        setFNameErrorMessage(
          `Max ${CHAR_LIMIT.FIRST_NAME_LIMIT} characters accepted`
        )
      } else {
        setFNameErrorMessage('')
        setFnameError(false)
      }
      return value.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLastName((oldValue) => {
      if (!REG_EXP.EMPTY_STRING.test(value)) {
        setLnameError(true)
        setLNameErrorMessage('Last name required')
      } else if (value.length > CHAR_LIMIT.LAST_NAME_LIMIT) {
        setLnameError(true)
        setLNameErrorMessage(
          `Max ${CHAR_LIMIT.LAST_NAME_LIMIT} characters accepted`
        )
      } else {
        setLnameError(false)
        setLNameErrorMessage('')
      }
      return value.replace(REG_EXP.EXTRA_WHITE_SPACE, ' ')
    })
  }

  const validateNewAddress = (isValid: boolean) => {
    setIsNewAddressValid(isValid)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <Box width="100%">
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.bankAccountChangeForm.nameOfAccountHolder}
          </Typography>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
          {/*
          // @ts-ignore */}
          {/* <TextField
            data-cy={'account-holder-name'}
            value={newAccountHolderName}
            onChange={handleAccountHolderNameChange}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            style={{ marginTop: 8 }}
          /> */}
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '50%',
                padding: '8px',
              }}
            >
              <AsurionTextField
                value={firstName}
                onChange={handleFirstNameChange}
                fieldStatus={fNameError ? 'error' : 'default'}
                helperText={fNameErrorMessage}
                label="First Name"
                disabled={false}
              ></AsurionTextField>
            </div>
            <div
              style={{
                width: '50%',
                padding: '8px',
              }}
            >
              <AsurionTextField
                value={lastName}
                onChange={handleLastNameChange}
                fieldStatus={lNameError ? 'error' : 'default'}
                helperText={lNameErrorMessage}
                label="Last Name"
                disabled={false}
              ></AsurionTextField>
            </div>
          </div>
        </Box>

        <Box mt={3} width="100%">
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.bankAccountChangeForm.sortCode}
          </Typography>

          <InputMask
            formatChars={{
              '9': '[0-9]',
              a: '[A-Za-z]',
              '*': '[A-Za-z0-9]',
              '.': '.',
            }}
            // autoComplete="off"
            // autoFocus={true}
            mask={isSortCodeFocused ? '999999' : '......'}
            maskChar=""
            value={isSortCodeFocused ? newSortCode : maskedSortCode}
            onChange={handleSortCodeChange}
            {...textFieldProps}
          >
            {(inputprops: any) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              // <TextField
              //   {...inputprops}
              //   inputRef={sortCodeRef}
              //   data-cy={'sort-code'}
              //   margin="normal"
              //   variant="outlined"
              //   fullWidth={true}
              //   style={{ marginTop: 8 }}
              // />
              <AsurionTextField
                label="Sort code"
                ref={sortCodeRef}
                {...inputprops}
              />
            )}
          </InputMask>
        </Box>

        <Box mt={3} width="100%">
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.bankAccountChangeForm.accountNumber}
          </Typography>

          <InputMask
            formatChars={{
              '9': '[0-9]',
              a: '[A-Za-z]',
              '*': '[A-Za-z0-9]',
              '.': '.',
            }}
            autoComplete="off"
            mask={isAccountNumberFocused ? '99999999' : '........'}
            maskChar=""
            placeholder=""
            value={
              isAccountNumberFocused ? newAccountNumber : maskedAccountNumber
            }
            onChange={handleAccountNumberChange}
            {...textFieldProps}
          >
            {(inputprops: any) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              // <TextField
              //   {...inputprops}
              //   inputRef={accountNumberRef}
              //   data-cy={'account-number'}
              //   margin="normal"
              //   variant="outlined"
              //   fullWidth={true}
              //   style={{ marginTop: 8 }}
              // />
              <AsurionTextField
                label={strings.bankAccountChangeForm.accountNumber}
                ref={accountNumberRef}
                {...inputprops}
              />
            )}
          </InputMask>
        </Box>

        <Box style={{ width: '100%' }}>
          <AddressChangeForm
            oldAddress={address}
            loading={loading}
            onChange={setNewAddress}
            validateAddress={validateNewAddress}
          />
        </Box>

        <SubmitButton
          text={strings.misc.saveChanges}
          id={'payment-submit'}
          disabled={!isFormValid}
          loading={loading}
        />
      </form>
    </>
  )
}

export default BankAccountChangeForm
