import { Country } from '../interfaces'

const britishPound = String.fromCharCode(parseInt('000A3', 16))

export default (price: number, country?: Country): string => {
  if (!price) {
    return ''
  } else {
    switch (country) {
      case Country.Brazil: {
        return `R$${price.toFixed(2)}`
      }
      case Country.GB: {
        return `${britishPound}${price.toFixed(2)}`
      }
      // Same as default
      case Country.US:
        return `$${price.toFixed(2)}`
      default:
        return `$${price.toFixed(2)}`
    }
  }
}
