import React, { useState } from 'react'
import CreditCardChangeForm from './CreditCardChangeForm'
import PaymentMicroUI from './PaymentMicroUI'
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import {
  BillingDetails,
  IBillingAddress,
  IBankAccountPaymentData,
  ICCPaymentData,
  isCCDetails,
  IBillingContact,
  IUpdateEnrollmentRequest,
} from '../../interfaces'
import { useStore, useActions } from '../state/accountStateMgmt'
import BankAccountChangeForm from './BankAccountChangeForm'
import { useLocale } from '../../localization'
import { useConfig } from '../state/accountConfigSetup'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

interface IPaymentModalContentProps {
  nameOnCard: string
  billingDetails: BillingDetails
  address: IBillingAddress
  onClose: () => void
  billingContact?: IBillingContact
}
const PaymentModalContent: React.FC<IPaymentModalContentProps> = ({
  nameOnCard,
  billingDetails,
  address,
  onClose,
  billingContact,
}) => {
  const classes = useStyles()
  const { loading, subscriptionNumber } = useStore()
  const { updatePaymentMethod, getSecurityToken, updateCCDetailsForMicroUi } =
    useActions()
  const { PARTNER, TESCO_MICRO_UI_BPID, TESCO_MICRO_UI_FEATURE_FLAG } =
    useConfig()
  const { strings } = useLocale()
  const [securityToken, setSecurityToken] = useState('')
  const ccBillingDetails = isCCDetails(billingDetails)

  const enrollmentAddress = {
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    state: address?.state || '',
    countryCode: address.countryCode,
    postalCode: address.postalCode,
    addressUsage: 'ENROLLMENT',
  }

  const getSecurityTokenForCC = async (): Promise<void> => {
    const tokenResponse = await getSecurityToken({
      txnType: 'CC',
      subscriptionNumber,
      billingProgramId: TESCO_MICRO_UI_BPID,
    })
    setSecurityToken(tokenResponse.token)
  }

  const handleCCUpdate = (data: ICCPaymentData) => {
    updatePaymentMethod(data).finally(onClose)
  }

  const handleBankAccountUpdate = (data: IBankAccountPaymentData) => {
    updatePaymentMethod(data).finally(onClose)
  }

  const handleNewCCUpdate = (data: IUpdateEnrollmentRequest) => {
    updateCCDetailsForMicroUi(data).finally(onClose)
  }

  const renderMicroUI = () => {
    if (securityToken !== '') {
      return (
        <PaymentMicroUI
          billingProgramId={TESCO_MICRO_UI_BPID}
          securityToken={securityToken}
          enrollmentAddress={enrollmentAddress}
          onPaymentSuccess={handleNewCCUpdate}
          onFailure={onClose}
        />
      )
    }
  }

  const renderOldCCForm = () => {
    if (isCCDetails(billingDetails)) {
      return (
        <CreditCardChangeForm
          //nameOnCard={nameOnCard}
          billingFirstName={billingContact?.firstName}
          billingLastName={billingContact?.lastName}
          ccType={billingDetails.cardType}
          address={address}
          onSaveChanges={handleCCUpdate}
          loading={loading}
        />
      )
    }
  }

  const renderCCForm = () => {
    if (ccBillingDetails) {
      if (TESCO_MICRO_UI_FEATURE_FLAG && PARTNER === 'Tesco') {
        if (securityToken === '') {
          getSecurityTokenForCC()
        }
        if (securityToken) {
          return renderMicroUI()
        } else {
          return (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )
        }
      } else {
        return renderOldCCForm()
      }
    }
  }

  return (
    <>
      <Box pb={3}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.paymentModalContent.changePaymentMethod}
        </Typography>
      </Box>

      <Box>
        {ccBillingDetails ? (
          renderCCForm()
        ) : (
          <BankAccountChangeForm
            address={address}
            nameOnBankAccount={billingDetails.name}
            onSaveChanges={handleBankAccountUpdate}
            loading={loading}
            billingFirstName={billingContact?.firstName}
            billingLastName={billingContact?.lastName}
          />
        )}
      </Box>

      <Box
        className={classes.link}
        onClick={onClose}
        textAlign="center"
        fontWeight={500}
        mt={3}
      >
        {strings.misc.cancel}
      </Box>
    </>
  )
}

export default PaymentModalContent
