import React, { useContext } from 'react'
import ApiClient from '../../../generic/ApiClient'

const ApiContext = React.createContext<ApiClient>(
  (undefined as unknown) as ApiClient
)
const ApiProvider = ApiContext.Provider

const useApi = (): ApiClient => {
  return useContext(ApiContext)
}

export { ApiProvider, useApi }
