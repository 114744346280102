import { EventType } from './events'
import React, { ReactNode, ReactText } from 'react'

// LINK PROPS
export type LinkColorType =
  | 'inherit'
  | 'initial'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error'
  | undefined
export type LinkUnderlineType = 'none' | 'always' | 'hover' | undefined
export type LinkVariantType =
  | 'inherit'
  | 'button'
  | 'overline'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body2'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'srOnly'
  | undefined

export interface ILinkProps {
  handler?: () => void
  trackEvent: (eventType: EventType, props?: object) => void
  eventType?: EventType
  linkText: string | ReactNode
  style?: React.CSSProperties
  id?: string
  linkTarget: string
  className?: string
  color?: LinkColorType
  underline?: LinkUnderlineType
  'data-cy'?: string
  variant?: LinkVariantType
  component?: React.ElementType
  external?: LinkOpenOptions
  rel?: string
  width?: ReactText[]
}

export enum LinkOpenOptions {
  Internal = '',
  Scroll = 'scroll',
  NewTab = '_blank',
  SameTab = '_self',
}

export interface ICreateSmartLinkResponse {
  url: string
}
