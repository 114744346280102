import { Theme } from 'styled-system'

export const black = '#000000'
export const white = '#FFFFFF'
export const tescoBlue = '#00539F'
export const tescoPulseBlue = '#00ADEF;'
export const attDark = '#1D2329'
export const deepGray = '#A6AAAF'
export const deepestGray = '#333F48'
export const lightGray = '#424242'
export const footerGray = 'hsl(213, 5%, 47%)'
export const error = '#B00020'

const theme: Theme = {
  colors: {
    primary: tescoBlue,
    secondary: tescoPulseBlue,
    text: '#000',
    background: '#fff',
    muted: '#f6f6f9',
    gray: '#D5D6DA',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
    black,
    lightGray,
    deepGray,
    deepestGray,
    footerGray,
    error,
  },
  fonts: {
    body: 'TESCO Modern-Light',
    heading: 'TESCO Modern-Bold',
    monospace: 'TESCO Modern-Light',
  },
  fontSizes: [12, 14, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: '135%',
    heading: '100%',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48,
  },
  radii: {
    default: 4,
    circle: 99999,
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
    },
    link: {
      color: 'primary',
    },
    footerLink: {
      color: 'footerGray',
      textDecoration: 'none',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  buttons: {
    primary: {
      fontSize: 3,
      color: 'background',
      bg: 'primary',
      borderRadius: 'default',
    },
    secondary: {
      variant: 'buttons.primary',
      color: white,
      bg: tescoPulseBlue,
    },
    white: {
      variant: 'buttons.primary',
      color: 'white',
      bg: 'transparent',
    },
    // Just a container for something else
    unstyled: {
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      width: 'auto !important',
      height: 'auto !important',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
}

export default theme
