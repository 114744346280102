import { Country, Partner } from '../generic/interfaces'

const partnersAndCountries: Record<Partner, Country> = {
  [Partner.ATT]: Country.US,
  [Partner.Tesco]: Country.GB,
}

export default (partner: Partner): Country => {
  return partnersAndCountries[partner]
}
