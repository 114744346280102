import React, { CSSProperties } from 'react'
import amex from '../../sharedAssets/amex.svg'
import creditcard from '../../sharedAssets/creditcard.svg'
import discover from '../../sharedAssets/discover.svg'
import mastercard from '../../sharedAssets/mc.svg'
import visa from '../../sharedAssets/visa.svg'
import startCase from 'lodash/startCase'
import { useLocale } from '../../localization'

const cardImageStyle: CSSProperties = {
  height: 25,
  marginRight: 3,
  position: 'relative',
  top: 7,
  width: 25,
}

export const formatCardType = (cardType: string) => {
  const lowercased = cardType.toLowerCase()

  return lowercased === 'master' ? 'Mastercard' : startCase(lowercased)
}

export const getCardImageSrc = (type: string) => {
  const t = type.toLowerCase()
  switch (t) {
    case 'amex': {
      return amex
    }

    case 'discover': {
      return discover
    }

    case 'mastercard':
    case 'master': {
      return mastercard
    }

    case 'visa':
    case 'visacredit': {
      return visa
    }

    case 'mccredit':
    case 'mc': {
      return mastercard
    }

    default: {
      return creditcard
    }
  }
}

export default function ({
  creditCardType,
  text,
  visible,
}: {
  creditCardType: string
  text: boolean
  visible: boolean
}) {
  const { strings } = useLocale()
  const cardImageSrc = getCardImageSrc(creditCardType)
  const formattedCardType = formatCardType(creditCardType)

  const cardImage = (
    <img
      src={cardImageSrc}
      alt={strings.misc.creditCardIcon}
      style={cardImageStyle}
    />
  )

  const cardText = text ? formattedCardType : ''

  return visible ? (
    <span>
      {cardImage} <span style={{ fontWeight: 'bold' }}>{cardText}</span>
    </span>
  ) : (
    <div />
  )
}
