import React from 'react'
import {
  Button as RebassButton,
  ButtonProps as RebassButtonProps,
  Flex,
} from 'rebass'
import { CircularProgress } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'

interface ButtonProps extends RebassButtonProps {
  isLoading?: boolean
  disabled?: boolean
  showArrow?: boolean
}
const Button: React.FC<ButtonProps> = ({
  isLoading,
  disabled,
  showArrow,
  ...otherProps
}) => {
  return (
    <>
      <RebassButton
        width="100%"
        disabled={disabled}
        {...otherProps}
        bg={disabled ? 'deepGray' : otherProps.bg}
        sx={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          borderRadius: 9999,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          cursor: disabled ? 'not-allowed' : 'pointer',
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          position: 'relative',
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          borderColor: otherProps.color,
          ...otherProps.sx,
        }}
        minHeight={48}
      >
        {otherProps.children}

        {showArrow && (
          <Flex
            alignItems="center"
            sx={{ position: 'absolute', top: 0, right: 20, bottom: 0 }}
          >
            <ArrowForward style={{ color: 'inherit' }} />
          </Flex>
        )}

        {isLoading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: 'rgba(0, 0, 0, 0.3)',
              borderRadius: 9999,
            }}
          >
            <CircularProgress size={24} style={{ color: 'white' }} />
          </Flex>
        )}
      </RebassButton>
    </>
  )
}

export default Button
