import { Typography, Theme } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import MainPaper from '../generic/accountMgmt/components/MainPaper'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  instructionText: {
    lineHeight: 1.4,
    marginTop: theme.spacing(2),
  },
}))

const DownForMaintenance: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MainPaper>
        <Typography variant="h4" component="h1">
          We're undergoing some scheduled maintenance.
        </Typography>

        <Typography
          variant="subtitle1"
          component="p"
          className={classes.instructionText}
        >
          Sorry for the inconvenience. We'll be back up and running shortly.
        </Typography>
      </MainPaper>
    </div>
  )
}

export default DownForMaintenance
