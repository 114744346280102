import moment from 'moment'
import { BillingDetails } from '../interfaces'

// billDate is the actual day that they are going to take the payment. It's
// just a number of the day of the month. nextInvoiceDate is the date the next
// invoice will be created. To get the exact year, month, and day that the next
// payment will be taken out, get the year and month from nextInvoiceDate,
// then get the day from billDate.

// UPDATE: The above info was what Rob and Laura both said but I (Mason) couldn't
// recreate it. For me, billDate and nextInvoiceDate always have the same day of
// the month no matter what, so the below code could be unnecessary now. Maybe
// they fixed a downstream API?

export default (billingDetails: BillingDetails) => {
  const { nextInvoiceDate, billDate } = billingDetails

  // Create a moment based on the nextInvoiceDate then set the day of the month
  // to the billDate. Note that I've been unable to see those days of the month
  // diverge but left this code in just in case.
  const nextBillDate = moment(nextInvoiceDate, 'YYYY-MM-DD').date(billDate)

  return nextBillDate
}
