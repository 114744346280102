import { FormControl, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  Suggestion,
} from 'react-places-autocomplete'
import { useLocale } from '../../localization'
import { useConfig } from '../state/accountConfigSetup'
import { TextField as AsurionTextField } from '@soluto-private/mx-asurion-ui-react'

const useStyles = makeStyles(() => ({
  addressInputContainer: {
    position: 'relative',
  },
  dropdownContainer: {
    backgroundColor: 'white',
    border: 'solid 1px lightGrey',
    borderRadius: '5px 5px 5px 5px',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  suggestion: {
    borderBottom: 'solid 1px lightGrey',
    color: 'grey',
    cursor: 'pointer',
    padding: '10px',
  },
  suggestionActive: {
    color: 'inherit',
    cursor: 'pointer',
  },
}))

interface IAddressInputProps {
  error: boolean
  handleAutoAddressEntry: (address: google.maps.GeocoderResult) => void
  isDirty?: (x: boolean) => void
}

const AddressInput = (props: IAddressInputProps) => {
  const { strings } = useLocale()
  const [addressString, setAddressString] = useState('')
  const { error, handleAutoAddressEntry } = props
  const classes = useStyles()
  const { USE_BRITISH_ADDRESSING } = useConfig()
  const handleSelect = (address: string): void => {
    geocodeByAddress(address)
      .then((results) => {
        const addressObj = results && results[0]
        if (!addressObj) {
          return
        }

        if (props.isDirty) {
          props.isDirty(false)
        }

        // Set local state for display only
        setAddressString(addressObj.formatted_address)
        // Set address state in parent
        handleAutoAddressEntry(addressObj)
      })
      .catch((err) => console.error('Error', err))
  }

  const handleChange = (addressStr: string): void => {
    props.isDirty && props.isDirty(true)
    const cleanAddress = addressStr?.replace('Backspace', '')
    if (cleanAddress.length > 1 && !addressString) {
      handleSelect(cleanAddress)
    }
    setAddressString(cleanAddress)
  }

  const SuggestionItem = (
    suggestion: Suggestion,
    getSuggestionItemProps: (s: Suggestion, o: { className: string }) => any
  ) => (
    <div
      {...getSuggestionItemProps(suggestion, {
        className: classNames(
          suggestion.active && classes.suggestionActive,
          classes.suggestion
        ),
      })}
    >
      <span>{suggestion.description}</span>
    </div>
  )

  return (
    <Typography component="div">
      <PlacesAutocomplete
        value={addressString}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: {
            country: USE_BRITISH_ADDRESSING ? 'uk' : 'us',
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={classes.addressInputContainer}>
            <FormControl margin="normal" required={true} fullWidth={true}>
              {/* <TextField
                error={error}
                helperText={`${
                  error ? strings.addressInput.mustBeSelected : ''
                }`}
                variant="outlined"
                placeholder={strings.addressInput.billingAddress}
                name="address"
                id="address"
                aria-labelledby="addressLabel"
                {...getInputProps({
                  'aria-activedescendant': undefined,
                  className: 'location-search-input',
                })}
              /> */}
              <AsurionTextField
                label={strings.addressInput.billingAddress}
                id="address"
                fieldStatus={error ? 'error' : 'default'}
                helperText={error ? strings.addressInput.mustBeSelected : ''}
                {...getInputProps({
                  'aria-activedescendant': undefined,
                  className: 'location-search-input',
                })}
              ></AsurionTextField>
            </FormControl>

            {!!suggestions.length && (
              <div className={classes.dropdownContainer}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) =>
                  SuggestionItem(suggestion, getSuggestionItemProps)
                )}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </Typography>
  )
}

export default AddressInput
