import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import closeIcon from '../../sharedAssets/close-icon.png'
import { useActions, useStore } from '../state/accountStateMgmt'
import { useConfig } from '../state/accountConfigSetup'
import Theme from '../../../att/accountMgmt/theme'
import { useLocale } from '../../localization'

const useStyles = makeStyles((theme: typeof Theme) => ({
  banner: {
    transition: 'height 0.4s ease-out, background-color 0.4s ease',
  },
  none: {
    height: '0px !important',
  },
  error: {
    backgroundColor: '#F8D7DA',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: '#C3F0CB',
  },
}))

const Banner = () => {
  const { APP_MAX_WIDTH } = useConfig()
  const { bannerMsg } = useStore()
  const { setBanner } = useActions()
  const { strings } = useLocale()
  const classes = useStyles()
  const bannerClass = bannerMsg ? classes[bannerMsg.type] : classes.none

  return (
    <Box
      className={`${bannerClass} ${classes.banner}`}
      px={3}
      py={bannerMsg ? 2 : 0}
      textAlign={'center'}
      id="banner"
    >
      <Box
        marginLeft="auto"
        marginRight="auto"
        maxWidth={APP_MAX_WIDTH}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography component="div" variant="body2">
          {bannerMsg && bannerMsg.msg}
        </Typography>

        {bannerMsg && (
          <Box
            component="button"
            onClick={() => setBanner()}
            position="absolute"
            right="-16px"
            id={'close-banner'}
            className={bannerClass}
            style={{ cursor: 'pointer', border: 'none' }}
          >
            <img src={closeIcon} alt={strings.misc.closeIcon} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Banner
