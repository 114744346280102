import initConfig from '../../../generic/initConfig'
import { Partner } from '../../../generic/interfaces'

export interface TescoMarketingConfig {
  API_BASE_URL: string
  APP_MAX_WIDTH: number
  APP_MIN_WIDTH: number
  ENVIRONMENT: string
  ENABLE_SIGNATURE_FOR_TOUCH_DEVICES: boolean
  ALLOWED_CC_TYPES: string
  CLAIM_DOCS_URL: string
  CUST_SERVICE_NUMBER: string
  BYOD_ID: string
  MIXPANEL_ENABLED: boolean
  PARTNER: Partner
  RECAPTCHA_KEY: string
  SPEEDCLAIMS_URL: string
  COOKIE_POLICY_LINK: string
  TESCO_MICRO_UI_BPID: string
  TESCO_MICRO_UI_FEATURE_FLAG: boolean
}

const { ConfigProvider, useConfig } = initConfig<TescoMarketingConfig>()

export { ConfigProvider, useConfig }
