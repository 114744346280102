import {
  BannerMsgId,
  PortalErrId,
  PortalErrorLookup,
  TerminalErrMsgId,
} from '../interfaces'

const portalErrorLookup: PortalErrorLookup = {
  [PortalErrId.INIT_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.RECAPTCHA_1]: {
    triesAllowed: 1,
    defaultErrMsg: BannerMsgId.B,
    finalErrMsg: BannerMsgId.B,
  },
  [PortalErrId.RECAPTCHA_2]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.ELIGIBILITY_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.ELIGIBILITY_2]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.NOT_A_CUSTOMER,
    finalErrMsg: TerminalErrMsgId.NOT_A_CUSTOMER,
  },
  [PortalErrId.ELIGIBILITY_3]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.ALREADY_COVERED,
    finalErrMsg: TerminalErrMsgId.ALREADY_COVERED,
  },
  [PortalErrId.ELIGIBILITY_4]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.TBD2,
    finalErrMsg: TerminalErrMsgId.TBD3,
  },
  [PortalErrId.ELIGIBILITY_5]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.PAST_ELIGIBILITY_WINDOW,
    finalErrMsg: TerminalErrMsgId.PAST_ELIGIBILITY_WINDOW,
  },
  [PortalErrId.SMART_PIN_1]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.SUCCESS_1,
    finalErrMsg: TerminalErrMsgId.F,
  },
  [PortalErrId.SMART_PIN_2]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.D,
    finalErrMsg: TerminalErrMsgId.D,
  },
  [PortalErrId.SMART_PIN_3]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.E,
    finalErrMsg: TerminalErrMsgId.F,
  },
  [PortalErrId.SMART_PIN_4]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.G,
    finalErrMsg: TerminalErrMsgId.F,
  },
  [PortalErrId.CC_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.CC_2]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.CARD_DECLINED,
    finalErrMsg: TerminalErrMsgId.CARD_DECLINED,
  },
  [PortalErrId.CC_3]: {
    triesAllowed: 3,
    defaultErrMsg: BannerMsgId.CARD_DECLINED,
    finalErrMsg: TerminalErrMsgId.CARD_DECLINED,
  },
  [PortalErrId.FRM_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.ENROLLMENT_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.H,
    finalErrMsg: TerminalErrMsgId.H,
  },
  [PortalErrId.SMS_1]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.I,
    finalErrMsg: TerminalErrMsgId.I,
  },
  [PortalErrId.AGENT_1_A]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_1_A,
    finalErrMsg: TerminalErrMsgId.AGENT_1_A,
  },
  [PortalErrId.AGENT_1_B]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_1_B,
    finalErrMsg: TerminalErrMsgId.AGENT_1_B,
  },
  [PortalErrId.AGENT_1_C]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_1_C,
    finalErrMsg: TerminalErrMsgId.AGENT_1_C,
  },
  [PortalErrId.AGENT_1_D]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_1_D,
    finalErrMsg: TerminalErrMsgId.AGENT_1_D,
  },
  [PortalErrId.AGENT_2]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_2,
    finalErrMsg: TerminalErrMsgId.AGENT_2,
  },
  [PortalErrId.AGENT_3]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_3,
    finalErrMsg: TerminalErrMsgId.AGENT_3,
  },
  [PortalErrId.AGENT_4]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_4,
    finalErrMsg: TerminalErrMsgId.AGENT_4,
  },
  [PortalErrId.AGENT_5_A]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_5_A_DEFAULT,
    finalErrMsg: TerminalErrMsgId.AGENT_5_A_FINAL,
  },
  [PortalErrId.AGENT_5_B]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_5_B_DEFAULT,
    finalErrMsg: TerminalErrMsgId.AGENT_5_B_FINAL,
  },
  [PortalErrId.AGENT_6]: {
    triesAllowed: 1,
    defaultErrMsg: TerminalErrMsgId.AGENT_6,
    finalErrMsg: TerminalErrMsgId.AGENT_6,
  },
  [PortalErrId.SUCCESS_2]: {
    triesAllowed: 1,
    defaultErrMsg: BannerMsgId.SUCCESS_2,
    finalErrMsg: BannerMsgId.SUCCESS_2,
  },
  [PortalErrId.SUCCESS_3]: {
    triesAllowed: 1,
    defaultErrMsg: BannerMsgId.SUCCESS_3,
    finalErrMsg: BannerMsgId.SUCCESS_3,
  },
  [PortalErrId.ONBOARDING_1]: {
    triesAllowed: 1,
    defaultErrMsg: BannerMsgId.ONBOARDING_1,
    finalErrMsg: BannerMsgId.ONBOARDING_1,
  },
}

export default portalErrorLookup
