import React, { useCallback, useRef } from 'react'
import { Box, BoxProps } from 'rebass'
import MagnifyingGlassIcon from '../../assets/magnifying-glass-white.svg'
import InputMask, { Instance } from 'inputmask'

// maskPlaceholder:
// 9 : numeric
// a : alphabetical
// * : alphanumeric

interface InputProps extends BoxProps {
  showMagnifyingGlass?: boolean
  isUnderlined?: boolean
  errorMsg?: string
  mask?: string
}
const Input: React.FC<InputProps> = ({
  showMagnifyingGlass,
  isUnderlined,
  errorMsg,
  mask,
  ...otherProps
}) => {
  const maskOptions = {
    mask,
    jitMasking: true,
  }

  const inputMaskRef = useRef<Instance | null>(null)
  const inputRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        inputMaskRef.current = new InputMask(maskOptions)
        inputMaskRef.current.mask(node)
      }
    },
    [maskOptions]
  )

  return (
    <>
      <Box
        ref={inputRef}
        as="input"
        type="text"
        tx="forms"
        variant="input"
        className="lighterPlaceholder"
        {...otherProps}
        sx={{
          outline: 'none',
          display: 'block',
          width: '100%',
          height: 48,
          p: 2,
          pl: showMagnifyingGlass ? '46px' : 3,
          appearance: 'none',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          borderTop: isUnderlined ? 'none' : '1px solid',
          borderRight: isUnderlined ? 'none' : '1px solid',
          borderBottom: '1px solid',
          borderLeft: isUnderlined ? 'none' : '1px solid',
          color: errorMsg ? 'error' : 'inherit',
          bg: 'transparent',
          background: showMagnifyingGlass
            ? `url(${MagnifyingGlassIcon}) no-repeat scroll 12px 12px`
            : '',
          '&:focus': {
            borderColor: errorMsg ? 'error' : 'primary',
            borderWidth: '2px',
          },
          transition: 'all .2s ease-in-out',
          ...otherProps.sx,
        }}
      />

      {errorMsg && (
        <Box
          data-cy="inputErrorMsg"
          as="span"
          mt={1}
          px={2}
          py={1}
          bg="white"
          color="error"
          sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        >
          {errorMsg}
        </Box>
      )}
    </>
  )
}

export default Input
