import React from 'react'
import { Theme as MaterialUITheme } from '@material-ui/core'
import { IPortalErr, PortalErrId } from './errors'

export enum Channel {
  Web = 'AEP-WEB',
  Store = 'AEP-STORE',
  Agent = 'AEP-AGENT',
  Home = 'AEP-HOME',
}

export interface IDevice {
  color: string
  id: string
  make: string
  model: string
  name: string
  imei?: string
  imagePath?: string
}

export enum Country {
  Brazil = 'Brazil',
  GB = 'GB',
  US = 'US',
}

export interface ISecurityToken {
  encryptionKey: string
  token: string
  returnCode: string
}

export interface IBillingAddress {
  addressLine1: string
  addressLine2?: string
  city: string
  state?: string
  countryCode: string
  postalCode: string
  addressUsage: string
}

export enum BillingStatus {
  Current = 'Current',
  PastDue = 'Past Due',
  Pending = 'Pending',
}

export function isCCPayment(
  pd: ICCPaymentData | IBankAccountPaymentData
): pd is ICCPaymentData {
  return (pd as ICCPaymentData).creditCardNumber !== undefined
}

export type PaymentData = ICCPaymentData | IBankAccountPaymentData

export interface ICCPaymentData {
  address: IBillingAddress
  creditCardNumber: string
  cvv: string
  expDate: string
  firstName: string
  lastName: string
  ccType: string
}

export interface IBankAccountPaymentData {
  address: IBillingAddress
  // accountHolderName: string
  firstName: string
  lastName: string
  sortCode: string
  accountNumber: string
}

export interface IBillingContact {
  firstName: string
  middleInitial: string
  lastName: string
  companyName: string
  address1: string
  address2: string
  locality: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  workPhone: string
  cellPhone: string
  email: string
}

export function isCCDetails(
  bd: ICCDetails | IBankAccountDetails
): bd is ICCDetails {
  return (bd as ICCDetails).cardNum !== undefined
}

export type BillingDetails = ICCDetails | IBankAccountDetails

export interface ICCDetails {
  billDate: number
  billingProfileId: string
  cardExp: string // mm-yyyy
  cardNum: string // ****
  cardType: string // VISA
  name: string // first last
  nextInvoiceDate: string // yyyy-mm-dd
}

export interface IBankAccountDetails {
  acctNum: string
  billDate: number
  billingProfileId: string
  name: string // first last
  nextInvoiceDate: string // yyyy-mm-dd
  routingNum: string
}

export interface IInvoice {
  invoiceId: string
  paymentTransactions: unknown[]
  status: string
  statusReason: string
  refundStatus?: string
  actualProcessDate?: string
  billingDate: string
  billingCycleStartDate: string
  billingCycleEndDate: string
  reconcileDate?: string
  scheduledProcessDate: string
  transactionType: string
  createdOn: string
  paymentResponseCode?: string
  invoiceAmount: number
  balanceRemaining: number
  invoiceLineItems: IInvoiceLineItem[]
}

export interface IInvoiceLineItem {
  feeType: string
  lineItemId: string
  amount: number
}

export enum Locale {
  English = 'en',
  EnglishGB = 'en-gb',
  Spanish = 'es',
  Portuguese = 'pt',
}

export interface Banner {
  type: BannerMsgType
  msg: React.ReactNode
}

export enum BannerMsgType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export enum Partner {
  ATT = 'AT&T',
  Tesco = 'Tesco',
}

export interface ICCErrors {
  creditCardNumber?: string
  expirationDate?: string
  nameOnCard?: string
  securityCode?: string
}

export interface ICCInfo {
  creditCardNumber: string
  expMonth: number | null
  expYear: number | null
  // nameOnCard: string
  firstName: string
  lastName: string
  securityCode: string
  creditCardType: string
}

export interface ITimezone {
  names: string[] // The actual timezone names that have been combined
  formattedName: string
}

export enum SmartPINDeliveryMethod {
  SMS = 'sms',
  Email = 'email',
}

export type PortalErrorLookup = { [e in PortalErrId]: IPortalErr }

export type DynamicConfig = Partial<IEnrollmentConfig>

export interface IEnrollmentConfig {
  ACCOUNT_PATH: string
  ALLOWED_CC_TYPES: string
  API_BASE_URL: string
  APP_MAX_WIDTH: number
  APP_MIN_WIDTH: number
  BG_ENDPOINT: string
  BYOD_ID: string
  CLIENT_CHANNEL_ID: string
  CUST_SERV_PHONE_NO: string
  DEFAULT_PLAN_SKU: string
  DISABLE_CAPTCHA: boolean
  DISABLE_REFRESH: boolean
  ENROLL_PATH: string
  MIXPANEL_ENABLED: boolean
  PROTECH_LINK: string
  RECAPTCHA_KEY: string
  THEME: MaterialUITheme
  PORTAL_ERROR_LOOKUP: PortalErrorLookup

  DEFAULT_LOCALE: Locale
  COUNTRY_CALLING_CODE: string
  PARTNER: Partner
  IS_DOWN_FOR_MAINTENANCE: boolean
  BILLING_PROGRAM_ID: string
  SHOW_TEXT_MESSAGE_DISCLAIMER: boolean

  // A/B testing
  SHOW_DEVICE_HEALTH: boolean
  TESCO_MICRO_UI_BPID?: string
  TESCO_MICRO_UI_FEATURE_FLAG?: boolean
}

export interface IAccountMgmtConfig {
  ACCOUNT_PATH: string
  ALLOWED_CC_TYPES: string
  API_BASE_URL: string
  APP_MAX_WIDTH: number
  APP_MIN_WIDTH: number
  BG_ENDPOINT: string
  BYOD_ID: string
  CUST_SERV_PHONE_NO: string
  DISABLE_CAPTCHA: boolean
  DISABLE_REFRESH: boolean
  ENROLL_PATH: string
  MIXPANEL_ENABLED: boolean
  PROTECH_LINK: string
  RECAPTCHA_KEY: string
  THEME: MaterialUITheme
  DEFAULT_LOCALE: Locale
  SHOW_ENROLL_NOW_CTA: boolean
  SHOW_TEXT_MESSAGE_DISCLAIMER: boolean
  SHOW_COMMUNICATIONS_PANEL: boolean
  SHOW_CANCEL_PLAN_PANEL: boolean
  SHOW_CANCELLATION_REASONS: boolean
  USE_BRITISH_ADDRESSING: boolean
  MDN_MASK: string
  COUNTRY_CALLING_CODE: string
  PARTNER: Partner
  IS_DOWN_FOR_MAINTENANCE: boolean
  BILLING_PROGRAM_ID: string
  ALLOW_NAME_CHANGE: boolean
  SPEEDCLAIMS_URL: string
  COMMS_URL: string
  ALLOW_MAKEUP_PAYMENT: boolean
  ALLOW_EMAIL_SMART_PIN: boolean
  TESCO_MICRO_UI_BPID: string
  TESCO_MICRO_UI_FEATURE_FLAG: boolean
}

export interface IStringsAndLocale<LocalizedStringsType> {
  strings: LocalizedStringsType
  locale: Locale
}
export type UseLocaleType<LocalizedStringsType> =
  () => IStringsAndLocale<LocalizedStringsType>
export type LocaleProviderType = React.ProviderExoticComponent<
  React.ProviderProps<Locale>
>
export type LocalizedStringsLookup<LocalizedStringsType> = Partial<
  Record<Locale, LocalizedStringsType>
>
