import { AxiosInstance, AxiosRequestConfig, Method } from 'axios'
import axios from 'axios'
import {
  isError,
  IBasicPayload,
  IBeginEnrollmentRequest,
  IBeginEnrollmentRequestPayload,
  IBeginEnrollmentResponse,
  IClientOfferServiceResponse,
  IDeviceEligibilityPayload,
  IDeviceEligibilityRequest,
  ISendSmartPinPayload,
  ISendSmartPinRequest,
  IDeviceEligibilityResponse,
  ITrackEventPayload,
  IVerifyPinRequest,
  IVerifyPinPayload,
  IVerifyPinResponse,
  IFetchAccountInfoPayload,
  IFetchAccountInfoRequest,
  IFetchAccountInfoResponse,
  IFindAgreementResponse,
  ISecurityTokenResponse,
  IUpdateBillDateRequest,
  IUpdateBillDatePayload,
  IGetAppointmentsPayload,
  IGetAppointmentsResponse,
  IGetAppointmentsRequest,
  IReserveAppointmentPayload,
  IReserveAppointmentResponse,
  IReserveAppointmentRequest,
  VerificationResult,
  Channel,
  EventType,
  IUpdateEnrollmentRequest,
  IBillingInfoResponse,
  IUpdateEnrollmentRequestPayload,
  IUpdateEnrollmentResponse,
  IMakeAdhocPaymentRequest,
  IMakeAdhocPaymentResponse,
  IMakeAdhocPaymentPayload,
  ICreateSmartLinkResponse,
  IFinishEnrollmentPayload,
  IFinishEnrollmentRequest,
  ISMSGatewayPayload,
  ISMSGatewayResponse,
  ITextReminderRequest,
  IInitData,
  IPlan,
  Partner,
  IFraudAPIPayload,
  IFraudRequest,
  IFraudRequestResponse,
  IApiClientConstructorParams,
  ICancelPlanResponse,
  ICancelPlanPayload,
  ICancelPlanRequest,
  IDeductibleLookupPayload,
  IDeductibleLookupResponse,
  IDeductibleLookupRequest,
  IAssetAttributesPayload,
  IAssetAttributesResponse,
  IAssetAttributesRequest,
  GetSecurityTokenPayload,
  ICreateNotePayload,
  ICreateNoteResponse,
  INoteData,
} from './interfaces'
import { buildDeviceList } from './sharedHelpers/buildDeviceList'
import trackingProps from './sharedHelpers/trackingProps'
import Fingerprint2, { Component } from 'fingerprintjs2'
import { TXN_TYPE } from './accountMgmt/common/constants'

interface InitStatus {
  promise: Promise<IInitData>
  resolve: (data: IInitData) => void
  reject: (msg: string) => void
  isResolved: boolean
}
declare global {
  interface Window {
    dataLayer: {
      push: (eventData: Record<string, any>) => void
    }
    gtag?: (
      type: string,
      name: string,
      options: {
        callback?: (variation: string, experimentId: string) => void
        remove?: boolean
        message?: string
        planId?: string
      }
    ) => void
  }
}

export default class ApiClient {
  private readonly apiBaseUrl: string
  private readonly channel: Channel
  private readonly isTrackingEnabled: boolean
  private token: string | null
  private correlationId: string
  private interactionLineId: string
  private partner: Partner | null
  private axiosInstance: AxiosInstance
  private initialization: InitStatus
  private shouldReinitializeOnPageChange: boolean

  constructor({
    apiBaseUrl,
    channel,
    isTrackingEnabled,
    token,
  }: IApiClientConstructorParams) {
    this.apiBaseUrl = apiBaseUrl
    this.isTrackingEnabled = isTrackingEnabled
    this.channel = channel
    this.token = token || null
    this.correlationId = ''
    this.interactionLineId = ''
    this.partner = null
    this.shouldReinitializeOnPageChange = false
    this.axiosInstance = axios.create({ baseURL: this.apiBaseUrl })

    const initialization: InitStatus = { isResolved: false } as InitStatus

    initialization.promise = new Promise(function (resolve, reject) {
      initialization.reject = reject
      initialization.resolve = (data) => {
        initialization.isResolved = true
        resolve(data)
      }
    })

    this.initialization = initialization
  }

  public initialize(partner: Partner, cid?: string): Promise<IInitData | void> {
    this.partner = partner
    return this.initializeApiCall(this.channel, cid).then(
      (initData: IInitData) => {
        if (isError(initData)) {
          const rejectReason = `Initialization error: ${JSON.stringify(
            initData.error,
            null,
            2
          )}`

          this.initialization.reject(rejectReason)
        }

        this.correlationId = initData.correlationId
        this.interactionLineId = initData.interactionLineId

        const cb = (components: Component[]) => {
          // noinspection JSIgnoredPromiseFromCall
          this.trackEventApiCall({
            eventType: EventType.BrowserFingerprint,
            props: { components, referrer: window.document.referrer },
            interactionLineId: this.interactionLineId,
            correlationId: this.correlationId,
            partner,
            channel: this.channel,
          })
        }
        Fingerprint2.get(
          {
            excludes: {
              canvas: true,
              webgl: true,
              webglVendorAndRenderer: true,
              doNotTrack: true,
            },
          },
          cb.bind(this)
        )
        if (initData.correlationId && initData.interactionLineId) {
          this.initialization.resolve(initData)
        } else {
          this.initialization.reject(
            'Correlation ID or Interaction Line ID missing.'
          )
        }

        return this.initialization.promise
      }
    )
  }

  public async basicPayload(): Promise<IBasicPayload> {
    await this.initialization.promise

    // This won't happen if we are initialized
    if (this.partner === null) {
      throw new Error('Partner has not been set.')
    }

    return {
      channel: this.channel,
      correlationId: this.correlationId,
      interactionLineId: this.interactionLineId,
      partner: this.partner,
    }
  }

  public async getSecurityTokenPayload(
    txnType?: TXN_TYPE,
    subscriptionNumber?: string,
    accountNumber?: string,
    billingProgramId?: string
  ): Promise<GetSecurityTokenPayload> {
    await this.initialization.promise
    // This won't happen if we are initialized
    if (this.partner === null) {
      throw new Error('Partner has not been set.')
    }

    return {
      channel: this.channel,
      correlationId: this.correlationId,
      interactionLineId: this.interactionLineId,
      partner: this.partner,
      customerId: subscriptionNumber || '',
      sourceTransactionReference: subscriptionNumber || '',
      subscriptionNumber,
      accountNumber,
      txnType,
      billingProgramId,
    }
  }

  public setShouldReinitializeOnPageChange(reinit: boolean) {
    this.shouldReinitializeOnPageChange = reinit
  }

  public getShouldReinitializeOnPageChange() {
    return this.shouldReinitializeOnPageChange
  }

  public isInitialized() {
    return this.initialization.isResolved
  }

  public hasToken() {
    return !!this.token
  }

  public async getOffers(): Promise<IPlan[]> {
    const basicPayload = await this.basicPayload()
    const getOffersData = await this.getOffersApiCall(basicPayload)
    return getOffersData.offers
  }

  public async getDeviceList() {
    const basicPayload = await this.basicPayload()
    const getDeviceListRes = await this.getDeviceListApiCall(basicPayload)
    return buildDeviceList(getDeviceListRes && getDeviceListRes.catalog)
  }

  public fetchAccountInfo(
    request: IFetchAccountInfoRequest
  ): Promise<IFetchAccountInfoResponse> {
    return this.basicPayload().then((basicPayload) => {
      return this.fetchAccountInfoApiCall({
        ...basicPayload,
        ...request,
      })
    })
  }

  public async fetchBillingInfo() {
    const basicPayload = await this.basicPayload()

    return this.fetchBillingInfoApiCall(basicPayload)
  }

  public async makeAdhocPayment(
    request: IMakeAdhocPaymentRequest
  ): Promise<IMakeAdhocPaymentResponse> {
    const basicPayload = await this.basicPayload()

    return this.makeAdhocPaymentApiCall({ ...basicPayload, ...request })
  }

  public async updateBillingDate(
    request: IUpdateBillDateRequest
  ): Promise<void> {
    const basicPayload = await this.basicPayload()

    return this.updateBillingDateApiCall({ ...basicPayload, ...request })
  }

  public async trackEvent(
    eventType: EventType,
    props: object = {}
  ): Promise<void | any> {
    const basicPayload = await this.basicPayload()

    // Only call API if we have every item of the basicPayload
    if (!Object.values(basicPayload).every((item) => !!item)) {
      return Promise.reject(
        'Missing entries in basicPayload. Not calling trackEvent API.'
      )
    }

    return this.trackEventApiCall({
      ...basicPayload,
      eventType,
      props: {
        ...trackingProps(),
        ...props,
      },
    })
  }

  public async beginEnrollment(
    payload: IBeginEnrollmentRequest
  ): Promise<IBeginEnrollmentResponse> {
    const basicPayload = await this.basicPayload()

    return this.beginEnrollmentApiCall({
      ...basicPayload,
      ...payload,
    })
  }

  public async verifyPin(
    request: IVerifyPinRequest
  ): Promise<VerificationResult> {
    const basicPayload = await this.basicPayload()

    const resp = await this.verifyPinApiCall({
      ...basicPayload,
      pin: request.pin,
    })
    if (isError(resp)) {
      if (resp.error.code === 'AEP-414') {
        return VerificationResult.NoMatch
      } else if (resp.error.code === 'AEP-415') {
        return VerificationResult.Expired
      } else {
        throw new Error('Unknown error code: ' + resp.error.code)
      }
    } else {
      return VerificationResult.Verified
    }
  }

  public async findAgreements() {
    const basicPayload = await this.basicPayload()
    return this.findAgreementsApiCall(basicPayload)
  }

  public async getEligibility(
    request: IDeviceEligibilityRequest
  ): Promise<IDeviceEligibilityResponse> {
    const basicPayload = await this.basicPayload()
    return this.getDeviceEligibilityApiCall({
      ...basicPayload,
      ...request,
    })
  }

  public async sendSmartPin(
    request: ISendSmartPinRequest
  ): Promise<{ sent: boolean }> {
    const basicPayload = await this.basicPayload()
    return this.sendSmartPinApiCall({
      ...basicPayload,
      ...request,
      interactionLineId: this.interactionLineId,
    })
  }

  public async runFraudCheck(
    request: IFraudRequest
  ): Promise<IFraudRequestResponse> {
    const basicPayload = await this.basicPayload()
    return this.runFRMCheckApiCall({ ...basicPayload, ...request })
  }

  public async finishEnrollment(request: IFinishEnrollmentRequest) {
    const basicPayload = await this.basicPayload()
    return this.finishEnrollmentApiCall({ ...basicPayload, ...request })
  }

  public async sendTextReminder(request: ITextReminderRequest) {
    const basicPayload = await this.basicPayload()
    return this.sendSMSApiCall({
      ...basicPayload,
      ...request,
    }).then((response: ISMSGatewayResponse) => {
      if (response && response.error && response.error.code === 'AEP-405') {
        throw new Error('There was a problem sending the SMS')
      } else {
        return response
      }
    })
  }

  public async updateEnrollment(req: IUpdateEnrollmentRequest) {
    const basicPayload = await this.basicPayload()

    return this.updateEnrollmentApiCall({ ...basicPayload, ...req })
  }

  public async getSecurityToken(
    txnType?: TXN_TYPE,
    subscriptionNumber?: string,
    accountNumber?: string,
    billingProgramId?: string
  ) {
    const basicPayload = await this.getSecurityTokenPayload(
      txnType,
      subscriptionNumber,
      accountNumber,
      billingProgramId
    )

    return this.getSecurityTokenApiCall(basicPayload)
  }

  public async getAppointments(req: IGetAppointmentsRequest) {
    const basicPayload = await this.basicPayload()

    return this.getAppointmentsApiCall({ ...basicPayload, ...req })
  }

  public async reserveAppointment(req: IReserveAppointmentRequest) {
    const basicPayload = await this.basicPayload()
    return this.reserveAppointmentApiCall({ ...basicPayload, ...req })
  }

  public async createSmartLink() {
    const basicPayload = await this.basicPayload()

    return this.createSmartLinkApiCall(basicPayload)
  }

  public async cancelPlan(req: ICancelPlanRequest) {
    const basicPayload = await this.basicPayload()

    return this.cancelPlanApiCall({ ...basicPayload, ...req })
  }

  public async deductibleLookup(req: IDeductibleLookupRequest) {
    const basicPayload = await this.basicPayload()

    return this.deductibleLookupApiCall({ ...basicPayload, ...req })
  }

  public async getAssetAttributes(req: IAssetAttributesRequest) {
    const basicPayload = await this.basicPayload()

    return this.assetAttributesApiCall({ ...basicPayload, ...req })
  }

  public async createNoteCall(req: INoteData) {
    const payload = {
      ...(await this.basicPayload()),
      ...req,
    }
    return this.createNoteApiCall(payload)
  }

  // PRIVATE METHODS
  private setToken(token: string) {
    this.token = token
  }

  private request(requestConfig: AxiosRequestConfig): Promise<any> {
    if (this.token) {
      requestConfig.headers = requestConfig.headers || {}
      requestConfig.headers['X-Token'] = this.token
    }

    return this.axiosInstance
      .request(requestConfig)
      .then((response) => {
        if (response.headers['x-token'] != null) {
          this.setToken(response.headers['x-token'])

          if (this.token !== null) {
            // always
            sessionStorage.setItem('token', this.token)
          }
        }
        return response.data
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response
          const { error } = data
          if (error && error.code) {
            // if the error is Expired Token (AEP-1) or Invalid Token (AEP-2)
            // delete the existing token from session storage and reload the page to send them back to the appropriate sign in screen
            if (
              error.code === 'AEP-1' ||
              error.code === 'AEP-2' ||
              error.code === 'AEP-3'
            ) {
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('enrolled')
              return window.location.reload()
            }
          }
        }
        throw err
      })
  }

  private send(
    method: Method,
    url: string,
    data: any,
    params: any,
    requestConfig?: AxiosRequestConfig
  ) {
    requestConfig = requestConfig || {}
    return this.request({
      url,
      data,
      params,
      method,
      ...requestConfig,
    })
  }

  private get(url: string, params: any, requestConfig?: AxiosRequestConfig) {
    return this.send('get', url, null, params, requestConfig)
  }

  private post(url: string, data: any, requestConfig?: AxiosRequestConfig) {
    return this.send('post', url, data, null, requestConfig)
  }

  private put(url: string, data: any, requestConfig?: AxiosRequestConfig) {
    return this.send('put', url, data, null, requestConfig)
  }

  private delete(url: string, data: any, requestConfig?: AxiosRequestConfig) {
    return this.send('delete', url, data, null, requestConfig)
  }

  // API CALLS
  private beginEnrollmentApiCall = (
    payload: IBeginEnrollmentRequestPayload
  ): Promise<IBeginEnrollmentResponse> => {
    return this.post('/begin-enrollment', payload)
  }

  private sendSmartPinApiCall = (payload: ISendSmartPinPayload) => {
    return this.post('/passcode/send', payload)
  }

  private runFRMCheckApiCall = (payload: IFraudAPIPayload) => {
    return this.post('/frm-check', payload)
  }

  private finishEnrollmentApiCall = (payload: IFinishEnrollmentPayload) => {
    return this.post('/finish-enrollment', payload)
  }

  private getDeviceListApiCall = (params: IBasicPayload) => {
    return this.get('/assets', params)
  }

  private trackEventApiCall = (params: ITrackEventPayload) => {
    if (!this.isTrackingEnabled) {
      return Promise.resolve()
    }
    const {
      channel,
      correlationId,
      interactionLineId,
      partner,
      eventType,
      props,
    } = params
    window.dataLayer?.push({
      channel,
      correlationId,
      event: 'DIGITAL ENROLLMENT ' + eventType,
      flow: 'Enroll',
      interactionLineId,
      partner,
      props,
    })

    return this.post('/log', params)
  }

  private verifyPinApiCall = (
    payload: IVerifyPinPayload
  ): Promise<IVerifyPinResponse> => {
    return this.post('/passcode/verify', payload)
  }

  private findAgreementsApiCall = (
    payload: IBasicPayload
  ): Promise<IFindAgreementResponse> => {
    return this.get('/agreement', payload)
  }

  private fetchAccountInfoApiCall = (payload: IFetchAccountInfoPayload) => {
    return this.get('/account', payload)
  }

  private fetchBillingInfoApiCall = (
    payload: IBasicPayload
  ): Promise<IBillingInfoResponse> => {
    return this.get('/billing-information', payload)
  }

  private makeAdhocPaymentApiCall = (
    payload: IMakeAdhocPaymentPayload
  ): Promise<IMakeAdhocPaymentResponse> => {
    return this.post('billing/make-payment', payload)
  }

  private updateBillingDateApiCall = (payload: IUpdateBillDatePayload) => {
    return this.put('/billing', payload)
  }

  private initializeApiCall = (channel: Channel, cid?: string) => {
    return this.post('/initialize', {
      channel,
      partner: this.partner,
      campaignId: cid,
    })
  }

  private getOffersApiCall = (
    payload: IBasicPayload
  ): Promise<IClientOfferServiceResponse> => {
    return this.get('/get-offers', payload)
  }

  private getDeviceEligibilityApiCall = (params: IDeviceEligibilityPayload) => {
    return this.get(`/asset/${params.assetCatalogId}/eligibility`, params)
  }

  private sendSMSApiCall = (payload: ISMSGatewayPayload) => {
    return this.post('/send-sms', payload)
  }

  private updateEnrollmentApiCall = (
    payload: IUpdateEnrollmentRequestPayload
  ): Promise<IUpdateEnrollmentResponse> => {
    return this.put('/enrollment', payload)
  }

  private getSecurityTokenApiCall = (
    payload: GetSecurityTokenPayload
  ): Promise<ISecurityTokenResponse> => {
    return this.get('/billing/securityToken', payload)
  }

  private getAppointmentsApiCall = (
    payload: IGetAppointmentsPayload
  ): Promise<IGetAppointmentsResponse> => {
    return this.get('/appointments', payload)
  }

  private reserveAppointmentApiCall = (
    payload: IReserveAppointmentPayload
  ): Promise<IReserveAppointmentResponse> => {
    return this.post('/appointments', payload)
  }

  private createSmartLinkApiCall = (
    payload: IBasicPayload
  ): Promise<ICreateSmartLinkResponse> => {
    return this.get('/links/claims', payload)
  }

  private cancelPlanApiCall = (
    payload: ICancelPlanPayload
  ): Promise<ICancelPlanResponse> => {
    return this.delete('/agreement', payload)
  }

  private deductibleLookupApiCall = (
    payload: IDeductibleLookupPayload
  ): Promise<IDeductibleLookupResponse> => {
    return this.post('/deductible-lookup', payload)
  }

  private assetAttributesApiCall = (
    payload: IAssetAttributesPayload
  ): Promise<IAssetAttributesResponse> => {
    return this.get('/asset-attributes', payload)
  }

  private createNoteApiCall = (
    paylaod: ICreateNotePayload
  ): Promise<ICreateNoteResponse> => {
    return this.post('/create-note', paylaod)
  }
}
