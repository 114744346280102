import React from 'react'
import { Box, Heading, Image } from 'rebass'
import CrackedScreenImage from '../../../../sharedAssets/cracked-screen.png'
import tescoProtectLogo from '../../assets/tesco-protect-logo-white.png'
import { error, tescoBlue, tescoPulseBlue } from '../../theme'
import ImageOverlapHero from '../../../../sharedComponents/templates/ImageOverlapHero'
import { useConfig } from '../../state/tescoMarketingConfigSetup'
import Button from '../../../../sharedComponents/atoms/Button'

interface TescoHeroProps {
  onStartAClaim: () => void
}
const TescoHero: React.FC<TescoHeroProps> = ({ onStartAClaim }) => {
  const { APP_MAX_WIDTH, APP_MIN_WIDTH } = useConfig()

  return (
    <ImageOverlapHero
      imgAlt="Image of a phone with a cracked screen"
      imgSrc={CrackedScreenImage}
      displayBgArt={false}
      alignItems="flex-start"
      flexWrap="wrap"
      backgroundStyle={{
        background: [
          tescoBlue,
          tescoBlue,
          'radial-gradient(21.53% 144.4% at 24.93% 75.9%, #6DC3F6 0.72%, #00539F 98.82%)',
        ],
        mixBlendMode: 'normal',
        maxHeight: [400, 450, 550],
      }}
      imageStyle={{
        height: 'auto',
        width: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        transform: 'rotate(-8deg)',
        display: ['none', 'block', 'block'],
      }}
      maxWidth={APP_MAX_WIDTH}
      minWidth={APP_MIN_WIDTH}
    >
      <Box pl={[3, 3, 6]} sx={{ textAlign: ['center', 'center', 'left'] }}>
        <Image alt="Tesco Protect logo" src={tescoProtectLogo} width="157px" />

        <Box pt={4}>
          <Heading
            as="h1"
            fontSize={[5, 5, 6]}
            width={[1, 1, 520]}
            sx={{
              fontWeight: 'bold',
              maxWidth: '100%',
              lineHeight: ['125%', '125%', '110%'],
            }}
          >
            Start, manage or track your Tesco Mobile Protect claim
            <span style={{ color: error }}>.</span>
          </Heading>

          <Button
            my={[4, 4, 5]}
            width={[1, 290]}
            height={48}
            variant="secondary"
            bg={tescoPulseBlue}
            color="white"
            onClick={onStartAClaim}
          >
            Get started
          </Button>
        </Box>
      </Box>
    </ImageOverlapHero>
  )
}

export default TescoHero
