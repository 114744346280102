import React, { useEffect, useState } from 'react'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/styles'
import { Box, CssBaseline, Divider, Typography } from '@material-ui/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import SmartPinContainer from '../sharedComponents/SmartPinContainer'
import SignInContainer from '../sharedComponents/SignInContainer'
import SmartPinDeliveryContainer from './components/SmartPinDeliveryContainer'
import AccountMain from './components/AccountMain'
import SignOut from './components/SignOut'
import ConnectedLoadingOverlay from './components/ConnectedLoadingOverlay'
import TerminalError from './components/TerminalError'
import Banner from './components/Banner'
import TrackRouteChanges from '../sharedComponents/TrackRouteChanges'
import AccountLinkWithTracking from './components/AccountLinkWithTracking'
// import { MaintenancePage } from './components/MaintenancePage';

import {
  Country,
  Channel,
  EventType,
  IAccountMgmtConfig,
  IActions,
  IPlan,
  IStore,
  Locale,
} from '../interfaces'
import {
  connect,
  initAccountStore,
  initAccountApiClient,
  StoreProvider,
  useActions,
  useApi,
  useStore,
} from './state/accountStateMgmt'
import { LocaleProvider, useLocale } from '../localization'
import { ConfigProvider, useConfig } from './state/accountConfigSetup'
import DownForMaintenance from '../../sharedComponents/DownForMaintenance'
import ApiClient from '../ApiClient'
import useRouter from 'use-react-router'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { getDynatraceHeadElement } from '../sharedHelpers/getDynatraceHeadElements'

const generateClassName = createGenerateClassName({
  productionPrefix: 'accountMgmtStyles',
  seed: 'accountMgmtStyles',
})
const isProd = process.env.NODE_ENV === 'production'
const ConnectedSignIn = connect(
  (store: IStore) => ({
    isAgent: false,
    selectedPlan: null as IPlan | null,
    mobileNumber: store.mdn || '',
    reCaptchaToken: '',
    loading: store.loading,
    isSignIn: true,
    sendVia: store.smartPINDeliveryMethod,
  }),
  (methods: IActions) => ({
    handleError: methods.goToErrorPage,
    submit: methods.submitMDN,
    agentSignIn: () => {
      // No need to localize for Asurion agents
      console.log('Agent login unsupported')
    },
    sendSmartPin: methods.sendSmartPin,
    trackEvent: methods.trackEvent,
  })
)<{
  reCaptchaKey: string
  disableCaptcha: boolean
  mdnMask: string
  country: Country
  countryCallingCode: string
  showTextMessageDisclaimer: boolean
}>(React.memo(SignInContainer))

const ConnectedSmartPinDelivery = connect(
  (store: IStore) => ({
    mdn: store.mdn || '',
    email: store.email || '',
    hasEmail: store.hasEmail,
    reCaptchaToken: store.reCaptchaToken,
  }),
  (methods: IActions) => ({
    methodSelected: methods.setDeliveryMethod,
    verificationCodeSent: methods.verificationCodeSent,
    handleError: methods.goToErrorPage,
    setLoading: methods.setLoading,
  })
)<{ countryCallingCode: string }>(React.memo(SmartPinDeliveryContainer))

const ConnectedSmartPin = connect(
  (store: IStore) => ({
    sendVia: store.smartPINDeliveryMethod,
    reCaptchaToken: store.reCaptchaToken,
    mobileNumber: store.mdn || '',
    email: store.email || '',
    loading: store.loading,
  }),
  (methods: IActions) => ({
    methodSelected: methods.setDeliveryMethod,
    submit: methods.verifyPin,
    setLoading: methods.setLoading,
    sendSmartPin: methods.sendSmartPin,
    handleError: methods.goToErrorPage,
    removeBannerError: methods.setBanner,
    verificationCodeSent: methods.verificationCodeSent,
    trackEvent: methods.trackEvent,
  })
)<{ countryCallingCode: string }>(React.memo(SmartPinContainer))

const AccountAppInner: React.FC<{
  onSignIn?: (isSignedIn: boolean) => void
}> = ({ onSignIn }) => {
  const {
    ACCOUNT_PATH,
    ENROLL_PATH,
    THEME,
    RECAPTCHA_KEY,
    DISABLE_CAPTCHA,
    SHOW_ENROLL_NOW_CTA,
    MDN_MASK,
    USE_BRITISH_ADDRESSING,
    COUNTRY_CALLING_CODE,
    APP_MAX_WIDTH,
    IS_DOWN_FOR_MAINTENANCE,
    SHOW_TEXT_MESSAGE_DISCLAIMER,
    PARTNER,
  } = useConfig()
  const { signedIn, mdn } = useStore()
  const { login } = useActions()
  const apiClient = useApi()
  const { strings } = useLocale()
  const {
    location: { pathname },
  } = useRouter()
  const isAccountPath = pathname.includes('/account')

  useEffect(() => {
    apiClient.trackEvent(EventType.InitialPageLoad, {
      flow: 'AcctMgmt',
    })

    login()
  }, [apiClient, login])

  useEffect(() => {
    const initialize = async (apiClient: ApiClient) => {
      // ensure that this gets set to false prior to reinit in case there is an error
      apiClient.setShouldReinitializeOnPageChange(false)
      await apiClient.initialize(PARTNER)
      onSignIn?.(signedIn)
    }

    if (apiClient.getShouldReinitializeOnPageChange()) {
      initialize(apiClient)
    } else if (onSignIn) {
      onSignIn(signedIn)
    }

    // If this component is torn down, call this handler to inform the parent
    // that we are signed out.
    return () => {
      onSignIn?.(false)
    }
  }, [signedIn, onSignIn, apiClient, PARTNER])

  const enrollNowCTA = SHOW_ENROLL_NOW_CTA && (
    <Box mt={5} maxWidth={600} ml="auto" mr="auto" textAlign="center">
      <Divider />

      <Box mt={2}>
        <Typography variant="body1">
          {strings.accountApp.dontHaveAnAccount}{' '}
          <AccountLinkWithTracking
            linkTarget={ENROLL_PATH}
            linkText={strings.accountApp.enrollNow}
          />
        </Typography>
      </Box>
    </Box>
  )
  const headElements = ([getDynatraceHeadElement(isProd, PARTNER)] ?? []).map(
    ({ type, attributes = {} }, i) =>
      React.createElement(type, { key: `headTag-${i}`, ...attributes })
  )

  return (
    <ThemeProvider theme={THEME}>
      <StylesProvider generateClassName={generateClassName}>
        <Helmet>{headElements}</Helmet>
        <CssBaseline />
        <TrackRouteChanges apiClient={apiClient} />

        <Box flexDirection="column" minHeight="100%" display="flex">
          <Box
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box style={{ flex: '0 0 auto' }}>
              <Banner />
            </Box>

            {IS_DOWN_FOR_MAINTENANCE ? (
              <Box
                px={3}
                style={{
                  flex: '1 1 auto',
                  width: APP_MAX_WIDTH,
                  maxWidth: '100%',
                  marginTop: 48,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <DownForMaintenance />
              </Box>
            ) : (
              <Switch>
                <Route
                  path={`${ACCOUNT_PATH}/error`}
                  component={TerminalError}
                />

                <Route path={`${ACCOUNT_PATH}/sign-out`} component={SignOut} />

                <Route
                  path={`${ACCOUNT_PATH}/sign-in`}
                  render={() => (
                    <Box px={3} py={4}>
                      <ConnectedSignIn
                        reCaptchaKey={RECAPTCHA_KEY}
                        disableCaptcha={DISABLE_CAPTCHA}
                        mdnMask={MDN_MASK}
                        country={
                          USE_BRITISH_ADDRESSING ? Country.GB : Country.US
                        }
                        countryCallingCode={COUNTRY_CALLING_CODE}
                        showTextMessageDisclaimer={SHOW_TEXT_MESSAGE_DISCLAIMER}
                      />
                      {enrollNowCTA}
                      {/* <MaintenancePage /> */}
                    </Box>
                  )}
                />

                {/* If no mdn by this point, redirect to /sign-in. */}
                {!mdn && <Redirect to={`${ACCOUNT_PATH}/sign-in`} />}

                <Route
                  path={`${ACCOUNT_PATH}/smart-pin-delivery`}
                  render={() => (
                    <Box px={3} py={4}>
                      <ConnectedSmartPinDelivery
                        countryCallingCode={COUNTRY_CALLING_CODE}
                      />
                      {enrollNowCTA}
                    </Box>
                  )}
                />

                <Route
                  path={`${ACCOUNT_PATH}/smart-pin`}
                  render={() => (
                    <div style={{ padding: '32px 24px' }}>
                      <ConnectedSmartPin
                        countryCallingCode={COUNTRY_CALLING_CODE}
                      />
                      {enrollNowCTA}
                    </div>
                  )}
                />

                {/* If not signed in by this point, redirect to sign-in. */}
                {!signedIn && <Redirect to={`${ACCOUNT_PATH}/sign-in`} />}

                <Route
                  path={[
                    `${ACCOUNT_PATH}/overview`,
                    `${ACCOUNT_PATH}/billing`,
                    `${ACCOUNT_PATH}/preferences`,
                  ]}
                  render={() => <AccountMain />}
                />

                {isAccountPath && <Redirect to={`${ACCOUNT_PATH}/sign-in`} />}
              </Switch>
            )}
          </Box>

          <ConnectedLoadingOverlay />
        </Box>
      </StylesProvider>
    </ThemeProvider>
  )
}

// Any component using this component must give it config using the
// IAccountMgmtConfig interface.
const AccountApp: React.FC<{
  config: IAccountMgmtConfig
  providedApiClient?: ApiClient
  onSignIn?: (isSignedIn: boolean) => void
  selectedLocale?: Locale
}> = ({ config, providedApiClient, onSignIn, selectedLocale }) => {
  const store = initAccountStore()
  const [apiClient] = useState(
    () =>
      providedApiClient ||
      initAccountApiClient({
        apiBaseUrl: config.API_BASE_URL,
        channel: Channel.Web,
        isTrackingEnabled: config.MIXPANEL_ENABLED,
        token: sessionStorage.getItem('token') || undefined,
      })
  )

  return (
    <ConfigProvider config={config}>
      <StoreProvider apiClient={apiClient} store={store}>
        <HelmetProvider>
          <LocaleProvider value={selectedLocale || config.DEFAULT_LOCALE}>
            <AccountAppInner onSignIn={onSignIn} />
          </LocaleProvider>
        </HelmetProvider>
      </StoreProvider>
    </ConfigProvider>
  )
}

export default AccountApp
