import React from 'react'
import ApiClient from '../../ApiClient'
import {
  IActions,
  ICreateNoteResponse,
  INoteData,
  IStore,
  IUpdateEnrollmentRequest,
  TerminationReason,
} from '../../interfaces'
import { History } from 'history'
import {
  AccountErrorCode,
  BannerMsgType,
  BillingStatus,
  EventType,
  IAccountMgmtConfig,
  IBankAccountDetails,
  IBankAccountPaymentData,
  Banner,
  IBillingAddress,
  ICCDetails,
  ICCPaymentData,
  IMakeAdhocPaymentRequest,
  isCCDetails,
  isCCPayment,
  ISendSmartPinRequest,
  isError,
  Locale,
  PaymentData,
  SmartPINDeliveryMethod,
  VerificationResult,
} from '../../interfaces'
// import convertNameToNameArray from '../../sharedHelpers/convertNameToNameArray'
import formatPhoneNumber from '../../sharedHelpers/formatPhoneNumber'
import { useConfig } from './accountConfigSetup'
import { useLocale } from '../../localization'
import { Box, Link } from '@material-ui/core'
import formatDateByCountry from '../../sharedHelpers/formatDateByCountry'
import getCountryFromPartner from '../../../sharedHelpers/getCountryFromPartner'
import getCurrencyFromPartner from '../../../sharedHelpers/getCurrencyFromPartner'
import moment from 'moment'
import { AccountMgmtStrings } from '../../localization/en'
import {
  ACHInfo,
  BillingContactInfo,
  CreditCardInfo,
  Env,
  PaymentClient,
  SessionData,
} from '@backoffice/fast-payments-client-js-sdk'
import { omitBy as _omitBy, isEmpty as _isEmpty } from 'lodash'
import { TXN_TYPE } from '../common/constants'

interface IContextFromStateMgmt {
  apiClient: ApiClient
  history: History
  store: IStore
  updateStore: (partialStore: Partial<IStore>) => void
}

interface IContext extends IContextFromStateMgmt {
  config: IAccountMgmtConfig
  locale: { locale: Locale; strings: AccountMgmtStrings }
}

let paymentClient: PaymentClient

const goToErrorPage = (context: IContext) => {
  context.history.push(`${context.config.ACCOUNT_PATH}/error`)
}

const setLoading = (
  context: IContext,
  loading = true,
  isScreenBlocked?: boolean
) => {
  context.updateStore({ loading })
  if (!loading || isScreenBlocked !== undefined) {
    context.updateStore({ isScreenBlocked: !!isScreenBlocked })
  }
}

const setBanner = (context: IContext, bannerMsg?: Banner) => {
  context.updateStore({ bannerMsg })
}

const getBillingInfo = (context: IContext): Promise<void> => {
  setLoading(context, true, true)
  return context.apiClient
    .fetchBillingInfo()
    .then((billingInfoRes) => {
      const {
        billingStatus,
        billingHistory,
        billingAddress,
        billingDetails,
        billingContact,
      } = billingInfoRes

      // const billingDetailsFromStore = context.store.billingDetails

      let cardNumFromApi
      let cardTypeFromApi
      let bankAcctNumFromApi
      let bankRoutingNumFromApi

      if (isCCDetails(billingDetails)) {
        cardNumFromApi = billingDetails.cardNum
        cardTypeFromApi = billingDetails.cardType
      } else {
        bankAcctNumFromApi = billingDetails.acctNum
        bankRoutingNumFromApi = billingDetails.routingNum
      }

      // let cardNumFromStore
      // let cardTypeFromStore
      // if (billingDetailsFromStore && isCCDetails(billingDetailsFromStore)) {
      //   const { cardNum, cardType } = billingDetailsFromStore || {}
      //   cardNumFromStore = cardNum
      //   cardTypeFromStore = cardType
      // }

      let newBillingDetails: ICCDetails | IBankAccountDetails | undefined
      if (isCCDetails(billingDetails)) {
        newBillingDetails = {
          ...billingDetails,
          // Prefer the cardNum and cardType from the store if we have it
          // because the data returned from the API is sometimes wrong
          //   cardNum: cardNumFromStore || cardNumFromApi || '',
          //   cardType: cardTypeFromStore || cardTypeFromApi || '',
          cardNum: cardNumFromApi || '',
          cardType: cardTypeFromApi || '',
        }
      } else {
        newBillingDetails = {
          ...billingDetails,
          acctNum: bankAcctNumFromApi || '',
          routingNum: bankRoutingNumFromApi || '',
        }
      }

      context.updateStore({
        billingDetails: newBillingDetails,
        billingAddress,
        billingHistory,
        billingStatus,
        billingContact,
      })

      if (billingStatus === BillingStatus.PastDue) {
        const num = context.config.CUST_SERV_PHONE_NO
        const pastDueMessage = context.config.ALLOW_MAKEUP_PAYMENT ? (
          <Box
            component="span"
            onClick={() => showBillingModal(context, BillingModal.AdHocPayment)}
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {context.locale.strings.misc.makeAPaymentNow}
          </Box>
        ) : (
          <Box component="span">
            {context.locale.strings.misc.pleaseContact}{' '}
            <Link
              href={`tel: ${num}`}
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              {num}.
            </Link>
          </Box>
        )

        setBanner(context, {
          type: BannerMsgType.Error,
          msg: (
            <Box>
              {context.locale.strings.misc.yourAccountIsPastDue}{' '}
              {pastDueMessage}
            </Box>
          ),
        })
      } else if (billingStatus === BillingStatus.Pending) {
        setBanner(context, {
          type: BannerMsgType.Warning,
          msg: (
            <Box>
              {context.locale.strings.misc.yourAccountIsPending}{' '}
              <Box
                component="span"
                onClick={() =>
                  showBillingModal(context, BillingModal.PaymentMethod)
                }
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                {context.locale.strings.misc.pleaseUpdatePaymentMethod}
              </Box>
            </Box>
          ),
        })
      }
    })
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToFetch + err,
      })
    })
    .finally(() => setLoading(context, false))
}

const login = (context: IContext) => {
  setBanner(context)

  if (!sessionStorage.getItem('token')) {
    context.history.replace(`${context.config.ACCOUNT_PATH}/sign-in`)
    return
  }

  setLoading(context, true, true)

  return context.apiClient
    .findAgreements()
    .then((findAgreementsRes) => {
      if (!isError(findAgreementsRes)) {
        context.updateStore({
          details: findAgreementsRes,
          mdn: findAgreementsRes.mdn,
          signedIn: true,
          subscriptionNumber: findAgreementsRes.subscriptionNumber,
        })
        context.history.replace(`${context.config.ACCOUNT_PATH}/overview`)
        setLoading(context, true, true)

        return getBillingInfo(context)
      } else if (findAgreementsRes.error.code === 'AEP-429') {
        // Thrown when there is no billingProfileId in the agreement.
        goToErrorPage(context)

        return
      } else if (findAgreementsRes.error.code === 'AEP-430') {
        // Thrown when there are duplicate billingProfileIds (SSPS-275).
        context.updateStore({ errorCode: AccountErrorCode['AEP-430'] })
        goToErrorPage(context)

        return
      } else if (findAgreementsRes.error.code === 'AEP-431') {
        // Thrown when there are multiple active agreements (SSPS-347).
        context.updateStore({ errorCode: AccountErrorCode['AEP-431'] })
        goToErrorPage(context)

        return
      } else {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.didNotRecognize,
        })

        context.history.replace(`${context.config.ACCOUNT_PATH}/sign-in`)
        context.updateStore({ mdn: '' })
      }
    })
    .catch(() => {
      goToErrorPage(context)
    })
    .finally(() => {
      setLoading(context, false)
    })
}

const updateBillingDate = (
  context: IContext,
  billDate: string
): Promise<void> => {
  setLoading(context)

  return context.apiClient
    .updateBillingDate({ billDate })
    .then(() => getBillingInfo(context))
    .then(() =>
      setBanner(context, {
        type: BannerMsgType.Success,
        msg: context.locale.strings.accountActions.thanksBanner,
      })
    )
    .catch(() => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedBillingDate,
      })
    })
    .finally(() => setLoading(context, false))
}

const setDeliveryMethod = async (
  context: IContext,
  m: SmartPINDeliveryMethod,
  smartPinRequest: ISendSmartPinRequest
): Promise<{ sent: boolean }> => {
  context.updateStore({ smartPINDeliveryMethod: m })

  try {
    const sendSmartPinRes = await context.apiClient.sendSmartPin(
      smartPinRequest
    )

    if (isError(sendSmartPinRes)) {
      console.error(sendSmartPinRes.error)
      return { sent: false }
    } else {
      if (!sendSmartPinRes.sent) {
        return { sent: false }
      }
    }

    context.history.push(`${context.config.ACCOUNT_PATH}/smart-pin`)
    return { sent: true }
  } catch (error) {
    console.error(error)
    return { sent: false }
  }
}

const submitMDN = (context: IContext, mdnToSet: string, token: string) => {
  setLoading(context)
  setBanner(context)

  const { COUNTRY_CALLING_CODE } = context.config

  context.updateStore({ mdn: mdnToSet, reCaptchaToken: token })

  context.apiClient
    .fetchAccountInfo({
      mdn: mdnToSet,
      countryCallingCode: COUNTRY_CALLING_CODE,
    })
    .then((resp) => {
      context.updateStore({ email: resp.email, hasEmail: resp.hasEmail })
      context.history.push(`${context.config.ACCOUNT_PATH}/smart-pin-delivery`)
    })
    .catch(() => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToFetchMDN,
      })
    })
    .finally(() => setLoading(context, false))
}

const verifyPin = (context: IContext, pin: string) => {
  setLoading(context, true, true)

  context.apiClient
    .verifyPin({ pin })
    .then((verifyPinRes) => {
      if (verifyPinRes !== VerificationResult.Verified) {
        const newErrorCount = context.store.smartPinErrorCounter + 1

        context.updateStore({
          smartPinErrorCounter: newErrorCount,
        })

        // This will go to the terminal error page on the 3rd error
        if (newErrorCount > 2) {
          setBanner(context)
          goToErrorPage(context)
          return
        }
      }

      if (verifyPinRes === VerificationResult.NoMatch) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.doesNotMatch,
        })
      } else if (verifyPinRes === VerificationResult.Expired) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.expired,
        })
      } else if (verifyPinRes === VerificationResult.Verified) {
        return login(context)
      }
    })
    .catch(() => goToErrorPage(context))
    .finally(() => setLoading(context, false))
}

const updateName = (
  context: IContext,
  firstName: string,
  lastName: string
): Promise<void> => {
  setLoading(context)

  return context.apiClient
    .updateEnrollment({ firstName, lastName })
    .then((response) => {
      if (isError(response)) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.failedName,
        })
      } else {
        if (context.store.details) {
          context.updateStore({
            details: {
              ...context.store.details,
              customer: { firstName, lastName },
            },
          })
          setBanner(context, {
            type: BannerMsgType.Success,
            msg: context.locale.strings.accountActions.thanksBanner2,
          })
        }
      }
    })
    .catch(() => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedName,
      })
    })
    .finally(() => setLoading(context, false))
}

const updateEmail = (
  context: IContext,
  emailAddress: string
): Promise<void> => {
  setLoading(context)

  return context.apiClient
    .updateEnrollment({ emailAddress })
    .then((response) => {
      if (isError(response)) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.failedEmail,
        })
      } else {
        if (context.store.details) {
          setBanner(context, {
            type: BannerMsgType.Success,
            msg: context.locale.strings.accountActions.updated({
              email1:
                (context.store.details &&
                  context.store.details.contact.email) ||
                '',
              email2: emailAddress,
            }),
          })
          context.updateStore({
            details: {
              ...context.store.details,
              contact: {
                ...context.store.details.contact,
                email: emailAddress,
              },
            },
          })
        }
      }
    })
    .catch(() => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedEmail,
      })
    })
    .finally(() => setLoading(context, false))
}

const makePayment = async (
  context: IContext,
  req: IMakeAdhocPaymentRequest,
  newPaymentMethod?: PaymentData
): Promise<void> => {
  setLoading(context)
  setBanner(context)

  try {
    if (newPaymentMethod) {
      await updatePaymentMethod(context, newPaymentMethod)
    }

    const response = await context.apiClient.makeAdhocPayment(req)

    if (!isError(response)) {
      if (response.status === 'approved') {
        setBanner(context, {
          type: BannerMsgType.Success,
          msg: context.locale.strings.accountActions.successCatchUp,
        })
      }
    } else {
      response.error.code === 'AEP-417'
        ? setBanner(context, {
            type: BannerMsgType.Error,
            msg: context.locale.strings.accountActions.errorPleaseCall(
              response.error.message,
              context.config.CUST_SERV_PHONE_NO
            ),
          })
        : setBanner(context, {
            type: BannerMsgType.Error,
            msg: context.locale.strings.accountActions.errorPleaseCall(
              response.error.message,
              context.config.CUST_SERV_PHONE_NO
            ),
          })
    }
  } catch (err) {
    console.error(err)

    setBanner(context, {
      type: BannerMsgType.Error,
      msg: context.locale.strings.accountActions.errorMakingPayment(
        context.config.CUST_SERV_PHONE_NO
      ),
    })

    return
  } finally {
    await getBillingInfo(context)
    setLoading(context, false)
  }
}

const updateCCPaymentMethod = async (
  context: IContext,
  data: ICCPaymentData
): Promise<void> => {
  setLoading(context)

  const {
    address,
    creditCardNumber,
    firstName,
    lastName,
    expDate,
    cvv,
    ccType,
  } = data
  // const [firstName, lastName] = convertNameToNameArray(nameOnCard)
  const [ccExpMonth, ccExpYear] = expDate.split('/')
  const creditCardInfo: CreditCardInfo = {
    expiration: {
      year: ccExpYear,
      month: ccExpMonth,
    },
    number: creditCardNumber,
    securityCode: cvv,
  }
  const billingContactInfo: BillingContactInfo = {
    name: {
      first: firstName,
      last: lastName,
    },
    address: {
      address1: address.addressLine1,
      address2: address.addressLine2 || undefined,
      city: address.city,
      state: address.state,
      country: getCountryFromPartner(context.config.PARTNER),
      zip: address.postalCode,
    },
    contactInfo: {
      email: context.store.email,
      phone: context.store.mdn,
    },
  }
  const subscriptionNumber = context.store.subscriptionNumber

  const { encryptionKey, token } = await context.apiClient
    .getSecurityToken(
      TXN_TYPE.CC,
      subscriptionNumber, //sessionStorage.getItem('subscriptionNumber')?.toString(),
      creditCardNumber.replace(/\s/g, '')
    )
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToFetchSecToken,
      })
      setLoading(context, false)
      throw err
    })

  if (!paymentClient) {
    const env =
      process.env.NODE_ENV === 'production' ? Env['prod-eu'] : Env['qa-eu'] //Env['qa-us']
    paymentClient = new PaymentClient({ env })
  }

  try {
    const sessionData: SessionData = {
      appName: 'ENRPORTAL',
      encryptionKey: encryptionKey,
      currency: getCurrencyFromPartner(context.config.PARTNER),
    }

    paymentClient.addSession(token, sessionData)

    await paymentClient.addCreditCardInfo(creditCardInfo)

    await paymentClient.addBillingContactInfo({
      ...billingContactInfo,
      address: {
        ..._omitBy(billingContactInfo.address, _isEmpty),
        zip: address.postalCode,
        country: getCountryFromPartner(context.config.PARTNER),
      },
    })

    await paymentClient.processPayment()
  } catch (err) {
    setBanner(context, {
      type: BannerMsgType.Error,
      msg: context.locale.strings.accountActions.errorUpdatingPayment(
        context.config.CUST_SERV_PHONE_NO
      ),
    })
    setLoading(context, false)
    throw err
  }

  await context.apiClient
    .updateEnrollment({
      updatePaymentMethod: true, // Tell the API to get the new payment info we just added
      billingAddress: {
        ...address,
        addressLine2: address.addressLine2 || undefined,
      },
      firstName,
      lastName,
      securityToken: token,
      paymentMethodDetails: {
        CardExpirationMonth: ccExpMonth,
        CardExpirationYear: ccExpYear,
        CardHolderFirstName: firstName,
        CardHolderLastName: lastName,
        CardNumber: creditCardNumber.slice(-4),
        CardPostalCode: address.postalCode,
        CardType: ccType,
      },
    })
    .then((response) => {
      if (isError(response)) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.failureUpdatingPaymentInfo(
            context.config.CUST_SERV_PHONE_NO
          ),
        })
      } else {
        if (context.store.billingDetails) {
          const billingDetails: ICCDetails = {
            billingProfileId: context.store.billingDetails.billingProfileId,
            billDate: context.store.billingDetails.billDate,
            cardExp: data.expDate,
            cardNum: data.creditCardNumber.replace(/\s/g, '').substr(-4),
            cardType: data.ccType,
            // name: data.nameOnCard,
            name: `${firstName} ${lastName}`,
            nextInvoiceDate: context.store.billingDetails.nextInvoiceDate,
          }
          const billingAddress: IBillingAddress = data.address

          context.updateStore({
            billingDetails,
            billingAddress,
          })
        }

        setBanner(context, {
          type: BannerMsgType.Success,
          msg: context.locale.strings.accountActions.updatedBillingInfo(
            (context.store.details && context.store.details.contact.email) || ''
          ),
        })
      }
    })
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToUpdatePaymentInfo,
      })

      throw err
    })
    .finally(() => setLoading(context, false))
}

const updateBankAccountPaymentMethod = async (
  context: IContext,
  data: IBankAccountPaymentData
): Promise<void> => {
  setLoading(context)

  const {
    accountNumber,
    sortCode,
    /*accountHolderName*/ firstName,
    lastName,
    address,
  } = data
  const details = context.store.details
  const billingDetailsFromStore = context.store.billingDetails
  // const [billingFirstName, billingLastName] = convertNameToNameArray(
  //   accountHolderName
  // )
  const billingFirstName = firstName
  const billingLastName = lastName
  if (!details) {
    return Promise.reject(
      context.locale.strings.accountActions.missingFindAgreementsResponseData
    )
  }

  if (!billingDetailsFromStore) {
    return Promise.reject(
      context.locale.strings.accountActions.missingBillingDetails
    )
  }

  const { billDate, nextInvoiceDate, billingProfileId } =
    billingDetailsFromStore

  const bankAccountInfo: ACHInfo = {
    bankAccountNumber: accountNumber,
    bankRoutingNumber: sortCode,
    countryCode: 'GB',
    accountHolderName: `${billingFirstName} ${billingLastName}`,
    // invoiceNumber: sessionStorage.getItem('subscriptionNumber')?.toString(),
    // authorisationId: authorisationId.replace('-', '').slice(0, 8)
  }
  const billingContactInfo: BillingContactInfo = {
    name: {
      first: billingFirstName,
      last: billingLastName,
    },
    address: {
      address1: address.addressLine1,
      address2: address.addressLine2 || undefined,
      city: address.city,
      state: address.state,
      country: getCountryFromPartner(context.config.PARTNER),
      zip: address.postalCode,
    },
    contactInfo: {
      email: context.store.email,
      phone: context.store.mdn,
    },
  }

  const subscriptionNumber = context.store.subscriptionNumber

  const { encryptionKey, token } = await context.apiClient
    .getSecurityToken(
      TXN_TYPE.DD,
      subscriptionNumber, //sessionStorage.getItem('subscriptionNumber')?.toString(),
      accountNumber
    )
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToFetchSecToken,
      })
      setLoading(context, false)
      throw err
    })

  if (!paymentClient) {
    const env =
      process.env.NODE_ENV === 'production' ? Env['prod-eu'] : Env['qa-eu'] //Env['qa-us']
    paymentClient = new PaymentClient({ env })
  }

  try {
    const sessionData: SessionData = {
      appName: 'ENRPORTAL',
      encryptionKey: encryptionKey,
      currency: getCurrencyFromPartner(context.config.PARTNER),
    }

    await paymentClient.addSession(token, sessionData)

    await paymentClient.addACHInfo(bankAccountInfo)

    await paymentClient.addBillingContactInfo({
      ...billingContactInfo,
      address: {
        ..._omitBy(billingContactInfo.address, _isEmpty),
        zip: address.postalCode,
        country: getCountryFromPartner(context.config.PARTNER),
      },
    })

    await paymentClient.processPayment()
  } catch (err) {
    setBanner(context, {
      type: BannerMsgType.Error,
      msg: context.locale.strings.accountActions.failureUpdatingPaymentInfo(
        context.config.CUST_SERV_PHONE_NO
      ),
    })
    setLoading(context, false)
    throw err
  }

  await context.apiClient
    .updateEnrollment({
      updatePaymentMethod: true, // Tell the API to get the new payment info we just added
      billingAddress: {
        ...address,
        addressLine2:
          address.addressLine2 && address.addressLine2 !== ''
            ? address.addressLine2
            : undefined,
      },
      firstName: firstName, //details.customer.firstName,
      lastName: lastName, //details.customer.lastName,
      securityToken: token,
      paymentMethodDetails: {
        AccountHolderFirstName: billingFirstName,
        AccountHolderLastName: billingLastName,
        BillingAccountNumber: accountNumber.slice(-4),
        RoutingNumber: sortCode,
      },
    })
    .then((response) => {
      if (isError(response)) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: `${context.locale.strings.accountActions.failedToUpdatePaymentInfo}`,
        })
      } else {
        const newBillingDetails: IBankAccountDetails = {
          acctNum: accountNumber.slice(accountNumber.length - 4),
          billingProfileId: billingProfileId,
          billDate: billDate,
          // name: accountHolderName,
          name: `${firstName} ${lastName}`,
          nextInvoiceDate: nextInvoiceDate,
          routingNum: sortCode,
        }
        context.updateStore({ billingDetails: newBillingDetails })

        setBanner(context, {
          type: BannerMsgType.Success,
          msg: context.locale.strings.accountActions.updatedBillingInfo(
            (details && details.contact.email) || ''
          ),
        })
      }
    })
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToUpdatePaymentInfo,
      })

      throw err
    })
    .finally(() => setLoading(context, false))
}

const updatePaymentMethod = async (context: IContext, data: PaymentData) => {
  if (isCCPayment(data)) {
    await updateCCPaymentMethod(context, data)
  } else {
    await updateBankAccountPaymentMethod(context, data)
  }

  await getBillingInfo(context)
}

const trackEvent = (
  context: IContext,
  eventType: EventType,
  props?: object
) => {
  return context.apiClient.trackEvent(eventType, props)
}

const signOut = (context: IContext) => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('enrolled')
  context.history.replace(`${context.config.ACCOUNT_PATH}/sign-in`)
  context.updateStore({ signedIn: false, mdn: '' })
  setBanner(context, {
    type: BannerMsgType.Success,
    msg: context.locale.strings.accountActions.successfullySignedOut,
  })
}

const verificationCodeSent = (context: IContext) => {
  if (context.store.smartPINDeliveryMethod === SmartPINDeliveryMethod.SMS) {
    setBanner(context, {
      type: BannerMsgType.Success,
      msg: context.locale.strings.accountActions.sentVerification(
        formatPhoneNumber(
          context.store.mdn || '',
          getCountryFromPartner(context.config.PARTNER)
        ) || ''
      ),
    })
  } else {
    setBanner(context, {
      type: BannerMsgType.Success,
      msg: context.locale.strings.accountActions.sentVerification(
        context.store.email || ''
      ),
    })
  }
}

export const sendSmartPin = (context: IContext, req: ISendSmartPinRequest) => {
  return context.apiClient.sendSmartPin(req)
}

const createSmartLink = async (context: IContext) => {
  setLoading(context, true)
  try {
    const res = await context.apiClient.createSmartLink()
    return res.url
  } catch (err) {
    console.error(err)
    return null
  } finally {
    setLoading(context, false)
  }
}

const viewPaymentHistory = (context: IContext) => {
  context.history.push(`${context.config.ACCOUNT_PATH}/billing`)
}

const cancelPlan = async (
  context: IContext,
  reasons: { terminationReason: TerminationReason }
): Promise<void> => {
  try {
    setLoading(context, true)

    const cancelPlanRes = await context.apiClient.cancelPlan(reasons)

    if (!isError(cancelPlanRes)) {
      const { cancellationDate, refundEligible } = cancelPlanRes

      let bannerMsg =
        context.locale.strings.accountActions.successfulCancellationGeneric

      if (refundEligible) {
        bannerMsg =
          context.locale.strings.accountActions
            .successfulCancellationRefundEligible
      } else if (cancellationDate !== undefined) {
        const formattedCancellationDate = formatDateByCountry(
          moment(cancellationDate, 'YYYY-MM-DD'),
          getCountryFromPartner(context.config.PARTNER)
        )

        bannerMsg =
          context.locale.strings.accountActions.successfulCancellationWithDate(
            formattedCancellationDate
          )
      }

      context.apiClient
        .findAgreements()
        .then((findAgreementsRes) => {
          if (!isError(findAgreementsRes)) {
            context.updateStore({
              details: findAgreementsRes,
              mdn: findAgreementsRes.mdn,
              signedIn: true,
            })
          }
        })
        .finally(() => {
          setBanner(context, {
            type: BannerMsgType.Success,
            msg: bannerMsg,
          })
        })
    } else {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.errorCancellingPlan(
          context.config.CUST_SERV_PHONE_NO
        ),
      })
    }
  } finally {
    setLoading(context, false)
  }
}

const createNote = async (
  context: IContext,
  paylaod: INoteData
): Promise<ICreateNoteResponse> => {
  setLoading(context, true, true)
  const response = await context.apiClient.createNoteCall(paylaod)
  if (response) {
    setLoading(context, false)
  }
  return response
}

export enum BillingModal {
  AdHocPayment,
  NextPayment,
  PaymentMethod,
}
const showBillingModal = (
  context: IContext,
  currentBillingModal?: BillingModal
) => {
  context.history.push(`${context.config.ACCOUNT_PATH}/billing`)
  context.updateStore({ currentBillingModal })

  switch (currentBillingModal) {
    case BillingModal.AdHocPayment: {
      trackEvent(context, EventType.MakeAdHocPaymentLinkClicked, {
        flow: 'AcctMgmt',
      })
      break
    }
    case BillingModal.NextPayment: {
      trackEvent(context, EventType.ChangePaymentDateLinkClicked, {
        flow: 'AcctMgmt',
      })
      break
    }
    case BillingModal.PaymentMethod: {
      trackEvent(context, EventType.ChangePaymentMethodLinkClicked, {
        flow: 'AcctMgmt',
      })
    }
  }
}

const getSecurityToken = async (
  context: IContext,
  data: {
    txnType: 'CC' | 'DD'
    subscriptionNumber: string
    accountNumber?: string
    billingProgramId?: string
  }
) => {
  const { encryptionKey, token } = await context.apiClient
    .getSecurityToken(
      data.txnType as TXN_TYPE,
      data.subscriptionNumber,
      data.accountNumber,
      data.billingProgramId
    )
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToFetchSecToken,
      })
      setLoading(context, false)
      throw err
    })
  return {
    encryptionKey,
    token,
  }
}

const updateCCDetailsForMicroUi = async (
  context: IContext,
  payload: IUpdateEnrollmentRequest
) => {
  setLoading(context, true, true)
  await context.apiClient
    .updateEnrollment(payload)
    .then((response) => {
      if (isError(response)) {
        setBanner(context, {
          type: BannerMsgType.Error,
          msg: context.locale.strings.accountActions.failureUpdatingPaymentInfo(
            context.config.CUST_SERV_PHONE_NO
          ),
        })
      } else {
        setBanner(context, {
          type: BannerMsgType.Success,
          msg: context.locale.strings.accountActions.updatedBillingInfo(
            (context.store.details && context.store.details.contact.email) || ''
          ),
        })
      }
    })
    .catch((err) => {
      setBanner(context, {
        type: BannerMsgType.Error,
        msg: context.locale.strings.accountActions.failedToUpdatePaymentInfo,
      })
      throw err
    })
    .finally(() => setLoading(context, false))
  await getBillingInfo(context)
}

let memo: {
  context: IContext
  actions: IActions
}
const getActions = (incomingContext: IContextFromStateMgmt): IActions => {
  const config = useConfig() // eslint-disable-line react-hooks/rules-of-hooks
  const locale = useLocale() // eslint-disable-line react-hooks/rules-of-hooks
  const context: IContext = {
    ...incomingContext,
    config,
    locale,
  }

  if (memo) {
    Object.assign(memo.context, context)
  } else {
    memo = {
      context,
      actions: {
        verifyPin: verifyPin.bind(null, context),
        setDeliveryMethod: setDeliveryMethod.bind(null, context),
        submitMDN: submitMDN.bind(null, context),
        setLoading: setLoading.bind(null, context),
        getBillingInfo: getBillingInfo.bind(null, context),
        login: login.bind(null, context),
        updateBillingDate: updateBillingDate.bind(null, context),
        updateName: updateName.bind(null, context),
        updateEmail: updateEmail.bind(null, context),
        updatePaymentMethod: updatePaymentMethod.bind(null, context),
        setBanner: setBanner.bind(null, context),
        signOut: signOut.bind(null, context),
        verificationCodeSent: verificationCodeSent.bind(null, context),
        goToErrorPage: goToErrorPage.bind(null, context),
        createSmartLink: createSmartLink.bind(null, context),
        sendSmartPin: sendSmartPin.bind(null, context),
        makePayment: makePayment.bind(null, context),
        trackEvent: trackEvent.bind(null, context),
        viewPaymentHistory: viewPaymentHistory.bind(null, context),
        cancelPlan: cancelPlan.bind(null, context),
        showBillingModal: showBillingModal.bind(null, context),
        createNote: createNote.bind(null, context),
        getSecurityToken: getSecurityToken.bind(null, context),
        updateCCDetailsForMicroUi: updateCCDetailsForMicroUi.bind(
          null,
          context
        ),
      },
    }
  }
  return memo.actions
}

export { getActions }
