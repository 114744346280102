import React from 'react'
import { Box } from 'rebass'

const Constrainer: React.FC<{
  maxWidth: number
  minWidth: number
  children?: React.ReactNode | undefined
}> = ({ maxWidth, minWidth, children }) => {
  return (
    <Box
      width={maxWidth}
      maxWidth="100%"
      minWidth={minWidth}
      mx="auto"
      sx={{ position: 'relative' }}
    >
      {children}
    </Box>
  )
}

export default Constrainer
