import {
  Box,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import PaymentStatus from './PaymentStatus'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CreditCardChangeForm from './CreditCardChangeForm'
import {
  BillingDetails,
  BillingStatus,
  IBillingAddress,
  IBillingContact,
  IFindAgreementResponse,
  IInvoice,
  isCCDetails,
  PaymentData,
} from '../../interfaces'
import getUnpaidInvoices from '../../sharedHelpers/getUnpaidInvoices'
import SubmitButton from '../../sharedComponents/SubmitButton'
import formatPrice from '../../sharedHelpers/formatPrice'
// import formatNameOnCard from '../../sharedHelpers/formatNameOnCard'
import { useActions, useStore } from '../state/accountStateMgmt'
import paymentMethodDisplay from '../../sharedHelpers/paymentMethodDisplay'
import BankAccountChangeForm from './BankAccountChangeForm'
import { useLocale } from '../../localization'
import { useConfig } from '../state/accountConfigSetup'
import chooseCountryFromPartner from '../../../sharedHelpers/getCountryFromPartner'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IProps {
  details: IFindAgreementResponse
  billingHistory: IInvoice[]
  billingDetails: BillingDetails
  billingAddress: IBillingAddress
  billingStatus?: BillingStatus
  billingContact?: IBillingContact
  onClose: () => void
}
const MakePaymentModalContent: React.FC<IProps> = ({
  details,
  billingDetails,
  billingAddress,
  billingHistory,
  billingStatus,
  billingContact,
  onClose,
}) => {
  type MethodOptions = 'default' | 'specified'
  const [paymentMethod, setPaymentMethod] = useState<MethodOptions>('default')
  const classes = useStyles()
  const { loading } = useStore()
  const { makePayment } = useActions()
  const { invoiceIds, amount } = getUnpaidInvoices(billingHistory)
  const { strings } = useLocale()
  const { PARTNER } = useConfig()
  const COUNTRY = chooseCountryFromPartner(PARTNER)

  const buildReqAndMakePayment = (paymentMethodData?: PaymentData) => {
    const req = {
      billingProfileId: billingDetails.billingProfileId,
      invoiceIds,
      amount,
      firstName: details.customer.firstName,
      lastName: details.customer.lastName,
    }

    makePayment(req, paymentMethodData).finally(onClose)
  }

  const handleMethodChange = (event: React.ChangeEvent<unknown>) => {
    const method = (event.target as HTMLInputElement).value as MethodOptions
    setPaymentMethod(method)
  }

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    buildReqAndMakePayment()
  }

  return (
    <>
      <Box mb={2}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.makePaymentModalContent.makePayment}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" py={3}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {strings.makePaymentModalContent.billingStatus}
          </Typography>

          <Box mt={1}>
            <PaymentStatus
              billingStatus={billingStatus || BillingStatus.Pending}
            />
          </Box>

          <Box mt={2}>
            <Typography variant={'subtitle2'} color={'textSecondary'}>
              {strings.makePaymentModalContent.paymentAmount}
            </Typography>
            <Typography
              data-cy="amount-due"
              variant={'subtitle2'}
              color={'textPrimary'}
            >
              {formatPrice(amount, COUNTRY)}
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant={'subtitle2'} color={'textSecondary'}>
              {strings.makePaymentModalContent.paymentMethod}
            </Typography>
            <RadioGroup value={paymentMethod} onChange={handleMethodChange}>
              <FormControlLabel
                data-cy="radio-default-payment"
                value={'default'}
                control={<Radio />}
                label={strings.makePaymentModalContent.methodOnFileRadio(
                  paymentMethodDisplay({ billingDetails, strings })
                )}
              />
              <FormControlLabel
                data-cy={'radio-new-payment'}
                value={'specified'}
                control={<Radio />}
                label={strings.makePaymentModalContent.useDifferentMethod}
              />
            </RadioGroup>
          </Box>

          {/* If paymentMethod is specified, hide submit button and show PaymentModalForm instead. */}
          {paymentMethod === 'default' && (
            <SubmitButton
              data-cy={'payment-submit'}
              text={strings.makePaymentModalContent.makePayment}
              loading={loading}
            />
          )}
        </Box>
      </form>

      {paymentMethod === 'specified' && (
        <Box mt={1} mb={2}>
          {isCCDetails(billingDetails) ? (
            <CreditCardChangeForm
              loading={loading}
              // nameOnCard={formatNameOnCard(billingDetails, details)}
              billingFirstName={billingContact?.firstName}
              billingLastName={billingContact?.lastName}
              ccType={billingDetails.cardType}
              address={billingAddress}
              submitText={strings.makePaymentModalContent.updateAndMakePayment}
              onSaveChanges={buildReqAndMakePayment} // Will pass payment method data as an argument
            />
          ) : (
            <BankAccountChangeForm
              address={billingAddress}
              loading={loading}
              nameOnBankAccount={billingDetails.name}
              onSaveChanges={buildReqAndMakePayment}
              billingFirstName={billingContact?.firstName}
              billingLastName={billingContact?.lastName}
            />
          )}
        </Box>
      )}

      <Box
        className={classes.link}
        onClick={onClose}
        textAlign="center"
        fontWeight={500}
      >
        {strings.misc.cancel}
      </Box>
    </>
  )
}

export default MakePaymentModalContent
