import React from 'react'
import { Box } from 'rebass'
import TescoHero from './sections/TescoHero'
import ClaimDocuments from './sections/ClaimDocuments'

interface TescoLandingProps {
  onStartAClaim: () => void
}
const TescoLanding: React.FC<TescoLandingProps> = ({ onStartAClaim }) => {
  return (
    <Box>
      <TescoHero onStartAClaim={onStartAClaim} />
      <ClaimDocuments />
    </Box>
  )
}

export default TescoLanding
