import globalConfig from '../../config'
import { Partner } from '../../generic/interfaces'
import { TescoMarketingConfig } from './state/tescoMarketingConfigSetup'

const config: TescoMarketingConfig = {
  ALLOWED_CC_TYPES: 'VISA|MASTER|AMEX|DISC',
  API_BASE_URL: globalConfig.TESCO_API_BASE_URL,
  APP_MAX_WIDTH: 1450,
  APP_MIN_WIDTH: 300,
  BYOD_ID: '7031A',
  CLAIM_DOCS_URL: globalConfig.CLAIM_DOCUMENTS_URL,
  CUST_SERVICE_NUMBER: '+44(0)345 030 3290',
  ENABLE_SIGNATURE_FOR_TOUCH_DEVICES: false,
  ENVIRONMENT: process.env.NODE_ENV || '',
  MIXPANEL_ENABLED: false, //globalConfig.MIXPANEL_ENABLED,
  PARTNER: Partner.Tesco,
  RECAPTCHA_KEY: globalConfig.RECAPTCHA_KEY,
  SPEEDCLAIMS_URL: globalConfig.SPEEDCLAIMS_URL,
  COOKIE_POLICY_LINK: process.env.REACT_APP_TESCO_COOKIE_POLICY_LINK || '',
  TESCO_MICRO_UI_BPID: globalConfig.TESCO_MICRO_UI_BPID || '',
  TESCO_MICRO_UI_FEATURE_FLAG: globalConfig.TESCO_MICRO_UI_FEATURE_FLAG,
}

export default config
