import React from 'react'
import { IFindAgreementResponse } from '../../interfaces'

interface IPreferencesProps {
  details: IFindAgreementResponse
}
const Preferences: React.FC<IPreferencesProps> = (props) => {
  const { details } = props

  return (
    <>
      <pre>{JSON.stringify(details, null, 2)}</pre>
    </>
  )
}

export default Preferences
